import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Button, Typography, Box, TextField, Pagination, Select, 
  MenuItem, FormControl, InputLabel
} from '@mui/material';
import { API_BASE_URL } from '../config';
import UserDetails from './UserDetails';
import { debounce } from 'lodash';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    totalPages: 1,
    limit: 10
  });
  const [loading, setLoading] = useState(false);

  const fetchUsers = async (page, limit, search) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page,
        limit,
        search: search || ''
      }).toString();

      const response = await fetch(
        `${API_BASE_URL}/api/admin/writers?${queryParams}`,
        {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
          }
        }
      );
      const data = await response.json();
      setUsers(data.users);
      setPagination(data.pagination);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced search function
  const debouncedSearch = debounce((searchValue) => {
    fetchUsers(1, pagination.limit, searchValue);
  }, 500);

  useEffect(() => {
    fetchUsers(pagination.currentPage, pagination.limit, searchTerm);
    // eslint-disable-next-line
  }, [pagination.currentPage, pagination.limit]);

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => debouncedSearch.cancel();
    // eslint-disable-next-line
  }, [searchTerm]);

  const handleStatusChange = async (userId, newStatus) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/writers/${userId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        },
        body: JSON.stringify({ status: newStatus })
      });
      const updatedUser = await response.json();
      setUsers(users.map(user => 
        user.id === updatedUser.id ? updatedUser : user
      ));
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  const handleViewUser = async (userId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        }
      });
      const userData = await response.json();
      setSelectedUser(userData);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" sx={{ mb: 4 }}>User Management</Typography>
      {selectedUser ? (
        <UserDetails user={selectedUser} onClose={() => setSelectedUser(null)} />
      ) : (
        <>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              fullWidth
              label="Search users by id, name or email"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>Rows per page</InputLabel>
              <Select
                value={pagination.limit}
                label="Rows per page"
                onChange={(e) => {
                  fetchUsers(1, e.target.value, searchTerm);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <TableContainer component={Paper} sx={{ boxShadow: 3, mb: 2 }}>
            <Table sx={{ minWidth: 650 }} aria-label="user management table">
              <TableHead>
                <TableRow sx={{ backgroundColor: 'primary.main' }}>
                  <TableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>ID</TableCell>
                  <TableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>Name</TableCell>
                  <TableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>Email</TableCell>
                  <TableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>Status</TableCell>
                  <TableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">Loading...</TableCell>
                  </TableRow>
                ) : (
                  users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>{user.fullName}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            color: user.status === 'active' ? 'success.main' : 'error.main',
                            fontWeight: 'bold'
                          }}
                        >
                          {user.status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleViewUser(user.id)}
                          sx={{ textTransform: 'none', mr: 1 }}
                        >
                          View
                        </Button>
                        <Button
                          variant="contained"
                          color={user.status === 'active' ? 'error' : 'success'}
                          onClick={() => handleStatusChange(user.id, user.status === 'active' ? 'suspended' : 'active')}
                          sx={{ textTransform: 'none' }}
                        >
                          {user.status === 'active' ? 'Suspend' : 'Activate'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.currentPage}
              onChange={(event, page) => {
                fetchUsers(page, pagination.limit, searchTerm);
              }}
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default UserManagement;
