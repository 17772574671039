import React, { useState } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem 
} from '@mui/material';
import { API_BASE_URL } from '../config';

const NewTask = ({ onClose }) => {
  const [task, setTask] = useState({
    title: '',
    description: '',
    price: '',
    deadline: '',
    category: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTask({ ...task, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/create-task`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        },
        body: JSON.stringify(task)
      });
      if (response.ok) {
        onClose();
      }
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>Add New Task</Typography>
      <TextField
        fullWidth
        label="Title"
        name="title"
        value={task.title}
        onChange={handleInputChange}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Description"
        name="description"
        value={task.description}
        onChange={handleInputChange}
        multiline
        rows={4}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Price"
        name="price"
        type="number"
        value={task.price}
        onChange={handleInputChange}
        required
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Deadline"
        name="deadline"
        type="date"
        value={task.deadline}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
        required
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth required sx={{ mb: 2 }}>
        <InputLabel>Category</InputLabel>
        <Select
          name="category"
          value={task.category}
          onChange={handleInputChange}
        >
          <MenuItem value="writing">Writing</MenuItem>
          <MenuItem value="editing">Editing</MenuItem>
          <MenuItem value="proofreading">Proofreading</MenuItem>
          <MenuItem value="transcribing">Transcribing</MenuItem>
          <MenuItem value="programming">Programming</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          Create Task
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default NewTask;