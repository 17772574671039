import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import LearningPathModal from './LearningPathModal';
import { User } from 'lucide-react';

const UserNavbar = ({ isLoggedIn, onLogout, avatarUrl, completedLearning }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showLearningModal, setShowLearningModal] = useState(false);
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "text-teal-400" : "text-gray-300 hover:text-teal-400";
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMenuOpen]);

  const handleLearningClick = () => {
    setShowLearningModal(true);
  };

  const renderAvatar = () => (
    <Link to="/profile" className="flex items-center">
      {avatarUrl ? (
        <img src={avatarUrl} alt="Profile" className="w-8 h-8 rounded-full" />
      ) : (
        <User className="w-8 h-8 text-gray-300" />
      )}
      {completedLearning && (
        <span className="ml-2 text-yellow-400">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
          </svg>
        </span>
      )}
    </Link>
  );

  return (
    <nav className="bg-gray-900 p-4 shadow-md">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <Link to="/" className="text-teal-400 text-xl font-bold">
            <img src="/fitfreelance-logo.jpeg" alt="FitFreelance Logo" className="h-10" />
          </Link>
          {/* Avatar for small screens */}
          <div className="lg:hidden flex items-center mr-2">
            {isLoggedIn && renderAvatar()}
          </div>
          {/* Hamburger menu for mobile */}
          <button
            className="lg:hidden text-gray-300 hover:text-teal-400"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
          {/* Desktop menu */}
          <div className="hidden lg:flex items-center space-x-6">
            {!isLoggedIn ? (
              <>
              <Link to='/' className={`${isActive('/')} transition duration-300`}>Home</Link>
              </>
            ) : (
              <></>
            )}
            <Link to="/FAQ" className={`${isActive('/FAQ')} transition duration-300`}>FAQ</Link>
            <Link to="/tasks" className={`${isActive('/tasks')} transition duration-300`}>Jobs</Link>
            <button onClick={handleLearningClick} className={`${isActive('/learning')} transition duration-300 focus:outline-none`}>
              Learning
            </button>
            {isLoggedIn ? (
              <>
                {renderAvatar()}
                <button onClick={onLogout} className="bg-teal-500 text-gray-900 px-4 py-2 rounded-full hover:bg-teal-400 transition duration-300">Logout</button>
              </>
            ) : (
              <>
                <Link to="/login" className={`${isActive('/login')} transition duration-300`}>Login</Link>
                <Link to="/register" className="bg-teal-500 text-gray-900 px-4 py-2 rounded-full hover:bg-teal-400 transition duration-300">Register</Link>
              </>
            )}
          </div>
        </div>
        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="lg:hidden mt-4">
            <Link to="/FAQ" className={`block py-2 ${isActive('/FAQ')} transition duration-300`} onClick={() => setIsMenuOpen(false)}>FAQ</Link>
            <Link to="/tasks" className={`block py-2 ${isActive('/tasks')} transition duration-300`} onClick={() => setIsMenuOpen(false)}>Jobs</Link>
            <button className={`block py-2 ${isActive('/learning')} transition duration-300 focus:outline-none`} onClick={() => { handleLearningClick(); setIsMenuOpen(false); }}>
              Learning
            </button>
            {isLoggedIn ? (
              <button onClick={() => { onLogout(); setIsMenuOpen(false); }} className="block w-full text-left py-2 text-gray-300 hover:text-teal-400 transition duration-300">Logout</button>
            ) : (
              <>
                <Link to="/login" className={`block py-2 ${isActive('/login')} transition duration-300`} onClick={() => setIsMenuOpen(false)}>Login</Link>
                <Link to="/register" className="block py-2 text-gray-300 hover:text-teal-400 transition duration-300" onClick={() => setIsMenuOpen(false)}>Register</Link>
              </>
            )}
          </div>
        )}
      </div>
      {/* Learning Path Modal */}
      <LearningPathModal show={showLearningModal} onHide={() => setShowLearningModal(false)} />
    </nav>
  );
};

export default UserNavbar;