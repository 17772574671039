import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function GrammarAndPunctuation() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/challenging-audio');
    };

    return (
        <div className="transcription-intro">
            <header className="header">
                <h1>Grammar and Punctuation in Transcription</h1>
                <p className="subtitle">Mastering Grammar and Punctuation</p>
            </header>

            <div className="content">
                {/* Grammar Essentials for Transcriptionists */}
                <div className="section-block">
                    <h2>1. Grammar Essentials for Transcriptionists</h2>
                    <h3>Common Grammatical Issues in Spoken Language</h3>
                    <p>When transcribing spoken language, you’ll encounter all kinds of grammatical quirks that wouldn’t normally appear in written language. People speak more casually and less grammatically correct than they write. As a transcriptionist, you need to decide whether to maintain the speaker’s informal grammar (in verbatim transcription) or clean it up for readability (in edited transcription).</p>

                    <p><strong>Sentence Fragments and Run-ons:</strong> People often speak in incomplete sentences or long, rambling run-ons. While this might make sense in conversation, it can look confusing in a transcript.</p>
                    <p><strong>Example:</strong></p>
                    <p>Spoken: "So, like, you know, when we, um, went to the meeting, it was—uh—it was just really, really bad, like, not even, you know, what I expected."</p>
                    <p>Clean Transcript: "When we went to the meeting, it was not what I expected. It was really bad."</p>

                    <p><strong>Inconsistent Verb Tenses:</strong> Speakers frequently shift between past, present, and future tenses mid-sentence, which can be confusing in a written transcript.</p>
                    <p><strong>Example:</strong></p>
                    <p>Spoken: "I was thinking that we’ll go to the park, and then we went to get ice cream."</p>
                    <p>Clean Transcript: "I was thinking that we would go to the park, and then we went to get ice cream."</p>

                    <p><strong>Misplaced Modifiers:</strong> In conversation, people may place modifiers (adjectives or adverbs) in awkward positions, making their meaning unclear. It’s important to capture their intent without altering the meaning.</p>
                    <p><strong>Example:</strong></p>
                    <p>Spoken: "I almost watched all of the movie."</p>
                    <p>Clean Transcript: "I watched almost all of the movie."</p>
                </div>

                {/* Handling Dialect and Non-Standard Grammar */}
                <div className="section-block">
                    <h3>Handling Dialect and Non-Standard Grammar</h3>
                    <p>When transcribing conversations with dialects or non-standard grammar, it’s crucial to stay true to the speaker’s voice without imposing formal grammar rules. Your job as a transcriptionist is to capture the speaker’s authentic voice, while ensuring that the transcript is clear and professional.</p>

                    <p><strong>Dialect Representation:</strong> If your client requests a verbatim transcription, maintain the speaker’s dialect and non-standard grammar as much as possible without changing the meaning. If clarity is more important than strict accuracy, you can adjust the grammar while preserving the speaker's tone.</p>
                    <p><strong>Example (Southern Dialect):</strong></p>
                    <p>Verbatim: "Y’all better get on outta here ‘fore it starts rainin’."</p>
                    <p>Clean Transcript: "You all better get out of here before it starts raining."</p>

                    <p><strong>Non-Standard Grammar:</strong> In informal speech, especially in certain dialects, non-standard grammar is common. If transcribing for an academic or formal project, you might need to clean up the language, but in most cases, retaining the speaker's authenticity is key.</p>
                    <p><strong>Example:</strong></p>
                    <p>Verbatim: "I ain't never seen nothing like that before."</p>
                    <p>Clean Transcript: "I’ve never seen anything like that before."</p>
                </div>

                {/* Punctuation in Transcripts */}
                <div className="section-block">
                    <h2>2. Punctuation in Transcripts</h2>
                    <h3>Using Punctuation to Convey Meaning and Tone</h3>
                    <p>Punctuation is the unsung hero of transcription. It not only provides structure but also conveys meaning, tone, and emphasis. When used correctly, punctuation can make your transcripts easy to read and understand, preserving the speaker’s intent and flow.</p>

                    <p><strong>Commas:</strong> Use commas to separate ideas, clarify meaning, and reflect natural pauses in speech. Commas can drastically change the meaning of a sentence, so it’s important to place them carefully.</p>
                    <p><strong>Example:</strong></p>
                    <p>"Let’s eat, Grandma!" vs. "Let’s eat Grandma!"</p>

                    <p><strong>Periods:</strong> Periods are used to break up complete thoughts. When transcribing, place periods at the end of complete sentences, even if the speaker trails off mid-thought. This makes the transcript easier to follow.</p>
                    <p><strong>Example:</strong></p>
                    <p>Spoken: "Well, I think that’s the best option for now... but, uh... let me know what you think."</p>
                    <p>Transcript: "Well, I think that’s the best option for now. But let me know what you think."</p>

                    <p><strong>Ellipses:</strong> Use ellipses to indicate when a speaker pauses mid-sentence, trails off, or leaves a thought incomplete. Be mindful not to overuse ellipses, as it can clutter the transcript.</p>
                    <p><strong>Example:</strong></p>
                    <p>Spoken: "I was going to suggest... but, never mind."</p>
                    <p>Transcript: "I was going to suggest... but never mind."</p>

                    <p><strong>Dashes (—):</strong> Use dashes to indicate an abrupt interruption, change in thought, or speaker overlap. This can be useful in interviews or group discussions where people interrupt each other.</p>
                    <p><strong>Example:</strong></p>
                    <p>"I was thinking—"</p>
                    <p>"Wait, let me explain that."</p>
                </div>

                {/* Special Punctuation for Transcripts */}
                <div className="section-block">
                    <h3>Special Punctuation for Transcripts</h3>
                    <p>Some punctuation is unique to transcripts and is used to clarify or provide additional context that wouldn’t be obvious from just the words themselves.</p>

                    <p><strong>Brackets:</strong> Use brackets to indicate non-verbal sounds or actions that are important for understanding the context of the conversation.</p>
                    <p><strong>Example:</strong></p>
                    <p>"I’m not sure about that [laughs], but let’s give it a try."</p>
                    <p>"[Applause]"</p>

                    <p><strong>Quotation Marks:</strong> Use quotation marks to indicate when a speaker is quoting someone else, emphasizing a word, or using a term in a specific way.</p>
                    <p><strong>Example:</strong></p>
                    <p>"He said, 'Let’s meet at 3 PM,' and then never showed up."</p>
                    <p>"She called it 'a disaster,' but I disagree."</p>
                </div>

                {/* Capitalization Rules */}
                <div className="section-block">
                    <h2>3. Capitalization Rules</h2>
                    <h3>Standard Capitalization</h3>
                    <p>Capitalization is more than just the first letter of a sentence—it can convey importance, distinguish proper nouns, and clarify titles. Knowing when to capitalize can prevent misunderstandings and enhance the professionalism of your transcripts.</p>

                    <p><strong>Standard Capitalization:</strong> Always capitalize the first word of each sentence to maintain readability and standard formatting.</p>
                    <p><strong>Example:</strong></p>
                    <p>"The project started last week."</p>

                    <p><strong>Proper Nouns:</strong> Proper nouns—names of people, places, organizations, and specific events—should always be capitalized. This includes job titles when used with names.</p>
                    <p><strong>Example:</strong></p>
                    <p>"Dr. Smith will meet you at City Hall."</p>

                    <p><strong>Titles of Documents:</strong> When transcribing a conversation where a document, book, or project is mentioned, capitalize its title.</p>
                    <p><strong>Example:</strong></p>
                    <p>"I’m currently reading To Kill a Mockingbird."</p>
                </div>

                {/* Handling Titles and Proper Nouns */}
                <div className="section-block">
                    <h3>Handling Titles and Proper Nouns</h3>
                    <p>Titles can be tricky because the rules vary depending on how they’re used in a sentence. Capitalizing titles appropriately ensures clarity and professionalism in your transcript.</p>

                    <p><strong>Capitalizing Titles with Names:</strong> Capitalize titles when they appear before a name, but use lowercase when they appear after a name or when used generically.</p>
                    <p><strong>Example:</strong></p>
                    <p>"President Johnson gave a speech today."</p>
                    <p>"The president gave a speech today."</p>

                    <p><strong>Events and Organizations:</strong> Always capitalize the names of organizations, formal events, and proper nouns to provide clarity.</p>
                    <p><strong>Example:</strong></p>
                    <p>"The United Nations held its General Assembly last week."</p>
                    <p>"She is attending the International Writers' Conference."</p>
                </div>

                {/* Conclusion Section */}
                <div className="section-block">
                    <h2>Conclusion: Mastering Grammar and Punctuation in Transcription</h2>
                    <p>Grammar and punctuation are the unsung heroes of transcription. They provide structure, convey meaning, and transform spoken language into polished, professional text. As a transcriptionist, your attention to grammatical details will not only ensure accuracy but also elevate the quality of your work. With practice, these tools become second nature, allowing you to create transcripts that are both precise and easy to read.</p>
                    <p>Remember, every well-placed comma or period enhances the clarity of your transcription. Every thoughtful capitalization brings professionalism to your work. By mastering these skills, you are not just transcribing words—you’re crafting readable, valuable documents that will serve your clients well.</p>
                    <p>This mastery will set you apart, enabling you to deliver high-quality transcripts every time, no matter how complex the audio or how informal the conversation.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default GrammarAndPunctuation;
