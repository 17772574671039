import React, { useState } from 'react';
import axios from 'axios';
import { FaFileAlt } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';
import { API_BASE_URL } from '../config';
import TaskDescription from './TaskDescription';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { motion } from 'framer-motion';

const TaskSubmission = ({ task, userToken, onBack }) => {
  const [submissionDetails, setSubmissionDetails] = useState('');
  const [files, setFiles] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);

  const isDeadlinePassed = new Date(task.deadline) < new Date();

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleSubmission = async () => {
    if (files.length === 0) {
      setSubmissionError('Please upload at least one file.');
      return;
    }

    setSubmitting(true);
    setSubmissionError(null);

    const formData = new FormData();
    formData.append('submissionDetails', submissionDetails);
    files.forEach((file) => {
      formData.append('documents', file);
    });

    try {
      await axios.post(`${API_BASE_URL}/api/users/task/${task.id}/submit`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userToken}`,
        },
      });
      setSubmitting(false);
      onBack();
    } catch (error) {
      console.error('Error submitting task:', error);
      setSubmissionError('Failed to submit the task.');
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen p-8">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Alert severity="info" className="mb-8">
          <AlertTitle>Submission Instructions</AlertTitle>
          <ol>
            <li>Upload all the required documents as per the instructions</li>
            <li>Submit Plag and AI Turnitin reports</li>
            <li>Submit your phone number for payment</li>              
          </ol>
        </Alert>
      </motion.div>
      <button onClick={onBack} className="mb-4 text-teal-400 hover:text-teal-600">Back to Profile</button>
      <h2 className="text-3xl font-bold mb-4 text-teal-300">{task.title}</h2>
      <TaskDescription description={task.description} />
      {task.documents && task.documents.length > 0 && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-teal-400">Support Documents</h3>
          <ul className="list-disc list-inside pl-4">
            {task.documents.map((doc, index) => (
              <li key={index} className="flex items-center mb-2">
                <FaFileAlt className="mr-2 text-teal-400" />
                <a 
                  href={`${API_BASE_URL}/uploads/${doc.fileName}`} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-teal-300 hover:underline"
                >
                  {doc.fileName} ({Math.round(doc.fileSize / 1024)} KB)
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
      {isDeadlinePassed ? (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Alert severity="warning" className="mb-8">
            <AlertTitle>Deadline Passed</AlertTitle>
            <p>The deadline for this task has passed. Submitting work late risks suspension.</p>
            <p>Please contact the admin at <a href="mailto:admin@fitfreelance.co.ke" className="text-yellow-700 underline">admin@fitfreelance.co.ke</a> to explain your reason for being late.</p>
          </Alert>
        </motion.div>
      ) : (
        <div>
          <h3 className="text-xl font-semibold mb-2">Upload Submission</h3>
          <textarea
            className="w-full p-2 mb-4 bg-gray-800 text-gray-100 rounded"
            rows="4"
            placeholder="Enter submission details..."
            value={submissionDetails}
            onChange={(e) => setSubmissionDetails(e.target.value)}
          ></textarea>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="mb-4 text-gray-300"
          />
          {submissionError && <div className="text-red-500 mb-4">{submissionError}</div>}
          <button
            onClick={handleSubmission}
            disabled={submitting}
            className="bg-teal-400 text-gray-900 font-semibold px-4 py-2 rounded hover:bg-teal-500"
          >
            {submitting ? 'Submitting...' : 'Submit Task'}
          </button>
          {submitting && (
            <div className="flex justify-center items-center mt-4">
              <ClipLoader color="#38bdf8" loading={submitting} size={50} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TaskSubmission;
