import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Paper, Typography, Divider, Box, Alert as MuiAlert, CircularProgress } from '@mui/material';
import { API_BASE_URL } from '../config';

const Alerts = () => {
  const [deadlineAlerts, setDeadlineAlerts] = useState([]);
  const [lateSubmissions, setLateSubmissions] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAlerts = async () => {
      setLoading(true);
      try {
        const deadlineResponse = await fetch(`${API_BASE_URL}/api/admin/deadline-alerts`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
          }
        });
        if (!deadlineResponse.ok) {
          throw new Error(`HTTP error! status: ${deadlineResponse.status}`);
        }
        const deadlineData = await deadlineResponse.json();
        console.log('Deadline Alerts:', deadlineData);
        setDeadlineAlerts(deadlineData);

        const lateResponse = await fetch(`${API_BASE_URL}/api/admin/late-submissions`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
          }
        });
        if (!lateResponse.ok) {
          throw new Error(`HTTP error! status: ${lateResponse.status}`);
        }
        const lateData = await lateResponse.json();
        console.log('Late Submissions:', lateData);
        setLateSubmissions(lateData);
      } catch (error) {
        console.error('Error fetching alerts:', error);
        setError(`Failed to fetch alerts: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchAlerts();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <MuiAlert severity="error">{error}</MuiAlert>;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" sx={{ mb: 4 }}>Alerts & Notifications</Typography>
     
      <Box sx={{ mb: 4 }}>
        <MuiAlert severity="warning" sx={{ mb: 2 }}>
          <Typography variant="h6">Deadline Alerts</Typography>
        </MuiAlert>
        <Paper elevation={3}>
          <List>
            {deadlineAlerts.length > 0 ? (
              deadlineAlerts.map((alert) => (
                <React.Fragment key={alert.id}>
                  <ListItem>
                    <ListItemText
                      primary={`Task: ${alert.taskTitle}`}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.primary">
                            Deadline: {new Date(alert.deadline).toLocaleDateString()}
                          </Typography>
                          <br />
                          {alert.assignment === 'Unassigned' ? (
                            <Typography component="span" variant="body2" color="error">
                              Unassigned
                            </Typography>
                          ) : (
                            <>
                              <Typography component="span" variant="body2" color="text.primary">
                                Assigned to: {alert.assignment.fullName}
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" color="text.secondary">
                                Email: {alert.assignment.email}
                              </Typography>
                            </>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No deadline alerts at this time." />
              </ListItem>
            )}
          </List>
        </Paper>
      </Box>
      <Box>
        <MuiAlert severity="error" sx={{ mb: 2 }}>
          <Typography variant="h6">Late Submissions</Typography>
        </MuiAlert>
        <Paper elevation={3}>
          <List>
            {lateSubmissions.length > 0 ? (
              lateSubmissions.map((submission) => (
                <React.Fragment key={submission.id}>
                  <ListItem>
                    <ListItemText
                      primary={`Task: ${submission.taskTitle}`}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.primary">
                            Submitted: {new Date(submission.submissionDate).toLocaleDateString()}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="error">
                            Deadline: {new Date(submission.deadline).toLocaleDateString()}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No late submissions at this time." />
              </ListItem>
            )}
          </List>
        </Paper>
      </Box>
    </Box>
  );
};

export default Alerts;