import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
const LearningPathModal = ({ show, onHide }) => {
  const navigate = useNavigate();

  const handlePathSelection = (path) => {
    onHide();
    navigate(path);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select Your Learning Path</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please select a learning path:</p>
        <Button className="mb-2 w-100" onClick={() => handlePathSelection('/learning')}>
          Academic Writing
        </Button>
        <Button className="mb-2 w-100" onClick={() => handlePathSelection('/blogging')}>
          Blog and Article Writing
        </Button>
        <Button className="mb-2 w-100" onClick={() => handlePathSelection('/transcription')}>
          Transcription
        </Button>
        <Button className="mb-2 w-100" onClick={() => handlePathSelection('/java-learning')}>
          Programming
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default LearningPathModal;
