// src/pages/JavaAPIs.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';
import './JavaAPIs.css';


const JavaAPIs = () => {
    const stringConcatCode = `
public class StringExample {
    public static void main(String[] args) {
        String str1 = "Hello";
        String str2 = "World";
        String str3 = str1 + " " + str2; // Concatenation using + operator
        System.out.println(str3); // Output: Hello World
    }
}
`;

    const stringLengthCode = `
public class StringLengthExample {
    public static void main(String[] args) {
        String str = "Java Programming";
        System.out.println("Length: " + str.length()); // Output: Length: 16
    }
}
`;

    const substringCode = `
public class SubstringExample {
    public static void main(String[] args) {
        String str = "Hello World";
        String substr = str.substring(6, 11); // Extracts "World"
        System.out.println("Substring: " + substr); // Output: Substring: World
    }
}
`;

    const replaceCode = `
public class ReplaceExample {
    public static void main(String[] args) {
        String str = "Java is fun";
        String newStr = str.replace("fun", "powerful");
        System.out.println("Modified String: " + newStr); // Output: Modified String: Java is powerful
    }
}
`;

    const stringComparisonCode = `
public class StringComparisonExample {
    public static void main(String[] args) {
        String str1 = "Java";
        String str2 = "Java";
        String str3 = "Python";
        System.out.println(str1.equals(str2)); // Output: true
        System.out.println(str1.compareTo(str3)); // Output: -9 (negative because "Java" comes before "Python")
    }
}
`;

    const basicMathCode = `
public class BasicMathExample {
    public static void main(String[] args) {
        double x = 2.5;
        double y = 4.0;
        System.out.println("Max: " + Math.max(x, y)); // Output: Max: 4.0
        System.out.println("Min: " + Math.min(x, y)); // Output: Min: 2.5
        System.out.println("Square Root: " + Math.sqrt(x)); // Output: Square Root: 1.5811388300841898
        System.out.println("Power: " + Math.pow(x, y)); // Output: Power: 39.0625
    }
}
`;

    const trigCode = `
public class TrigonometryExample {
    public static void main(String[] args) {
        double angle = 45.0;
        double radians = Math.toRadians(angle);
        System.out.println("Sin: " + Math.sin(radians)); // Output: Sin: 0.7071067811865475
        System.out.println("Cos: " + Math.cos(radians)); // Output: Cos: 0.7071067811865476
        System.out.println("Tan: " + Math.tan(radians)); // Output: Tan: 0.9999999999999999
    }
}
`;

    const randomCode = `
public class RandomNumberExample {
    public static void main(String[] args) {
        double randomValue = Math.random(); // Random value between 0.0 and 1.0
        int randomInt = (int) (Math.random() * 100); // Random integer between 0 and 99
        System.out.println("Random Value: " + randomValue);
        System.out.println("Random Integer: " + randomInt);
    }
}
`;

    const dateTimeExampleCode = `
import java.time.LocalDate;
import java.time.LocalTime;
import java.time.LocalDateTime;

public class DateTimeExample {
    public static void main(String[] args) {
        LocalDate date = LocalDate.now(); // Current date
        LocalTime time = LocalTime.now(); // Current time
        LocalDateTime dateTime = LocalDateTime.now(); // Current date and time
        System.out.println("Current Date: " + date); // Output: Current Date: 2024-08-25
        System.out.println("Current Time: " + time); // Output: Current Time: 14:30:15.123
        System.out.println("Current DateTime: " + dateTime); // Output: Current DateTime: 2024-08-25T14:30:15.123
    }
}
`;

    const dateTimeFormattingCode = `
import java.time.LocalDateTime;
import java.time.format.DateTimeFormatter;

public class DateTimeFormattingExample {
    public static void main(String[] args) {
        LocalDateTime dateTime = LocalDateTime.now();
        DateTimeFormatter formatter = DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss");
        String formattedDateTime = dateTime.format(formatter);
        System.out.println("Formatted DateTime: " + formattedDateTime); // Output: Formatted DateTime: 2024-08-25 14:30:15
    }
}
`;

    const dateTimeParsingCode = `
import java.time.LocalDate;
import java.time.format.DateTimeFormatter;

public class DateTimeParsingExample {
    public static void main(String[] args) {
        String dateString = "2024-08-25";
        DateTimeFormatter formatter = DateTimeFormatter.ofPattern("yyyy-MM-dd");
        LocalDate date = LocalDate.parse(dateString, formatter);
        System.out.println("Parsed Date: " + date); // Output: Parsed Date: 2024-08-25
    }
}
`;

    const dateManipulationCode = `
import java.time.LocalDate;

public class DateManipulationExample {
    public static void main(String[] args) {
        LocalDate date = LocalDate.now();
        LocalDate newDate = date.plusDays(10); // Adding 10 days to the current date
        System.out.println("New Date: " + newDate); // Output: New Date: 2024-09-04
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Introduction to Java APIs: A Comprehensive Guide</h1>
                <p>
                    Java provides a rich set of Application Programming Interfaces (APIs) that offer pre-built methods and classes to perform a wide variety of tasks. Understanding how to leverage these APIs effectively can significantly enhance your productivity as a developer and help you build more efficient, reliable, and maintainable applications. In this guide, we will explore three essential Java APIs: String Manipulation, the Math Library, and the Date and Time API. Each section includes detailed explanations, code examples, and real-world applications to help you understand and apply these APIs in your projects.
                </p>

                <hr className="section-divider" />

                <h2>1. String Manipulation</h2>
                <p>Strings are fundamental in almost every Java application, as they represent text data. The Java String API provides numerous methods for manipulating strings, including searching, modifying, and formatting text.</p>

                <h3>Common String Operations</h3>

                <h4>Creating and Concatenating Strings</h4>
                <CodeBlock code={stringConcatCode} />

                <h4>String Length</h4><p>The length() method returns the number of characters in a string.
                </p>
                <CodeBlock code={stringLengthCode} />

                <h4>Substring Extraction</h4>
                <p>The substring() method extracts a portion of a string based on specified indices.</p>
                <CodeBlock code={substringCode} />

                <h4>String Replacement</h4>
                <p>The replace() method replaces occurrences of a specified character or substring with another.</p>
                <CodeBlock code={replaceCode} />

                <h4>String Comparison</h4>
                <p>The equals() and compareTo() methods are used to compare strings.</p>
                <CodeBlock code={stringComparisonCode} />

                <p><strong>Real-World Application:</strong> String manipulation is crucial in many applications. For example, in a content management system, strings might be used to handle text data such as articles, titles, and metadata. String methods can be used to format, search, and replace content as needed.</p>

                <hr className="section-divider" />

                <h2>2. Math Library</h2>
                <p>The java.lang.Math class provides a range of static methods for performing basic numeric operations such as exponentiation, logarithms, trigonometric functions, and more. These methods are essential in applications that involve complex calculations.</p>

                <h3>Common Math Operations</h3>

                <h4>Basic Arithmetic Operations</h4>
                <p>While Java provides basic operators for arithmetic operations, the Math class offers additional methods for more complex calculations.</p>
                <CodeBlock code={basicMathCode} />

                <h4>Trigonometric Functions</h4>
                <p>The Math class provides methods for calculating trigonometric values, which are useful in applications involving geometry, physics simulations, and more.</p>
                <CodeBlock code={trigCode} />

                <h4>Random Number Generation</h4>
                <p>The Math.random() method returns a double value greater than or equal to 0.0 and less than 1.0, which can be scaled to generate random numbers within a specific range.</p>
                <CodeBlock code={randomCode} />

                <p><strong>Real-World Application:</strong> The Math library is essential in applications such as scientific computing, financial modeling, game development, and data analysis. For example, in a financial application, you might use Math methods to calculate compound interest, investment returns, and statistical metrics.</p>

                <hr className="section-divider" />

                <h2>3. Date and Time API</h2>
                <p>Handling dates and times is a common requirement in many applications. Java's Date and Time API, introduced in Java 8, provides a comprehensive and flexible framework for managing date and time information. The new API, located in the java.time package, is designed to be more intuitive and easier to use than the older java.util.Date and java.util.Calendar classes.</p>

                <h3>Working with LocalDate, LocalTime, and LocalDateTime</h3>

                <h4>Creating Date and Time Objects</h4>
                <p>The LocalDate, LocalTime, and LocalDateTime classes represent date-only, time-only, and both date and time, respectively.</p>
                <CodeBlock code={dateTimeExampleCode} />

                <h4>Formatting Date and Time</h4>
                <p>The DateTimeFormatter class is used to format date and time objects into a specific pattern.</p>
                <CodeBlock code={dateTimeFormattingCode} />

                <h4>Parsing Strings into Date and Time</h4>
                <p>The DateTimeFormatter class can also be used to parse strings into date and time objects.</p>
                <CodeBlock code={dateTimeParsingCode} />

                <h4>Manipulating Dates and Times</h4>
                <p>You can easily add or subtract days, months, years, hours, minutes, etc., using methods like plusDays(), minusMonths(), etc.</p>
                <CodeBlock code={dateManipulationCode} />

                <p><strong>Real-World Application:</strong> The Date and Time API is crucial in applications that require accurate time management, scheduling, or logging. For instance, in a task management system, the API can be used to set deadlines, schedule reminders, and track time spent on tasks.</p>

                <hr className="section-divider" />

                <p><strong>Conclusion:</strong> Java APIs for String Manipulation, Math operations, and Date and Time handling are essential tools for any Java developer. Mastering these APIs allows you to perform a wide range of tasks more efficiently and effectively. String Manipulation is key to handling and processing textual data, the Math library is invaluable for performing complex calculations, and the Date and Time API provides a robust framework for managing date and time data in modern applications.</p>

                <p>By understanding and applying these APIs, you can significantly enhance the functionality and reliability of your Java applications, whether you're working on text processing tools, financial applications, or scheduling systems. These foundational skills are crucial for building robust, efficient, and maintainable software in Java.</p>

                <NextButton nextPage="/advanced-oop" />
            </div>
        </div>
    );
};

export default JavaAPIs;
