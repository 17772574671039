import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Transcription/components/Sidebar';
import IntroductionToTranscription from './Transcription/pages/IntroductionToTranscription';
import TypesOfTranscription from './Transcription/pages/TypesOfTranscription';
import EssentialSkills from './Transcription/pages/EssentialSkills';
import ToolsAndEquipment from './Transcription/pages/ToolsAndEquipment';
import TranscriptionProcess from './Transcription/pages/TranscriptionProcess';
import AudioFormatsQuality from './Transcription/pages/AudioFormatsQuality';
import StylesAndFormatting from './Transcription/pages/StylesAndFormatting';
import GrammarAndPunctuation from './Transcription/pages/GrammarAndPunctuation';
import ChallengingAudio from './Transcription/pages/ChallengingAudio';
import SpecializedFields from './Transcription/pages/SpecializedFields';
import SoftwareAndTechnology from './Transcription/pages/SoftwareAndTechnology';
import QualityControl from './Transcription/pages/QualityControl';
import TimeManagement from './Transcription/pages/TimeManagement';
import CareerBuilding from './Transcription/pages/CareerBuilding';
import EthicsAndConfidentiality from './Transcription/pages/EthicsAndConfidentiality';
import ContinuingEducation from './Transcription/pages/ContinuingEducation';
import LockableContent from './LockableComponent';
import { API_BASE_URL } from './components/config';

function TranscriptionModule({ userToken, learningStatus }) {
    // eslint-disable-next-line
    const [hasCompletedLearning, setHasCompletedLearning] = useState(learningStatus);

    useEffect(() => {
        const fetchLearningStatus = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/api/training/status`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                const data = await response.json();
                setHasCompletedLearning(data);
            } catch (error) {
                console.error('Error fetching status:', error);
            }
        };

        fetchLearningStatus();
    }, [userToken]);

    const handleUnlock = () => {
        setHasCompletedLearning((prevState) => ({ ...prevState, isPaid: true }));
    };

    return (
        <div className="App">
            <Sidebar />
            <div className="content">
                <Routes>
                    <Route path="/" element={<IntroductionToTranscription />} />
                    <Route
                        path="types"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><TypesOfTranscription /></LockableContent>}
                    />
                    <Route
                        path="skills"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><EssentialSkills /></LockableContent>}
                    />
                    <Route
                        path="tools-equipment"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><ToolsAndEquipment /></LockableContent>}
                    />
                    <Route
                        path="process"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><TranscriptionProcess /></LockableContent>}
                    />
                    <Route
                        path="audio-quality"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><AudioFormatsQuality /></LockableContent>}
                    />
                    <Route
                        path="styles-formatting"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><StylesAndFormatting /></LockableContent>}
                    />
                    <Route
                        path="grammar-punctuation"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><GrammarAndPunctuation /></LockableContent>}
                    />
                    <Route
                        path="challenging-audio"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><ChallengingAudio /></LockableContent>}
                    />
                    <Route
                        path="specialized-fields"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><SpecializedFields /></LockableContent>}
                    />
                    <Route
                        path="software-technology"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><SoftwareAndTechnology /></LockableContent>}
                    />
                    <Route
                        path="quality-control"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><QualityControl /></LockableContent>}
                    />
                    <Route
                        path="time-management"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><TimeManagement /></LockableContent>}
                    />
                    <Route
                        path="career-building"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><CareerBuilding /></LockableContent>}
                    />
                    <Route
                        path="ethics-confidentiality"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><EthicsAndConfidentiality /></LockableContent>}
                    />
                    <Route
                        path="continuing-education"
                        element={<LockableContent initialLearningStatus={learningStatus.isPaid} userToken={userToken} onUnlock={handleUnlock}><ContinuingEducation /></LockableContent>}
                    />
                </Routes>
            </div>
        </div>
    );
}

export default TranscriptionModule;
