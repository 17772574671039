/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, IconButton, Typography, Box, Tabs, Tab, TextField, Button, Tooltip
} from '@mui/material';
import { Eye, Pencil, Trash2, Check, Plus, Search } from 'lucide-react';
import { API_BASE_URL } from '../config';
import TaskDetails from './TaskDetails';
import EditTask from './EditTask';
import NewTask from './NewTask';

const TaskManagement = () => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    filterTasks();
  }, [searchTerm, tasks, currentTab]);

  const fetchTasks = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/tasks`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        }
      });
      const data = await response.json();
      setTasks(data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const filterTasks = () => {
    const statuses = ['open', 'in progress', 'disputed', 'completed', 'closed'];
    const filteredByStatus = getTasksByStatus(statuses[currentTab]);
    const results = filteredByStatus.filter(task =>
      task.title.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredTasks(results);
  };

  const getTasksByStatus = (status) => {
    return tasks.filter(task => {
      if (status === 'disputed') {
        return task.status === 'disputed';
      }
      else if (status === 'in progress'){
        return task.status === 'in_progress';
      }
      return task.status === status;
    });
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleCompleteTask = async (taskId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/complete-task/${taskId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        }
      });
      if (response.ok) {
        fetchTasks();
      }
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const handleViewTask = (taskId) => {
    setSelectedTaskId(taskId);
    setIsEditing(false);
  };

  const handleEditTask = (taskId) => {
    setSelectedTaskId(taskId);
    setIsEditing(true);
  };

  const handleDeleteTask = async (taskId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/delete-task/${taskId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        }
      });
      if (response.ok) {
        fetchTasks();
      }
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  };

  const handleCloseTaskDetails = () => {
    setSelectedTaskId(null);
    setIsEditing(false);
  };

  const handleAddNewTask = () => {
    setIsAddingNew(true);
  };

  const handleCloseNewTask = () => {
    setIsAddingNew(false);
    fetchTasks();
  };

  const renderTasks = () => {
    return (
      <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
        <Table sx={{ minWidth: 800 }} aria-label="task management table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'primary.main' }}>
              <TableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>Title</TableCell>
              <TableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>Description</TableCell>
              <TableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>Deadline</TableCell>
              <TableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>Price</TableCell>
              <TableCell sx={{ color: 'common.white', fontWeight: 'bold' }}>Assigned To</TableCell>
              <TableCell sx={{ color: 'common.white', fontWeight: 'bold', width: '200px' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTasks.map((task) => (
              <TableRow key={task.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">{task.title}</TableCell>
                <TableCell>{task.description}</TableCell>
                <TableCell>{new Date(task.deadline).toLocaleDateString()}</TableCell>
                <TableCell>Ksh {task.price}</TableCell>
                <TableCell>{task.assignedTo || 'Unassigned'}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Tooltip title="View Task" arrow>
                      <IconButton onClick={() => handleViewTask(task.id)} color="primary">
                        <Eye size={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Task" arrow>
                      <IconButton onClick={() => handleEditTask(task.id)} color="secondary">
                        <Pencil size={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Task" arrow>
                      <IconButton onClick={() => handleDeleteTask(task.id)} color="error">
                        <Trash2 size={20} />
                      </IconButton>
                    </Tooltip>
                    {task.status !== 'completed' && task.status !== 'closed' && (
                      <Tooltip title="Mark as Complete" arrow>
                        <IconButton onClick={() => handleCompleteTask(task.id)} color="success">
                          <Check size={20} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {task.status === 'completed' && (
                      <Tooltip title="Close Task" arrow>
                        <IconButton onClick={() => handleCompleteTask(task.id)} color="success">
                          <Check size={20} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (selectedTaskId && !isEditing) {
    return <TaskDetails taskId={selectedTaskId} onClose={handleCloseTaskDetails} />;
  }

  if (selectedTaskId && isEditing) {
    return <EditTask taskId={selectedTaskId} onClose={handleCloseTaskDetails} onTaskUpdated={fetchTasks} />;
  }

  if (isAddingNew) {
    return <NewTask onClose={handleCloseNewTask} />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" sx={{ mb: 4 }}>Task Management</Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={currentTab} onChange={handleTabChange} aria-label="task status tabs">
          <Tab label="Open" />
          <Tab label="In Progress" />
          <Tab label="In Revision" />
          <Tab label="Completed" />
          <Tab label="Closed" />
        </Tabs>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Plus />}
          onClick={handleAddNewTask}
        >
          Add New Task
        </Button>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search tasks..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <Search size={20} style={{ marginRight: '8px', color: 'gray' }} />
            ),
          }}
        />
      </Box>
      {renderTasks()}
    </Box>
  );
};

export default TaskManagement;