import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function HandlingChallengingAudio() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/specialized-fields');
    };

    return (
        <div className="transcription-intro">
            <header className="header">
                <h1>Handling Challenging Audio</h1>
                <p className="subtitle">Mastering Complex Audio Transcription</p>
            </header>

            <div className="content">
                <div className="section-block">
                    <h2>1. Multiple Speakers</h2>
                    <h3>Techniques for Speaker Differentiation</h3>
                    <p>Transcribing audio with multiple speakers can be tough, especially when people talk over each other or if voices sound similar. Focus on distinguishing features like pitch, tone, and accent.</p>
                    <p><strong>Example:</strong> In a boardroom discussion, one speaker may have a lower, slower tone, while another speaks quickly with a higher pitch. Use these cues to correctly attribute dialogue.</p>
                </div>

                <div className="section-block">
                    <h3>Handling Overlapping Speech</h3>
                    <p>In group discussions, people may talk over one another. Use dashes or brackets to indicate interruptions and overlapping speech, prioritizing the main speaker's message.</p>
                    <p><strong>Example:</strong></p>
                    <p>Speaker 1: "I think—"</p>
                    <p>Speaker 2: "Wait, let me finish."</p>
                </div>

                <div className="section-block">
                    <h2>2. Accents and Dialects</h2>
                    <h3>Strategies for Understanding Unfamiliar Accents</h3>
                    <p>Accents can turn a straightforward transcription into a challenge. Slowing down the playback and using context clues can help you better understand accented speech.</p>
                    <p><strong>Example:</strong> If a speaker has a fast-paced Irish accent, slowing the playback to 75% can help you catch every word more easily.</p>
                </div>

                <div className="section-block">
                    <h3>Resources for Dialect Research</h3>
                    <p>Use resources like the International Dialects of English Archive (IDEA) and podcasts to familiarize yourself with specific accents before transcribing challenging audio.</p>
                </div>

                <div className="section-block">
                    <h2>3. Background Noise</h2>
                    <h3>Filtering Out Irrelevant Sounds</h3>
                    <p>Background noise can interfere with clear transcription. Use noise reduction tools like Audacity or Adobe Audition to isolate speech from distractions.</p>
                    <p><strong>Example:</strong> Use the noise reduction feature in Audacity to lower the volume of background chatter or traffic noise, making the speaker’s voice clearer.</p>
                </div>

                <div className="section-block">
                    <h3>Indicating Significant Background Noises in the Transcript</h3>
                    <p>Sometimes, background noise is relevant to the conversation. Use brackets to indicate non-verbal sounds that affect the context.</p>
                    <p><strong>Example:</strong></p>
                    <p>"We’ll take a short break now. [door slams]"</p>
                    <p>"[laughter] I can’t believe that just happened."</p>
                </div>

                <div className="section-block">
                    <h2>4. Technical Jargon and Specialized Vocabulary</h2>
                    <h3>Research Techniques for Unfamiliar Terms</h3>
                    <p>For specialized fields, using industry-specific dictionaries and quick online searches can help you accurately transcribe unfamiliar jargon.</p>
                    <p><strong>Example:</strong> For medical transcription, reference Merriam-Webster Medical Dictionary to accurately transcribe terms like “angioplasty” or “hypercholesterolemia.”</p>
                </div>

                <div className="section-block">
                    <h3>Building Glossaries for Specific Fields</h3>
                    <p>Create a personal glossary of specialized terms and jargon from frequently transcribed fields. This will save you time and improve accuracy for future projects.</p>
                </div>

                <div className="section-block">
                    <h2>Conclusion: Mastering the Art of Handling Challenging Audio</h2>
                    <p>Handling challenging audio requires practice, patience, and the right tools. By applying the strategies outlined here, you’ll be able to tackle difficult projects with confidence, delivering accurate and professional transcripts even in complex situations.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default HandlingChallengingAudio;
