// src/pages/Introduction.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';
import './Introduction.css';


const IntroductionPage = () => {
    const basicOperationsCode = `
public class BasicOperations {
    public static void main(String[] args) {
        int x = 10;
        int y = 20;
        int sum = x + y;
        int product = x * y;

        System.out.println("Sum: " + sum); // Output: Sum: 30
        System.out.println("Product: " + product); // Output: Product: 200
    }
}
`;

    const ageCheckCode = `
public class AgeCheck {
    public static void main(String[] args) {
        int age = 18;

        if (age >= 18) {
            System.out.println("You are an adult.");
        } else {
            System.out.println("You are a minor.");
        }
    }
}
`;

    const dayOfWeekCode = `
public class DayOfWeek {
    public static void main(String[] args) {
        int day = 3;
        String dayName;

        switch (day) {
            case 1:
                dayName = "Monday";
                break;
            case 2:
                dayName = "Tuesday";
                break;
            case 3:
                dayName = "Wednesday";
                break;
            default:
                dayName = "Unknown";
                break;
        }

        System.out.println("Day of the Week: " + dayName); // Output: Day of the Week: Wednesday
    }
}
`;

    const forLoopCode = `
public class ForLoopExample {
    public static void main(String[] args) {
        for (int i = 1; i <= 5; i++) {
            System.out.println("Iteration: " + i);
        }
    }
}
`;

    const whileLoopCode = `
public class WhileLoopExample {
    public static void main(String[] args) {
        int i = 1;
        while (i <= 5) {
            System.out.println("Iteration: " + i);
            i++;
        }
    }
}
`;

    const doWhileLoopCode = `
public class DoWhileExample {
    public static void main(String[] args) {
        int i = 1;
        do {
            System.out.println("Iteration: " + i);
            i++;
        } while (i <= 5);
    }
}
`;

    const methodExampleCode = `
public class MethodExample {
    public static void main(String[] args) {
        int result = addNumbers(10, 20);
        System.out.println("Sum: " + result); // Output: Sum: 30
    }

    public static int addNumbers(int a, int b) {
        return a + b;
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1 style={{ color: '#4CAF50', fontSize: '32px', fontWeight: 'bold' }}>
                    Introduction to Programming Concepts: A Comprehensive Guide
                </h1>
                <p>
                    Programming is a crucial skill in today’s digital world, and understanding the fundamental concepts is the first step toward becoming proficient in any programming language. In this guide, we will explore the basic building blocks of programming: Variables, Data Types, Operators, Control Structures, Loops, and Functions/Methods. Each of these concepts is foundational to writing effective and efficient code in any language, but we will focus on Java for our examples.
                </p>

                <h2>1. Variables, Data Types, and Operators</h2>
                <h3>Variables</h3>
                <p>
                    Variables are the building blocks of any program. They are used to store data that can be manipulated and retrieved throughout the execution of the program. In Java, variables must be declared with a specific data type before they can be used.
                </p>

                <h3>Data Types</h3>
                <p>
                    Data types specify the type of data that a variable can hold. Java is a statically typed language, meaning that all variables must be declared with a data type.
                </p>
                <ul>
                    <li><strong>int</strong>: Stores integers (whole numbers), e.g., <code>int age = 30;</code></li>
                    <li><strong>double</strong>: Stores floating-point numbers (decimals), e.g., <code>double salary = 85000.50;</code></li>
                    <li><strong>char</strong>: Stores a single character, e.g., <code>char initial = 'A';</code></li>
                    <li><strong>boolean</strong>: Stores true or false values, e.g., <code>boolean isAdult = true;</code></li>
                </ul>

                <h3>Operators</h3>
                <p>
                    Operators are special symbols that perform operations on variables and values. The most common types of operators in Java are:
                </p>
                <ul>
                    <li>Arithmetic Operators: <code>+</code> (Addition), <code>-</code> (Subtraction), <code>*</code> (Multiplication), <code>/</code> (Division), <code>%</code> (Modulus)</li>
                </ul>

                <h3>Example Code:</h3>
                <CodeBlock code={basicOperationsCode} />

                <p>
                    <strong>Application Example:</strong> In a real-world application, variables, data types, and operators are used to calculate and store values. For instance, in a payroll system, you might calculate an employee's weekly salary by multiplying their hourly wage by the number of hours worked.
                </p>

                <h2>2. Control Structures (if-else, switch-case)</h2>
                <p>Control structures allow you to dictate the flow of a program by making decisions based on certain conditions.</p>

                <h3>if-else</h3>
                <p>The if-else statement is used to execute a block of code only if a specified condition is true. If the condition is false, the code in the else block is executed.</p>

                <h3>Example Code:</h3>
                <CodeBlock code={ageCheckCode} />

                <h3>switch-case</h3>
                <p>The switch-case statement is used to execute one of many code blocks based on the value of a variable. It's a cleaner alternative to multiple if-else statements.</p>

                <h3>Example Code:</h3>
                <CodeBlock code={dayOfWeekCode} />

                <p><strong>Application Example:</strong> Control structures are essential in applications like a login system, where the program needs to check if a user's credentials match stored values before granting access.</p>

                <h2>3. Loops (for, while, do-while)</h2>
                <p>Loops are used to execute a block of code repeatedly, either a set number of times or until a certain condition is met.</p>

                <h3>for Loop</h3>
                <p>The for loop is used when you know in advance how many times you want to execute a statement or a block of statements.</p>

                <h3>Example Code:</h3>
                <CodeBlock code={forLoopCode} />

                <h3>while Loop</h3>
                <p>The while loop repeats a block of code as long as the specified condition is true.</p>

                <h3>Example Code:</h3>
                <CodeBlock code={whileLoopCode} />

                <h3>do-while Loop</h3>
                <p>The do-while loop is similar to the while loop, but it guarantees that the code block is executed at least once.</p>

                <h3>Example Code:</h3>
                <CodeBlock code={doWhileLoopCode} />

                <p><strong>Application Example:</strong> Loops are often used in applications that involve repeated tasks, such as processing items in a list or reading lines from a file. For instance, in a shopping cart application, a loop might be used to calculate the total price of items in the cart.</p>

                <h2>4. Functions/Methods (Defining and Calling Methods)</h2>
                <p>Functions (or methods in Java) are blocks of code designed to perform a specific task. They allow for code reuse and better organization.</p>

                <h3>Defining a Method</h3>
                <p>A method is defined with a return type, a name, and optionally, parameters.</p>

                <h3>Example Code:</h3>
                <CodeBlock code={methodExampleCode} />

                <p><strong>Application Example:</strong> Methods are fundamental in any application. For example, in a banking system, you might have methods like deposit and withdraw to handle transactions.</p>

                <p>
                    <strong>Conclusion:</strong> Understanding these core programming concepts is essential for anyone starting out in programming, especially in a versatile language like Java. Mastering variables, data types, operators, control structures, loops, and functions will provide a solid foundation upon which you can build more complex and powerful applications.
                </p>

                <p>
                    By applying these concepts through practical examples and projects, learners can gain a deeper understanding and appreciation for how programming enables the creation of dynamic, functional software. This foundational knowledge will pave the way for more advanced topics and real-world applications.
                </p>

                <NextButton nextPage="/oop-basics" />
            </div>
        </div>
    );
};

export default IntroductionPage;
