import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function IntroductionToTranscription() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/types');
    };

    return (
        <div className="transcription-intro">
            <header className="header">
                <h1>Introduction to Transcription</h1>
                <p className="subtitle">Understanding the Basics</p>
            </header>

            <div className="content">
                <div className="section-block">
                    <h2>1. What is Transcription?</h2>
                    <h3>Definition and Basic Concepts</h3>
                    <p>Transcription is the process of converting spoken language into written text. It involves listening to audio or video recordings and accurately typing out what is being said. The transcriptionist ensures that the text is a faithful and precise representation of the speech, including elements like tone, intonation, and context where needed.</p>

                    <h3>History and Evolution of Transcription</h3>
                    <p>Transcription has a long history, starting with scribes manually transcribing speeches or court proceedings. With the advent of technology, transcription has evolved from shorthand writing to digital transcription, and now includes advanced software and speech-to-text technology. Despite these advancements, human transcription remains essential for context interpretation and accuracy.</p>
                </div>

                <div className="section-block">
                    <h2>2. Importance of Transcription</h2>
                    <h3>Applications in Various Industries</h3>
                    <ul>
                        <li><strong>Legal Industry:</strong> Court reporters and legal transcriptionists transcribe court proceedings, depositions, and hearings. Legal transcription ensures accurate records of spoken interactions in legal contexts.</li>
                        <li><strong>Medical Industry:</strong> Medical transcriptionists transcribe dictations from healthcare professionals, ensuring accurate records for diagnoses and treatment plans.</li>
                        <li><strong>Academic Industry:</strong> Transcription in academia helps convert lectures, interviews, and focus groups into text for research and publications.</li>
                        <li><strong>Media Industry:</strong> Transcription aids in creating subtitles, captions, and scripts for interviews, documentaries, and TV shows, improving accessibility and content distribution.</li>
                    </ul>

                    <h3>Benefits of Written Records</h3>
                    <ul>
                        <li><strong>Accessibility:</strong> Written records make information accessible to the deaf or hard of hearing and improve understanding for non-native speakers.</li>
                        <li><strong>Reference and Documentation:</strong> Transcriptions serve as permanent records for legal, medical, or academic use.</li>
                        <li><strong>Searchability:</strong> Digital transcriptions allow quick searches for specific content within large audio or video files.</li>
                        <li><strong>Content Repurposing:</strong> Transcriptions can be turned into reports, articles, or books, extending the life and reach of the content.</li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>3. Overview of the Transcription Process</h2>
                    <h3>Listening</h3>
                    <p>The process begins with careful listening. Transcriptionists must understand the speaker's accent, tone, and pace. Repeated listening may be necessary for tricky sections or noisy audio.</p>

                    <h3>Typing</h3>
                    <p>While listening, the transcriptionist types out the spoken content with accurate spelling, punctuation, and formatting. Speed and precision are critical, along with the ability to distinguish between speakers in multi-person conversations.</p>

                    <h3>Proofreading</h3>
                    <p>After the initial transcription, proofreading is essential to catch spelling, grammar, or punctuation errors. It also ensures that the names and context are accurate. Certain industries may require adherence to specific style guides.</p>

                    <h3>Basic Workflow</h3>
                    <ul>
                        <li><strong>Prepare:</strong> Review the audio file and set up tools.</li>
                        <li><strong>Transcribe:</strong> Listen and type out the spoken words.</li>
                        <li><strong>Proofread and Edit:</strong> Check for accuracy and clarity.</li>
                        <li><strong>Final Review:</strong> Ensure the transcript follows the required format and industry standards.</li>
                        <li><strong>Submit or Deliver:</strong> Provide the transcript in the required format to the client or end user.</li>
                    </ul>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default IntroductionToTranscription;
