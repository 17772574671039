import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function AudioFileFormats() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/styles-formatting');
    };

    return (
        <div className="transcription-intro">
            <header className="header">
                <h1>Audio File Formats and Quality</h1>
                <p className="subtitle">Optimizing Your Transcription Workflow</p>
            </header>

            <div className="content">
                {/* Common Audio Formats Section */}
                <div className="section-block">
                    <h2>1. Common Audio Formats</h2>
                    <h3>MP3 (MPEG-1 Audio Layer III)</h3>
                    <p><strong>Pros:</strong></p>
                    <ul>
                        <li>Small File Size: Ideal for sharing over the internet or via email.</li>
                        <li>Widespread Compatibility: Supported by almost all devices and software.</li>
                    </ul>
                    <p><strong>Cons:</strong></p>
                    <ul>
                        <li>Lossy Compression: Removes some audio data, which can affect clarity, especially in noisy environments.</li>
                    </ul>
                    <p><strong>Example:</strong> In a noisy interview setting, an MP3 file may make it difficult to distinguish between background noise and the speaker’s voice.</p>

                    <h3>WAV (Waveform Audio File Format)</h3>
                    <p><strong>Pros:</strong></p>
                    <ul>
                        <li>Lossless Quality: Uncompressed, providing high-fidelity audio. Perfect for capturing every nuance of speech.</li>
                        <li>Ideal for Transcription: The clarity of WAV files is excellent for detailed transcription work like legal or medical transcription.</li>
                    </ul>
                    <p><strong>Cons:</strong></p>
                    <ul>
                        <li>Large File Size: Difficult to store and transfer, especially for lengthy recordings.</li>
                    </ul>
                    <p><strong>Example:</strong> In a high-stakes court case, WAV files ensure no detail is lost, allowing for precise transcription.</p>

                    <h3>AAC (Advanced Audio Coding)</h3>
                    <p><strong>Pros:</strong></p>
                    <ul>
                        <li>Better Compression than MP3: Offers higher sound quality at the same bitrates as MP3.</li>
                        <li>Widely Used: The default format for Apple devices and platforms like YouTube.</li>
                    </ul>
                    <p><strong>Cons:</strong></p>
                    <ul>
                        <li>Compatibility: Not as universally supported as MP3, may require additional software for playback.</li>
                    </ul>
                    <p><strong>Example:</strong> When transcribing a podcast recorded in AAC, you'll benefit from better quality compared to MP3 while maintaining manageable file sizes.</p>
                </div>

                {/* Audio Quality Factors Section */}
                <div className="section-block">
                    <h2>2. Audio Quality Factors</h2>
                    <h3>Bitrate and Sample Rate</h3>
                    <p><strong>Bitrate:</strong> The higher the bitrate, the better the sound quality.</p>
                    <ul>
                        <li>Low Bitrate (e.g., 96 kbps): Can result in unclear audio, making transcription challenging.</li>
                        <li>High Bitrate (e.g., 320 kbps): Provides clear, crisp sound, ideal for transcription.</li>
                    </ul>
                    <p><strong>Sample Rate:</strong> The higher the sample rate, the more accurate the sound reproduction.</p>
                    <ul>
                        <li>44.1 kHz or 48 kHz: High-quality audio that’s easy to transcribe.</li>
                        <li>Lower Sample Rates: Result in poorer sound quality, making it harder to distinguish words.</li>
                    </ul>
                    <p><strong>Example:</strong> A podcast recorded at 48 kHz will have better clarity, allowing you to transcribe speech with minimal effort compared to a phone recording with a lower sample rate.</p>

                    <h3>Mono vs. Stereo</h3>
                    <p><strong>Mono Audio:</strong> Recorded on a single channel. While reducing file size, it can make differentiating between speakers harder.</p>
                    <p><strong>Example:</strong> In a mono recording of a panel discussion, it might be challenging to identify who is speaking at any given moment.</p>

                    <p><strong>Stereo Audio:</strong> Recorded across two channels, providing depth. This makes it easier to distinguish between speakers in the recording.</p>
                    <p><strong>Example:</strong> Stereo recordings of interviews allow you to better differentiate speakers, making transcription and labeling easier.</p>
                </div>

                {/* Handling Poor Quality Audio Section */}
                <div className="section-block">
                    <h2>3. Handling Poor Quality Audio</h2>
                    <h3>Techniques for Enhancing Audio</h3>
                    <p><strong>Adjusting Playback Speed:</strong> Slowing down audio can help with fast speakers or challenging accents without distorting the voice.</p>
                    <p><strong>Equalization (EQ):</strong> Boost specific frequencies to enhance the clarity of the speaker's voice, especially in noisy environments.</p>
                    <p><strong>Noise Reduction Software:</strong> Use tools like Audacity to reduce background noise, making the audio clearer for transcription.</p>
                    <p><strong>Example:</strong> In Audacity, the “Noise Reduction” feature allows you to reduce background noise across the entire recording, making a noisy interview much easier to transcribe.</p>

                    <h3>When to Request Better Quality Files</h3>
                    <p>Sometimes, poor-quality audio cannot be fixed, and requesting a higher-quality file is essential.</p>
                    <p><strong>Example:</strong> A voice memo recorded on an old phone may be too compressed to transcribe accurately. Asking for the original recording or a re-recorded version ensures that key details aren't missed.</p>
                </div>

                {/* Conclusion Section */}
                <div className="section-block">
                    <h2>Conclusion: The Power of Audio Knowledge in Transcription</h2>
                    <p>Understanding audio formats and quality will elevate your transcription process, allowing you to deliver accurate transcripts, even in challenging conditions. Knowing how to handle various file formats and improve audio quality will set you apart as a professional transcriptionist capable of delivering exceptional results.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default AudioFileFormats;
