import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { ClipLoader } from 'react-spinners';
import { API_BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const TransactionWallet = () => {
  const [transactions, setTransactions] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false); // New state for handling loading status
  const navigate = useNavigate();

  const onBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken();
          const response = await fetch(`${API_BASE_URL}/api/users/transactions`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const data = await response.json();
          setTransactions(data.transactions);
          setTotalAmount(data.totalAmount || 0);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleWithdrawClick = () => {
    if (!withdrawAmount || isNaN(withdrawAmount) || withdrawAmount <= 0) {
      setMessage('Please enter a valid amount to withdraw.');
      return;
    }

    if (withdrawAmount > totalAmount) {
      setMessage('Insufficient funds for withdrawal.');
      return;
    }

    setIsModalOpen(true);
  };

  const validateAndFormatPhoneNumber = (number) => {
    const cleanNumber = number.replace(/\D/g, '');
    if (cleanNumber.length !== 10 || !['07', '01'].includes(cleanNumber.substring(0, 2))) {
      return { isValid: false, formattedNumber: '' };
    }
    return { isValid: true, formattedNumber: `254${cleanNumber.substring(1)}` };
  };

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    setPhoneNumber(input);
    setPhoneError('');
  };

  const handleWithdraw = async () => {
    setIsProcessing(true); // Set loading state to true when the withdrawal starts

    const { isValid, formattedNumber } = validateAndFormatPhoneNumber(phoneNumber);
    if (!isValid) {
      setPhoneError('Please enter a valid phone number (e.g., 0712345689 or 0123456789)');
      setIsProcessing(false); // Reset loading state if validation fails
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken();
        const response = await fetch(`${API_BASE_URL}/api/training/withdraw`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            amount: parseFloat(withdrawAmount),
            phoneNumber: formattedNumber
          }),
        });

        if (response.ok) {
          const updatedTotalAmount = totalAmount - parseFloat(withdrawAmount);
          setTotalAmount(updatedTotalAmount);
          setMessage('Withdrawal initiated successfully!');
          setWithdrawAmount('');
          setPhoneNumber('');
          setIsModalOpen(false);

          // Refresh transactions
          const response = await fetch(`${API_BASE_URL}/api/users/transactions`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          const data = await response.json();
          setTransactions(data.transactions);
          setTotalAmount(data.totalAmount || 0);
        } else {
          const errorData = await response.json();
          setMessage(`Withdrawal failed: ${errorData.error}`);
        }
      }
    } catch (error) {
      console.error('Error processing withdrawal:', error);
      setMessage('An error occurred while processing your withdrawal.');
    } finally {
      setIsProcessing(false); // Set loading state back to false when the request completes
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-900">
        <ClipLoader color="#38bdf8" loading={loading} size={150} />
      </div>
    );
  }

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <div className="container mx-auto px-4 py-12">
        <button onClick={onBack} className="mb-4 text-teal-400 hover:text-teal-600">Back to Profile</button>
        <h2 className="text-3xl font-bold text-purple-300 mb-8">Transaction Wallet</h2>
        
        <div className="bg-gray-800 p-6 rounded-lg mb-8">
          <h3 className="text-2xl font-semibold text-teal-400 mb-4">Total Balance</h3>
          <p className="text-3xl font-bold text-purple-300 mb-4">Ksh {totalAmount.toFixed(2)}</p>
          <div className="flex items-center">
            <input
              type="number"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
              placeholder="Enter amount to withdraw"
              className="mr-4 p-2 bg-gray-700 text-gray-100 rounded"
            />
            <button
              onClick={handleWithdrawClick}
              className="bg-teal-500 text-gray-900 font-bold py-2 px-4 rounded hover:bg-teal-400 transition duration-300"
            >
              Withdraw
            </button>
          </div>
          {message && <p className="mt-4 text-yellow-400">{message}</p>}
        </div>
        
        {transactions.length > 0 ? (
          <table className="min-w-full bg-gray-800 rounded-lg">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-700 text-left text-teal-400">Description</th>
                <th className="py-2 px-4 border-b border-gray-700 text-left text-teal-400">Amount</th>
                <th className="py-2 px-4 border-b border-gray-700 text-left text-teal-400">Date</th>
                <th className='py-2 px-4 border-b border-gray-700 text-left text-teal-400'>Status</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td className="py-2 px-4 border-b border-gray-700">{transaction.transactionType} Payment</td>
                  <td className="py-2 px-4 border-b border-gray-700">Ksh {transaction.amount}</td>
                  <td className="py-2 px-4 border-b border-gray-700">{new Date(transaction.date).toLocaleDateString()}</td>
                  <td className='py-2 px-4 border-b border-gray-700'>{transaction.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-gray-300">No transactions found.</p>
        )}
      </div>

      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle className="text-2xl font-bold text-purple-300">Initiate Withdrawal</DialogTitle>
        <DialogContent className="bg-gray-800 text-gray-100">
          <p className="mb-4">Please enter your phone number to proceed with the withdrawal of Ksh {withdrawAmount}.</p>
          <TextField
            type="tel"
            placeholder="Enter your phone number (e.g., 0712345689)"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={!!phoneError}
            helperText={phoneError}
            fullWidth
            margin="normal"
            variant="outlined"
            InputProps={{ style: { color: 'white' } }}
            InputLabelProps={{ style: { color: 'white' } }}
          />
          <div className="mt-6 flex justify-end">
            <Button onClick={() => setIsModalOpen(false)} className="bg-gray-600 text-gray-200 py-2 px-4 mr-4 rounded">
              Cancel
            </Button>
            <Button
              onClick={handleWithdraw}
              className="bg-teal-500 text-gray-900 font-bold py-2 px-4 rounded hover:bg-teal-400 transition duration-300"
              disabled={isProcessing} // Disable button while processing
            >
              {isProcessing ? (
                <ClipLoader color="#38bdf8" size={20} />
              ) : (
                'Confirm Withdrawal'
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TransactionWallet;
