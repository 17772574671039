import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Box, 
  Paper, 
  Button, 
  Divider, 
  List, 
  ListItem, 
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip
} from '@mui/material';
import {API_BASE_URL} from '../config';

const TaskDetails = ({ taskId, onClose }) => {
  const [task, setTask] = useState(null);
  const [statusComplete, setStatusComplete] = useState(false);
  const [openRevisionDialog, setOpenRevisionDialog] = useState(false);
  const [revisionComments, setRevisionComments] = useState('');

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/admin/tasks/${taskId}`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
          }
        });
        const data = await response.json();
        setTask(data.task);
        setStatusComplete(data.statusComplete);
      } catch (error) {
        console.error('Error fetching task details:', error);
      }
    };

    fetchTaskDetails();
  }, [taskId]);

  const handleCompleteTask = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/complete-task/${taskId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        }
      });
      if (response.ok) {
        setTask({ ...task, status: 'completed' });
        setStatusComplete(true);
      }
    } catch (error) {
      console.error('Error completing task:', error);
    }
  };

  const handleRequestRevision = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/tasks/${taskId}/request-revision`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ comments: revisionComments })
      });
      if (response.ok) {
        setTask({ 
          ...task, 
          status: 'disputed',
          revisions: [...task.revisions, { requestedAt: new Date(), status: 'pending' }]
        });
        setOpenRevisionDialog(false);
        setRevisionComments('');
      }
    } catch (error) {
      console.error('Error requesting revision:', error);
    }
  };

  if (!task) {
    return <Typography>Loading...</Typography>;
  }

  // Group documents by type
  const getDocumentsByType = (type) => {
    return task.documents?.filter(doc => doc.documentType === type) || [];
  };

  const submissionFiles = getDocumentsByType('submission');
  const revisionFiles = getDocumentsByType('revision');

  return (
    <Box className="w-full p-4">
      <Button 
          onClick={onClose} 
          variant="contained" 
          color="primary"
          className="mb-4"
       >
       Back to Task List
       </Button>

      <Paper className="p-4 mb-4">
        <Typography className="text-2xl mb-4">{task.title}</Typography>
        <Typography><span className="font-bold">Description:</span> {task.description}</Typography>
        <Typography><span className="font-bold">Status:</span> {task.status}</Typography>
        <Typography><span className="font-bold">Price:</span> Ksh {task.price}</Typography>
        <Typography><span className="font-bold">Category:</span> {task.category}</Typography>
        <Typography><span className="font-bold">Deadline:</span> {new Date(task.deadline).toLocaleString()}</Typography>
        <Typography><span className="font-bold">Assigned To:</span> {task.assignedTo || 'Not assigned'}</Typography>
        <Typography><span className="font-bold">Revision Remarks:</span> {task.feedback || 'No revision remarks'}</Typography>
      </Paper>

      {(submissionFiles.length > 0) && (
        <Paper className="p-4 mb-4">
          <Typography className="text-xl mb-4">Initial Submission</Typography>
          <Typography>{task.submissionDetails}</Typography>
          
          {submissionFiles.length > 0 && (
            <Box className="mt-4">
              <Typography className="font-bold mb-2">Submission Files:</Typography>
              <List>
                {submissionFiles.map((doc, index) => (
                  <ListItem key={index}>
                    <a 
                      href={`${API_BASE_URL}/${doc.fileUrl}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700"
                    >
                      {doc.fileName} ({(doc.fileSize / 1024).toFixed(2)} KB)
                    </a>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Paper>
      )}

      {(revisionFiles.length > 0 || task.feedback) && (
        <Paper className="p-4 mb-4">
          <Typography className="text-xl mb-4">Revisions</Typography>
          
          {task.feedback && (
            <Box className="mb-4">
              <Typography className="font-bold">Latest Revision Request:</Typography>
              <Typography className="ml-4">{task.feedback}</Typography>
            </Box>
          )}

          {revisionFiles.length > 0 && (
            <Box>
              <Typography className="font-bold mb-2">Revision Files:</Typography>
              <List>
                {revisionFiles.map((doc, index) => (
                  <ListItem key={index} className="flex items-center">
                    <Box className="flex-1">
                      <a 
                        href={`${API_BASE_URL}/${doc.fileUrl}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-700"
                      >
                        {doc.fileName} ({(doc.fileSize / 1024).toFixed(2)} KB)
                      </a>
                    </Box>
                    <Chip 
                      label={`Revision ${index + 1}`}
                      className="ml-2 bg-gray-200"
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Paper>
      )}

      <Divider className="my-4" />

      {task.status !== 'completed' && !statusComplete && (
        <Button
          className="mr-4"
          variant="contained" 
          color="primary"
          onClick={handleCompleteTask}
        >
          Mark as Completed
        </Button>
      )}

      {task.status !== 'disputed' && (
        <Button
          variant="contained" 
          color="primary"
          onClick={() => setOpenRevisionDialog(true)}
        >
          Request Revision
        </Button>
       )}

      <Dialog open={openRevisionDialog} onClose={() => setOpenRevisionDialog(false)}>
        <DialogTitle>Request Revision</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="revisionComments"
            label="Revision Comments"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={revisionComments}
            onChange={(e) => setRevisionComments(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenRevisionDialog(false)}>Cancel</Button>
          <Button onClick={handleRequestRevision} className="bg-blue-500 hover:bg-blue-600 text-white">
            Submit Revision Request
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TaskDetails;