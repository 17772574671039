import React from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Button } from '@mui/material';
import { Dashboard, People, Assignment, Payment, Gavel, School, Notifications, ExitToApp } from '@mui/icons-material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import DashboardOverview from './DashboardOverview';
import UserManagement from './UserManagement';
import TaskManagement from './TaskManagement';
import PaymentsManagement from './PaymentsManagement';
import LearningVerification from './LearningVerification';
import BidsManagement from './BidsManagement';
import Alerts from './Alerts';

const drawerWidth = 240;

const AdminDashboard = ({ onLogout }) => {
  const navigate = useNavigate();

  const menuItems = [
    { text: 'Dashboard Overview', icon: <Dashboard />, path: '/admin/dashboard' },
    { text: 'User Management', icon: <People />, path: '/admin/users' },
    { text: 'Task Management', icon: <Assignment />, path: '/admin/tasks' },
    { text: 'Bids Management', icon: <Gavel />, path: '/admin/bids' },
    { text: 'Payments Management', icon: <Payment />, path: '/admin/payments' },
    { text: 'User Verification', icon: <School />, path: '/admin/learning-verification' },
    { text: 'Alerts & Notifications', icon: <Notifications />, path: '/admin/alerts' },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" noWrap component="div">
            Admin Dashboard
          </Typography>
          <Button color="inherit" onClick={onLogout} startIcon={<ExitToApp />}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {menuItems.map((item, index) => (
              <ListItem button key={index} onClick={() => navigate(item.path)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: 'white' }}>
        <Toolbar />
        <Routes>
          <Route path="dashboard" element={<DashboardOverview />} />
          <Route path="users" element={<UserManagement />} />
          <Route path="tasks" element={<TaskManagement />} />
          <Route path="bids" element={<BidsManagement />} />
          <Route path="payments" element={<PaymentsManagement />} />
          <Route path="learning-verification" element={<LearningVerification />} />
          <Route path="alerts" element={<Alerts />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminDashboard;