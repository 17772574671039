import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function BuildingCareer() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/ethics-confidentiality');
    };

    return (
        <div className="building-career-intro">
            <header className="header">
                <h1>Building a Career in Transcription</h1>
                <p className="subtitle">Turning Your Skills into a Thriving Career</p>
            </header>

            <div className="content">
                <div className="section-block">
                    <h2>Embarking on a Career in Transcription</h2>
                    <p>Embarking on a career in transcription can be a rewarding and flexible path, with opportunities to work in diverse industries. Whether you choose to go the freelance route or seek an employee position, understanding the landscape of the transcription industry is key to building a sustainable career. In this guide, we’ll explore how to weigh the pros and cons of different career paths, create a professional portfolio, price your services competitively, and market yourself effectively. These insights will empower you to turn your transcription skills into a thriving business or a long-term job.</p>
                </div>

                <div className="section-block">
                    <h2>1. Freelance vs. Employee Positions</h2>
                    <h3>Pros and Cons of Freelancing</h3>
                    <ul>
                        <li><strong>Flexibility:</strong> As a freelancer, you control your schedule. You can work from anywhere, choose your projects, and decide how much work to take on.</li>
                        <li><strong>Earnings Potential:</strong> Freelancers can set their own rates, allowing for higher earning potential if you build a strong reputation.</li>
                        <li><strong>Diverse Work:</strong> You can work with various clients in different industries, from legal to media to medical, giving you exposure to different types of transcription work.</li>
                    </ul>
                    <h4>Cons:</h4>
                    <ul>
                        <li><strong>Inconsistent Income:</strong> Freelancers may experience fluctuating income, especially when starting out, as projects and clients may not always be consistent.</li>
                        <li><strong>Self-Management:</strong> You are responsible for all aspects of your business, from marketing to invoicing, which can be time-consuming and require business management skills.</li>
                        <li><strong>No Benefits:</strong> Freelancers don’t receive employee benefits like health insurance, paid time off, or retirement plans.</li>
                    </ul>

                    <h3>Pros and Cons of Employee Positions</h3>
                    <ul>
                        <li><strong>Steady Income:</strong> As an employee, you’ll have a consistent paycheck and benefits like paid time off, health insurance, and job security.</li>
                        <li><strong>Less Business Management:</strong> Your focus is solely on transcription work, without the need to market your services or manage client relationships.</li>
                        <li><strong>Training and Development:</strong> Many employers provide training, ongoing education, and resources to help you improve your transcription skills.</li>
                    </ul>
                    <h4>Cons:</h4>
                    <ul>
                        <li><strong>Less Flexibility:</strong> As an employee, your schedule is often dictated by the employer, and there may be less freedom to choose which projects you work on.</li>
                        <li><strong>Lower Earning Potential:</strong> Salaried transcription positions typically offer lower pay than what experienced freelancers can earn per project or hour.</li>
                    </ul>

                    <h3>How to Find Opportunities</h3>
                    <p>For freelancers, platforms like Upwork, Freelancer, and Fiverr can help you find transcription jobs. You can also specialize by joining niche platforms like Rev or TranscribeMe, which focus solely on transcription work. Networking is crucial—building relationships with clients can lead to repeat business and referrals.</p>
                    <p>For employee positions, you can search job boards such as Indeed, LinkedIn, and Glassdoor. Many companies in legal, medical, and media industries offer transcription positions, so targeting these sectors can also help.</p>
                </div>

                <div className="section-block">
                    <h2>2. Building a Professional Portfolio</h2>
                    <h3>Showcasing Your Skills and Experience</h3>
                    <p>Your portfolio is your most powerful tool for attracting clients or employers. It’s your chance to showcase your skills, highlight your experience, and make a strong first impression. A well-crafted portfolio will help you stand out in a competitive field.</p>
                    <ul>
                        <li><strong>Transcription Samples:</strong> Showcase different types of transcription, such as multi-speaker interviews, podcasts, and medical dictations, to demonstrate your versatility.</li>
                        <li><strong>Turnaround Times:</strong> Highlight your speed and accuracy by including details on how long it took to transcribe each sample.</li>
                        <li><strong>Client Testimonials:</strong> If possible, include feedback from previous clients to build credibility.</li>
                    </ul>

                    <h3>Creating a Professional Online Presence</h3>
                    <p>Having a professional online presence is essential for attracting clients or securing job offers. This starts with building a polished website or LinkedIn profile where potential clients can view your work, learn about your services, and contact you.</p>
                    <p><strong>Key Elements of Your Online Presence:</strong></p>
                    <ul>
                        <li>About You: A concise bio highlighting your experience, skills, and what makes you a standout transcriptionist.</li>
                        <li>Portfolio: Include your transcription samples with downloadable versions or links to view the files.</li>
                        <li>Services Offered: Clearly list the types of transcription services you provide (e.g., legal, medical, academic), along with your rates and turnaround times.</li>
                        <li>Contact Information: Ensure clients or employers can easily reach you through email or an online contact form.</li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>3. Pricing Your Services</h2>
                    <h3>Understanding Industry Rates</h3>
                    <p>Transcription rates vary widely depending on the industry and the type of transcription (e.g., legal transcription often pays more than general transcription). Rates are typically charged per audio minute, per hour, or per word. Research the average rates in your chosen niche, and start pricing accordingly.</p>
                    <ul>
                        <li>General transcription: $0.75 to $1.50 per audio minute</li>
                        <li>Legal transcription: $1.25 to $2.50 per audio minute</li>
                        <li>Medical transcription: $1.00 to $2.00 per audio minute</li>
                    </ul>

                    <h3>Factors Affecting Pricing</h3>
                    <ul>
                        <li><strong>Turnaround Time:</strong> Rush jobs typically command higher rates. If a client needs a transcript within 24 hours, you can charge a premium for the faster delivery.</li>
                        <li><strong>Audio Quality:</strong> Poor-quality audio that includes background noise, unclear speakers, or multiple speakers will require more time to transcribe accurately.</li>
                        <li><strong>Specialization:</strong> If your transcription involves specialized knowledge—like legal or medical terminology—you can charge more due to the expertise required.</li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>4. Marketing Your Transcription Services</h2>
                    <h3>Networking Strategies</h3>
                    <p>Networking can lead to job referrals, new clients, and collaborations. Start by reaching out to other transcriptionists, joining transcription forums, and participating in industry-related groups on LinkedIn or Facebook. Attend webinars or industry events to connect with potential clients or other professionals in your field.</p>

                    <h3>Online Platforms for Transcriptionists</h3>
                    <p>Several online platforms can help you find clients and showcase your services. In addition to general freelance websites, there are platforms specifically tailored to transcription work:</p>
                    <ul>
                        <li>Rev.com: A popular platform for general transcriptionists with a wide variety of jobs.</li>
                        <li>Scribie: Offers freelance transcription jobs with flexible hours.</li>
                        <li>TranscribeMe: Another platform focused on transcription, particularly for beginners.</li>
                    </ul>
                </div>

                <div className="section-block">
                    <h2>Conclusion: Taking Control of Your Transcription Career</h2>
                    <p>Building a successful career in transcription requires more than just typing skills. It’s about making strategic decisions—whether freelancing or seeking employment, creating a professional portfolio, setting competitive rates, and marketing your services effectively. By following these steps, you’ll position yourself as a top-tier transcriptionist in a growing field.</p>
                    <p>Your career is in your hands. By focusing on continuous improvement, smart pricing strategies, and effective self-promotion, you can create a fulfilling and successful transcription career. Whether you want the flexibility of freelancing or the stability of an employee position, the key is staying proactive, delivering high-quality work, and always striving to grow your network and skills. This approach will ensure long-term success in your transcription journey.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default BuildingCareer;
