// src/pages/AdvancedDataStructures.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';
import './AdvancedDataStructures.css';




const AdvancedDataStructures = () => {
    const hashMapCode = `
import java.util.HashMap;

public class HashMapExample {
    public static void main(String[] args) {
        // Creating a HashMap
        HashMap<String, Integer> map = new HashMap<>();

        // Adding elements to the map
        map.put("Apple", 10);
        map.put("Banana", 20);
        map.put("Orange", 30);

        // Retrieving a value
        System.out.println("Value for Apple: " + map.get("Apple")); // Output: 10

        // Removing a key-value pair
        map.remove("Banana");

        // Iterating over the map
        for (String key : map.keySet()) {
            System.out.println("Key: " + key + ", Value: " + map.get(key));
        }
    }
}
`;

    const treeMapCode = `
import java.util.TreeMap;

public class TreeMapExample {
    public static void main(String[] args) {
        // Creating a TreeMap
        TreeMap<String, Integer> map = new TreeMap<>();

        // Adding elements to the map
        map.put("Apple", 10);
        map.put("Banana", 20);
        map.put("Orange", 30);

        // Retrieving the first and last keys
        System.out.println("First Key: " + map.firstKey()); // Output: Apple
        System.out.println("Last Key: " + map.lastKey());   // Output: Orange

        // Iterating over the map
        for (String key : map.keySet()) {
            System.out.println("Key: " + key + ", Value: " + map.get(key));
        }
    }
}
`;

    const hashSetCode = `
import java.util.HashSet;

public class HashSetExample {
    public static void main(String[] args) {
        // Creating a HashSet
        HashSet<String> set = new HashSet<>();

        // Adding elements to the set
        set.add("Apple");
        set.add("Banana");
        set.add("Orange");
        set.add("Apple"); // Duplicate, will not be added

        // Iterating over the set
        for (String fruit : set) {
            System.out.println(fruit);
        }
    }
}
`;

    const queueCode = `
import java.util.LinkedList;
import java.util.Queue;

public class QueueExample {
    public static void main(String[] args) {
        // Creating a Queue using LinkedList
        Queue<String> queue = new LinkedList<>();

        // Adding elements to the queue
        queue.offer("John");
        queue.offer("Jane");
        queue.offer("Jack");

        // Retrieving and removing the head of the queue
        System.out.println("Removed from queue: " + queue.poll()); // Output: John

        // Peeking at the head of the queue
        System.out.println("Head of queue: " + queue.peek()); // Output: Jane
    }
}
`;

    const stackCode = `
import java.util.LinkedList;

public class StackExample {
    public static void main(String[] args) {
        // Creating a Stack using LinkedList
        LinkedList<String> stack = new LinkedList<>();

        // Pushing elements onto the stack
        stack.push("A");
        stack.push("B");
        stack.push("C");

        // Popping elements from the stack
        System.out.println("Popped from stack: " + stack.pop()); // Output: C

        // Peeking at the top of the stack
        System.out.println("Top of stack: " + stack.peek()); // Output: B
    }
}
`;

    const genericsClassCode = `
class GenericClass<T> {
    private T value;

    public void setValue(T value) {
        this.value = value;
    }

    public T getValue() {
        return value;
    }
}

public class GenericsExample {
    public static void main(String[] args) {
        // Using GenericClass with Integer
        GenericClass<Integer> intObj = new GenericClass<>();
        intObj.setValue(10);
        System.out.println("Integer Value: " + intObj.getValue()); // Output: 10

        // Using GenericClass with String
        GenericClass<String> strObj = new GenericClass<>();
        strObj.setValue("Hello Generics");
        System.out.println("String Value: " + strObj.getValue()); // Output: Hello Generics
    }
}
`;

    const genericsMethodCode = `
public class GenericMethodExample {
    public static void main(String[] args) {
        // Array of Integers
        Integer[] intArray = {1, 2, 3, 4, 5};
        // Array of Strings
        String[] strArray = {"A", "B", "C", "D", "E"};

        // Calling the generic method
        printArray(intArray); // Output: 1 2 3 4 5
        printArray(strArray); // Output: A B C D E
    }

    // Generic method
    public static <T> void printArray(T[] array) {
        for (T element : array) {
            System.out.println(element);
        }
    }
}
`;

    const boundedGenericsCode = `
public class BoundedGenericsExample {
    public static void main(String[] args) {
        // Calling the generic method with Integer
        printDouble(10); // Output: 10.0

        // Calling the generic method with Double
        printDouble(10.5); // Output: 10.5

        // The following line would cause a compile-time error
        // printDouble("Hello"); // Error: Type parameter T must extend Number
    }

    public static <T extends Number> void printDouble(T number) {
        System.out.println(number.doubleValue());
    }
}

const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/java-learning/multithreading');
    }
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Advanced Data Structures in Java: A Comprehensive Guide</h1>
                <p>
                    Advanced data structures are essential for efficiently solving complex problems and optimizing performance in software development. Java provides a rich set of data structures through the Java Collections Framework, which includes various implementations like HashMap, TreeMap, Set, Queue, Stack, and more. Additionally, Java supports generics, which allow for type-safe data structures. This guide will cover these advanced data structures in detail, including how to use them, when to use them, and real-world applications.
                </p>

                <hr className="section-divider" />

                <h2>1. HashMap, TreeMap, Set, and Queue</h2>

                <h3>HashMap</h3>
                <p>
                    HashMap is a part of the Java Collections Framework that implements the Map interface. It stores key-value pairs and allows for fast retrieval based on keys. The HashMap does not maintain any order of the keys or values and allows one null key and multiple null values.
                </p>

                <h4>Basic Operations:</h4>
                <ul>
                    <li><strong>put(K key, V value):</strong> Inserts the key-value pair into the map.</li>
                    <li><strong>get(Object key):</strong> Retrieves the value associated with the key.</li>
                    <li><strong>remove(Object key):</strong> Removes the key-value pair from the map.</li>
                </ul>

                <h3>Example Code:</h3>
                <CodeBlock code={hashMapCode} />

                <h4>Output:</h4>
                <pre>
                    Value for Apple: 10{'\n'}
                    Key: Apple, Value: 10{'\n'}
                    Key: Orange, Value: 30
                </pre>

                <p><strong>Explanation:</strong> This example demonstrates how to add, retrieve, and remove elements in a HashMap. The HashMap is particularly useful when you need fast lookups and are not concerned with the order of elements.</p>

                <h3>TreeMap</h3>
                <p>
                    TreeMap is another implementation of the Map interface, but unlike HashMap, it stores the keys in a sorted order (based on their natural ordering or by a Comparator provided at map creation). It does not allow null keys but can have multiple null values.
                </p>

                <h4>Basic Operations:</h4>
                <ul>
                    <li><strong>put(K key, V value):</strong> Inserts the key-value pair into the map.</li>
                    <li><strong>get(Object key):</strong> Retrieves the value associated with the key.</li>
                    <li><strong>firstKey():</strong> Retrieves the first (lowest) key.</li>
                    <li><strong>lastKey():</strong> Retrieves the last (highest) key.</li>
                </ul>

                <h3>Example Code:</h3>
                <CodeBlock code={treeMapCode} />

                <h4>Output:</h4>
                <pre>
                    First Key: Apple{'\n'}
                    Last Key: Orange{'\n'}
                    Key: Apple, Value: 10{'\n'}
                    Key: Banana, Value: 20{'\n'}
                    Key: Orange, Value: 30
                </pre>

                <p><strong>Explanation:</strong> This example shows how to add, retrieve, and iterate over elements in a TreeMap. The TreeMap is ideal when you need the keys to be in a sorted order.</p>

                <h3>Set</h3>
                <p>
                    A Set is a collection that does not allow duplicate elements. Java provides several implementations of the Set interface, including HashSet, LinkedHashSet, and TreeSet.
                </p>
                <ul>
                    <li><strong>HashSet:</strong> Does not maintain any order of the elements.</li>
                    <li><strong>LinkedHashSet:</strong> Maintains the insertion order of the elements.</li>
                    <li><strong>TreeSet:</strong> Maintains the elements in sorted order.</li>
                </ul>

                <h3>Example Code (HashSet):</h3>
                <CodeBlock code={hashSetCode} />

                <h4>Output:</h4>
                <pre>
                    Banana{'\n'}
                    Orange{'\n'}
                    Apple
                </pre>

                <p><strong>Explanation:</strong> This example shows how to add and iterate over elements in a HashSet. The HashSet is useful when you need to ensure that no duplicates are present and are not concerned with the order of elements.</p>

                <h3>Queue</h3>
                <p>
                    A Queue is a collection used to hold multiple elements prior to processing. It follows the First-In-First-Out (FIFO) principle. Common implementations in Java include LinkedList and PriorityQueue.
                </p>

                <h4>Basic Operations:</h4>
                <ul>
                    <li><strong>offer(E e):</strong> Inserts the specified element into the queue.</li>
                    <li><strong>poll():</strong> Retrieves and removes the head of the queue.</li>
                    <li><strong>peek():</strong> Retrieves, but does not remove, the head of the queue.</li>
                </ul>

                <h3>Example Code (LinkedList as Queue):</h3>
                <CodeBlock code={queueCode} />

                <h4>Output:</h4>
                <pre>
                    Removed from queue: John{'\n'}
                    Head of queue: Jane
                </pre>

                <p><strong>Explanation:</strong> This example demonstrates basic queue operations using LinkedList as a Queue. Queues are often used in scenarios like task scheduling where order matters.</p>

                <p><strong>Real-World Application:</strong> These data structures are widely used in various applications. For instance, a HashMap might be used in a caching system to quickly retrieve data based on keys, while a TreeMap could be used to store and retrieve data in a sorted manner, such as in a leaderboard system. A Set could be used to maintain a collection of unique items, like tags or categories in a content management system. A Queue is essential in task scheduling systems where tasks are processed in the order they arrive.</p>

                <hr className="section-divider" />

                <h2>2. Stacks and Queues (Using LinkedList)</h2>

                <h3>Stack</h3>
                <p>
                    A Stack is a collection that follows the Last-In-First-Out (LIFO) principle. The most recent element added to the stack is the first one to be removed. The Stack class in Java is a legacy class, but it can be effectively implemented using a LinkedList.
                </p>

                <h4>Basic Operations:</h4>
                <ul>
                    <li><strong>push(E e):</strong> Pushes an element onto the stack.</li>
                    <li><strong>pop():</strong> Removes and returns the top element of the stack.</li>
                    <li><strong>peek():</strong> Returns the top element of the stack without removing it.</li>
                </ul>

                <h3>Example Code (Stack using LinkedList):</h3>
                <CodeBlock code={stackCode} />

                <h4>Output:</h4>
                <pre>
                    Popped from stack: C{'\n'}
                    Top of stack: B
                </pre>

                <p><strong>Explanation:</strong> This example shows how to implement a stack using LinkedList, demonstrating basic operations like push, pop, and peek. Stacks are useful in scenarios like parsing expressions, undo mechanisms in editors, and managing function calls.</p>

                <h3>Queue</h3>
                <p>
                    As discussed earlier, a Queue follows the First-In-First-Out (FIFO) principle. When implemented using LinkedList, it provides efficient insertion and removal operations.
                </p>

                <h3>Example Code Revisited (Queue using LinkedList):</h3>
                <CodeBlock code={queueCode} />

                <h4>Output:</h4>
                <pre>
                    Removed from queue: John{'\n'}
                    Head of queue: Jane
                </pre>

                <p><strong>Explanation:</strong> Similar to the previous Queue example, but it emphasizes that LinkedList can be used interchangeably to implement both stacks and queues, demonstrating its versatility.</p>

                <p><strong>Real-World Application:</strong> Stacks and queues are fundamental in many areas of computer science. Stacks are used in depth-first search algorithms, expression evaluation (infix, postfix), and memory management. Queues are commonly used in breadth-first search algorithms, task scheduling, and print job management.</p>

                <hr className="section-divider" />

                <h2>3. Understanding Generics</h2>
                <p>
                    Generics enable types (classes and interfaces) to be parameters when defining classes, interfaces, and methods. The idea is to allow code to be written that can operate on objects of various types while providing compile-time type safety.
                </p>

                <h3>Why Use Generics?</h3>
                <ul>
                    <li><strong>Type Safety:</strong> Ensures that a class or method works with a specific data type.</li>
                    <li><strong>Code Reusability:</strong> Allows you to write more general and reusable code.</li>
                    <li><strong>Elimination of Casts:</strong> Reduces the need for casting when retrieving elements from a collection.</li>
                </ul>

                <h3>Generic Classes</h3>

                <h4>Syntax:</h4>
                <CodeBlock code={`class GenericClass<T> {\n    private T value;\n\n    public void setValue(T value) {\n        this.value = value;\n    }\n\n    public T getValue() {\n        return value;\n    }\n}`} />

                <h3>Example Code:</h3>
                <CodeBlock code={genericsClassCode} />

                <h4>Output:</h4>
                <pre>
                    Integer Value: 10{'\n'}
                    String Value: Hello Generics
                </pre>

                <p><strong>Explanation:</strong> The class <em>GenericClass</em> can work with any data type (as defined by <em>T</em>). The code demonstrates how the same class can be used with different data types, showcasing the flexibility and type safety that generics offer.</p>

                <h3>Generic Methods</h3>

                <h4>Syntax:</h4>
                <CodeBlock code={`public static <T> void printArray(T[] array) {\n    for (T element : array) {\n        System.out.println(element);\n    }\n}`} />

                <h3>Example Code:</h3>
                <CodeBlock code={genericsMethodCode} />

                <h4>Output:</h4>
                <pre>
                    1{'\n'}
                    2{'\n'}
                    3{'\n'}
                    4{'\n'}
                    5{'\n'}
                    A{'\n'}
                    B{'\n'}
                    C{'\n'}
                    D{'\n'}
                    E
                </pre>

                <p><strong>Explanation:</strong> The <em>printArray</em> method can accept arrays of any type due to the use of generics (<em>&lt;T&gt;</em>). This allows the same method to print arrays of integers, strings, or any other object type.</p>

                <h3>Bounded Generics</h3>

                <h4>Syntax:</h4>
                <CodeBlock code={`public static <T extends Number> void printDouble(T number) {\n    System.out.println(number.doubleValue());\n}`} />

                <h3>Example Code:</h3>
                <CodeBlock code={boundedGenericsCode} />

                <h4>Output:</h4>
                <pre>
                    10.0{'\n'}
                    10.5
                </pre>

                <p><strong>Explanation:</strong> The method <em>printDouble</em> only accepts arguments that are subclasses of <em>Number</em>, such as <em>Integer</em> or <em>Double</em>. This ensures that the method can call <em>doubleValue()</em> on the argument.</p>

                <p><strong>Real-World Application:</strong> Generics are extensively used in the Java Collections Framework. For example, when you create a <em>List&lt;String&gt;</em>, you are using generics to specify that the list will only contain <em>String</em> objects. This ensures type safety and eliminates the need for casting when retrieving elements. Generics also play a crucial role in implementing reusable and type-safe data structures, such as trees, graphs, and algorithms like sorting and searching.</p>

                <hr className="section-divider" />

                <p><strong>Conclusion:</strong> Advanced data structures like <em>HashMap</em>, <em>TreeMap</em>, <em>Set</em>, <em>Queue</em>, <em>Stack</em>, and the use of generics are vital tools in a Java developer's arsenal. These structures allow for efficient data storage, retrieval, and manipulation, enabling developers to solve complex problems effectively. Understanding when and how to use these data structures, as well as the principles of generics, will help you write more robust, flexible, and maintainable code.</p>

                <p>By mastering these advanced concepts, you'll be well-equipped to tackle challenging software development tasks, from designing efficient algorithms to building scalable applications. Whether you're working on small utility programs or large-scale enterprise systems, these advanced data structures and generics will empower you to develop high-quality, performance-optimized software in Java.</p>

                <NextButton nextPage="/multithreading" />
            </div>
        </div>
    );
};

export default AdvancedDataStructures;
