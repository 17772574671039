import React, { useEffect } from 'react';
import './TypesTranscription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function TypesTranscription() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/skills');
    };

    return (
        <div className="transcription-intro">
            <header className="header">
                <h1>Types of Transcription</h1>
            </header>

            <div className="content">
                {/* Verbatim Transcription */}
                <div className="section-block">
                    <h2>Verbatim Transcription</h2>
                    <h3>Full Verbatim vs. Clean Verbatim</h3>
                    <div className="sub-section">
                        <h4>Full Verbatim</h4>
                        <p>
                            Full verbatim captures everything spoken, including filler words, stutters, pauses, non-verbal sounds (e.g., laughter, sighs),
                            repetitions, and even incomplete sentences. Every sound and word is transcribed, making it valuable when the speaker's exact wording matters.
                        </p>
                        <p><strong>Example (Full Verbatim):</strong> “Uh, I, I just wanted to, um, let you know that, uh, yeah, I’ll be late, uh, tomorrow... like, around 9.”</p>
                    </div>
                    <div className="sub-section">
                        <h4>Clean Verbatim</h4>
                        <p>
                            Clean verbatim removes unnecessary fillers and distractions, producing a readable version of the speech without altering its meaning.
                        </p>
                        <p><strong>Example (Clean Verbatim):</strong> “I just wanted to let you know that I’ll be late tomorrow, around 9.”</p>
                    </div>
                    <div className="sub-section">
                        <h4>When to Use Verbatim Transcription</h4>
                        <ul>
                            <li><strong>Full Verbatim:</strong> Legal settings, media production, research.</li>
                            <li><strong>Clean Verbatim:</strong> Business meetings, interviews, podcasts.</li>
                        </ul>
                    </div>
                </div>

                {/* Clean Read Transcription */}
                <div className="section-block">
                    <h2>Clean Read Transcription</h2>
                    <p>
                        Clean read transcription offers a highly readable version of a transcript, improving grammar and coherence while maintaining the speaker's intended meaning.
                    </p>
                    <p><strong>Example:</strong> "I think it would be a great idea to proceed with the project by next week."</p>
                    <p><strong>Uses:</strong> Conferences, reports, training materials.</p>
                </div>

                {/* Edited Transcription */}
                <div className="section-block">
                    <h2>Edited Transcription</h2>
                    <p>
                        Edited transcription balances accuracy and readability, rewording sentences for clarity while staying true to the original speech.
                    </p>
                    <p><strong>Example:</strong> "It's essential to understand the basic concepts before advancing to more complex topics."</p>
                    <p><strong>Uses:</strong> Publishing interviews, educational content, corporate documents.</p>
                </div>

                {/* Phonetic Transcription */}
                <div className="section-block">
                    <h2>Phonetic Transcription</h2>
                    <p>
                        Phonetic transcription records speech sounds using the International Phonetic Alphabet (IPA), helping learners and linguists understand pronunciation.
                    </p>
                    <p><strong>Example:</strong> The word "cat" is transcribed as /kæt/ in IPA.</p>
                    <p><strong>Uses:</strong> Linguistics, language learning.</p>
                </div>

                {/* Specialized Transcription Types */}
                <div className="section-block">
                    <h2>Specialized Transcription Types</h2>
                    <div className="sub-section">
                        <h4>Closed Captioning</h4>
                        <p>
                            Closed captioning provides a verbatim transcription of spoken dialogue, sound effects, and other auditory information for the deaf or hard of hearing.
                        </p>
                        <p><strong>Example:</strong> "We're heading to the office now [footsteps]."</p>
                    </div>
                    <div className="sub-section">
                        <h4>Subtitling</h4>
                        <p>
                            Subtitling is a translated version of dialogue in video content, often condensed to fit within the screen time available.
                        </p>
                        <p><strong>Example:</strong> "We're leaving early for the airport."</p>
                    </div>
                    <div className="sub-section">
                        <h4>Real-Time Transcription</h4>
                        <p>
                            Real-time transcription captures speech as it happens, used in court reporting, live television broadcasts, and webinars.
                        </p>
                    </div>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default TypesTranscription;
