import React, { useEffect } from 'react';
import './ETranscription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function EssentialSkills() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/tools-equipment');
    };

    return (
        <div className="transcription-intro">
            <header className="header">
                <h1>Essential Skills for Transcriptionists</h1>
                <p className="subtitle">Mastering the Art of Transcription</p>
            </header>

            <div className="content">
                {/* Listening Skills */}
                <div className="section-block">
                    <h2>1. Listening Skills</h2>
                    <p>
                        Becoming a skilled transcriptionist requires more than just the ability to type quickly. It demands a blend of attentiveness,
                        technical expertise, and a keen understanding of language. These skills aren’t just essential for success—they’re the foundation
                        of an outstanding transcriptionist who can consistently produce accurate, polished work. Let’s dive deep into the crucial skills
                        that every transcriptionist must master.
                    </p>
                    <p>
                        Transcription is more than just typing—it’s about listening with precision. Your ability to understand, interpret, and transcribe
                        spoken words accurately depends on how well you listen. This means developing a sharp ear for detail, distinguishing between different
                        voices, accents, and even background noises.
                    </p>
                    <p><strong>Active Listening Techniques:</strong> Active listening means focusing entirely on the speaker without distractions. It’s about being present in the moment.
                        A good transcriptionist listens carefully to identify key points, tonal shifts, and contextual nuances. Techniques such as taking brief pauses
                        to replay sections, or using transcription software to slow down audio, can make a huge difference in accuracy.
                    </p>
                    <p><strong>Example:</strong> Imagine listening to a speaker with a heavy accent discussing medical procedures. Active listening will allow you to catch medical jargon that might be
                        challenging, ensuring nothing is missed or misinterpreted.
                    </p>
                    <p><strong>Developing Audio Discrimination:</strong> Not all audio files are created equal. Some may be recorded in noisy environments, others may have multiple speakers talking over each other.
                        A transcriptionist must hone the ability to distinguish speech from background noise, focusing on the speaker’s voice while filtering out distractions.
                        With practice, you’ll be able to discern speech even in low-quality recordings.
                    </p>
                    <p><strong>Pro tip:</strong> Start practicing with difficult audio files. Push yourself to transcribe conversations with multiple speakers or listen to recordings in different accents to strengthen this skill.
                    </p>
                </div>

                {/* Typing Speed and Accuracy */}
                <div className="section-block">
                    <h2>2. Typing Speed and Accuracy</h2>
                    <p>
                        While listening is key, transcriptionists must also type quickly and accurately to meet deadlines without sacrificing quality. A fast typist
                        with low accuracy is just as problematic as a slow typist with high accuracy. Striking the right balance is critical.
                    </p>
                    <p><strong>Typing Tests and Improvement Strategies:</strong> Regularly test your typing speed and accuracy using online tools such as TypingTest.com or KeyHero.
                        Challenge yourself to type faster while maintaining near-perfect accuracy. Practice daily, aiming for a typing speed of at least 60-80 words per minute (wpm)
                        to handle complex transcription tasks efficiently.
                    </p>
                    <p><strong>Example:</strong> If you’re transcribing an hour-long audio file, being able to type at 75 wpm instead of 50 wpm could reduce your work time significantly while improving your overall workflow.
                    </p>
                    <p><strong>Ergonomics for Long-Term Typing Health:</strong> Transcription involves long hours at the keyboard, which can strain your wrists, hands, and back. Proper ergonomics are crucial for maintaining
                        your health and preventing repetitive stress injuries like carpal tunnel syndrome. Invest in a comfortable chair, ensure your desk and keyboard are at the right height, and take regular breaks to stretch.
                    </p>
                    <p><strong>Tip:</strong> Use ergonomic keyboards, wrist rests, and consider sitting in a position that promotes good posture. Incorporate the 20-20-20 rule: every 20 minutes, look 20 feet away for 20 seconds to reduce eye strain.
                    </p>
                </div>

                {/* Language Proficiency */}
                <div className="section-block">
                    <h2>3. Language Proficiency</h2>
                    <p>
                        A transcriptionist must be a master of language—whether it’s grammar, spelling, or punctuation. The ability to produce a flawless, grammatically sound transcript is essential.
                        A well-transcribed document should read smoothly without grammatical or spelling errors.
                    </p>
                    <p><strong>Grammar, Spelling, and Punctuation:</strong> Excellent grammar isn’t negotiable in transcription. You must know when to use proper punctuation, how to structure sentences, and how to avoid common
                        spelling mistakes. Review grammar rules frequently, and use tools like Grammarly or Microsoft Word’s spell checker as a backup, but don’t rely on them entirely.
                    </p>
                    <p><strong>Example:</strong> Punctuating a transcript incorrectly can change its meaning entirely. Consider the difference between "Let’s eat, grandma!" and "Let’s eat grandma!" Punctuation saves lives—and reputations!
                    </p>
                    <p><strong>Vocabulary Expansion:</strong> A rich vocabulary is the cornerstone of professional transcription. The more words and terms you’re familiar with, the quicker you’ll be able to transcribe without stopping
                        to look up words. Read extensively, especially in the fields you specialize in (legal, medical, business), to expand your lexicon.
                    </p>
                    <p><strong>Example:</strong> If you’re transcribing legal proceedings, knowing terms like “amicus curiae” or “habeas corpus” can drastically speed up your workflow.
                    </p>
                </div>

                {/* Time Management */}
                <div className="section-block">
                    <h2>4. Time Management</h2>
                    <p>
                        In transcription, speed is crucial—but it should never come at the cost of quality. Time management ensures you can handle large workloads, meet tight deadlines, and still produce high-quality transcriptions.
                    </p>
                    <p><strong>Meeting Deadlines:</strong> Clients depend on you to deliver accurate transcripts on time. It’s essential to learn how to estimate how long a transcription will take and plan your day accordingly.
                        Consider using time-tracking tools like Toggl to monitor your pace and improve your ability to hit deadlines consistently.
                    </p>
                    <p><strong>Tip:</strong> If you’re working on a large project, break it down into smaller tasks and set mini-deadlines. Aim to complete a portion of the work ahead of time to allow for final proofreading.
                    </p>
                    <p><strong>Balancing Quality and Speed:</strong> While it’s tempting to focus on speed, you must remember that accuracy is paramount. Rushing through a transcription can lead to costly mistakes.
                        Prioritize delivering a clean, accurate transcript over speeding through the process.
                    </p>
                    <p><strong>Tip:</strong> Speed will come naturally as you gain experience. Focus on accuracy first, and your speed will improve over time.
                    </p>
                </div>

                {/* Attention to Detail */}
                <div className="section-block">
                    <h2>5. Attention to Detail</h2>
                    <p>
                        Nuance is everything in transcription. A skilled transcriptionist knows that small details—intonation, emphasis, or even pauses—can drastically change the meaning of the text.
                    </p>
                    <p><strong>Catching Nuances in Speech:</strong> Whether it's a speaker pausing for dramatic effect or emphasizing a specific word, these nuances often carry deeper meaning.
                        A skilled transcriptionist will note these subtleties and represent them in the transcript where appropriate.
                    </p>
                    <p><strong>Example:</strong> If a speaker says, “I didn’t mean it that way,” the emphasis on “mean” might indicate a specific tone or intention that must be reflected in the text.
                    </p>
                    <p><strong>Maintaining Consistency:</strong> Consistency is key, especially when transcribing large volumes of text. Make sure names, terminologies, and formatting remain uniform throughout the transcript.
                        Maintaining consistency gives your work a professional edge and ensures it’s easy to read and follow.
                    </p>
                    <p><strong>Tip:</strong> Create a style guide or template that you follow for every transcription project to ensure uniformity in formatting and style.
                    </p>
                </div>

                {/* Research Skills */}
                <div className="section-block">
                    <h2>6. Research Skills</h2>
                    <p>
                        Being a transcriptionist isn’t just about listening and typing—you need to be a detective sometimes, especially when faced with unfamiliar terms or concepts.
                    </p>
                    <p><strong>Fact-Checking and Verification:</strong> Accurate transcription often involves looking up proper names, technical terms, or company names to ensure you get them right.
                        Fact-checking adds a layer of professionalism to your work. Always verify spellings of names, acronyms, and specialized terminology by cross-referencing reliable sources like company websites,
                        government documents, or industry-specific glossaries.
                    </p>
                    <p><strong>Example:</strong> While transcribing a business meeting, you might hear an unfamiliar company name. It’s important to look it up to ensure it’s spelled correctly, especially if the transcription will be shared with clients or stakeholders.
                    </p>
                    <p><strong>Handling Unfamiliar Terms or Concepts:</strong> Don’t hesitate to pause and research unfamiliar words or phrases. When transcribing for niche industries like medical or legal,
                        you’ll frequently encounter jargon or industry-specific terms. Understanding these terms enhances the accuracy and value of your transcription.
                    </p>
                    <p><strong>Tip:</strong> Keep a list of commonly used industry terms and their meanings handy as a quick reference guide.
                    </p>
                </div>

                {/* Conclusion */}
                <div className="section-block">
                    <h2>Conclusion: The Heart of a Transcriptionist’s Work</h2>
                    <p>
                        Each of these skills works together to shape you into a transcriptionist who produces not just text, but accurate, polished, and professional transcripts.
                        By honing these abilities, you’ll not only meet the expectations of your clients—you’ll exceed them. Every transcript you create has the potential to serve as a record,
                        a legal document, or an important piece of media, so treat each task with care, diligence, and pride.
                    </p>
                    <p>
                        Remember, the best transcriptionists are the ones who combine their technical skills with passion for the craft. Whether you’re perfecting your listening abilities, improving your typing speed,
                        or deepening your understanding of language, every step brings you closer to becoming a standout professional in the field.
                    </p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default EssentialSkills;
