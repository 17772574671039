import React, { useState } from 'react';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import { API_BASE_URL } from '../config';
import { Modal, Button } from 'react-bootstrap';
import { Eye, EyeOff } from 'lucide-react';
import { ClipLoader } from 'react-spinners';
import 'bootstrap/dist/css/bootstrap.min.css';

const Login = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const idToken = await user.getIdToken();
      const avatarUrl = user.photoURL;
      const response = await fetch(`${API_BASE_URL}/api/users/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idToken }),
      });
      if (response.ok) {
        const data = await response.json();
        sessionStorage.setItem('userToken', idToken);
        const learningStatusResponse = await fetch(`${API_BASE_URL}/api/training/status`, {
          method: 'GET',
          headers: { 'Authorization': `Bearer ${idToken}` },
        });
        if (learningStatusResponse.ok) {
          const learningStatus = await learningStatusResponse.json();
          // Stringify the learningStatus before storing it
          sessionStorage.setItem('learningStatus', JSON.stringify(learningStatus));
          setIsSuccessModalOpen(true);
          setTimeout(() => {
            setIsSuccessModalOpen(false);
            // Pass the learningStatus object directly
            onLoginSuccess(idToken, learningStatus, data.user.role, avatarUrl);
          }, 2000);
        } else {
          setMessage('Failed to fetch learning status');
        }
      } else {
        const errorData = await response.json();
        setMessage(errorData.error || 'Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage(`An email has been sent to ${resetEmail}. Please check your email to change your password.`);
    } catch (error) {
      console.error('Password reset error:', error);
      setResetMessage('An error occurred. Please try again.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const modalStyle = {
    content: {
      backgroundColor: '#1F2937',
      color: '#F3F4F6',
      border: '1px solid #374151',
    },
    header: {
      borderBottom: '1px solid #374151',
    },
    title: {
      color: '#10B981',
    },
    closeButton: {
      color: '#F3F4F6',
      opacity: 0.7,
    },
  };

  return (
    <div className="bg-gray-900 min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full px-6 py-8 bg-gray-800 rounded-lg shadow-md">
        <h1 className="text-3xl font-bold mb-6 text-teal-400 text-center">Login</h1>
        <form onSubmit={handleLogin} className="space-y-5">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-3 border border-gray-700 rounded bg-gray-700 text-white placeholder-gray-400"
            required
          />
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 border border-gray-700 rounded bg-gray-700 text-white placeholder-gray-400"
              required
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white focus:outline-none"
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
          <button
            type="submit"
            className="w-full p-3 bg-purple-600 text-white rounded hover:bg-purple-700 transition duration-300 flex items-center justify-center"
            disabled={isLoading}
          >
            {isLoading ? (
              <ClipLoader color="#ffffff" size={20} />
            ) : (
              'Login'
            )}
          </button>
        </form>
        {message && <p className="mt-5 text-center text-red-400">{message}</p>}
        <p className="mt-4 text-center">
          <button
            onClick={() => setIsResetModalOpen(true)}
            className="text-teal-400 hover:underline"
          >
            Forgot Password?
          </button>
        </p>
      </div>

      <Modal show={isResetModalOpen} onHide={() => setIsResetModalOpen(false)} contentClassName="bg-gray-800">
        <Modal.Header closeButton style={modalStyle.header}>
          <Modal.Title style={modalStyle.title}>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalStyle.content}>
          <p>Enter your email address to receive a password reset link.</p>
          <form onSubmit={handleForgotPassword}>
            <input
              type="email"
              placeholder="Email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              className="w-full p-3 border border-gray-700 rounded bg-gray-700 text-white placeholder-gray-400 mt-3"
              required
            />
            <Button type="submit" variant="outline-light" className="mt-3 bg-purple-600 hover:bg-purple-700 border-0">
              Send Reset Link
            </Button>
          </form>
          {resetMessage && <p className="mt-4 text-center text-teal-400">{resetMessage}</p>}
        </Modal.Body>
      </Modal>

      <Modal 
        show={isSuccessModalOpen} 
        onHide={() => setIsSuccessModalOpen(false)} 
        contentClassName="bg-gray-800"
        centered
      >
        <Modal.Header closeButton style={modalStyle.header}>
          <Modal.Title style={modalStyle.title}>Login Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body style={modalStyle.content}>
          <div className="text-center">
            <div className="tick-animation">✓</div>
            <p className="mt-4">You have successfully logged in.</p>
          </div>
        </Modal.Body>
      </Modal>

      <style jsx global>{`
        .modal-content {
          background-color: #1F2937 !important;
          color: #F3F4F6 !important;
        }
        .modal-header {
          border-bottom: 1px solid #374151 !important;
        }
        .modal-header .close {
          color: #F3F4F6 !important;
          opacity: 0.7;
        }
        .modal-dialog-centered {
          display: flex;
          align-items: center;
          min-height: calc(100% - 1rem);
        }
        .tick-animation {
          font-size: 48px;
          color: #10B981;
          animation: scale-in 0.5s ease-out;
        }
        @keyframes scale-in {
          0% { transform: scale(0); }
          100% { transform: scale(1); }
        }
      `}</style>
    </div>
  );
};

export default Login;