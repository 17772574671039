// src/pages/DesignPatterns.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';
import './DesignPatterns.css';


const DesignPatterns = () => {
    const singletonEagerCode = `
public class Singleton {
    private static final Singleton instance = new Singleton();

    private Singleton() {
        // Private constructor to prevent instantiation
    }

    public static Singleton getInstance() {
        return instance;
    }
}
`;

    const singletonLazyCode = `
public class Singleton {
    private static Singleton instance;

    private Singleton() {
        // Private constructor to prevent instantiation
    }

    public static Singleton getInstance() {
        if (instance == null) {
            instance = new Singleton();
        }
        return instance;
    }
}
`;

    const singletonThreadSafeCode = `
public class Singleton {
    private static Singleton instance;

    private Singleton() {
        // Private constructor to prevent instantiation
    }

    public static synchronized Singleton getInstance() {
        if (instance == null) {
            instance = new Singleton();
        }
        return instance;
    }
}
`;

    const factoryPatternCode = `
interface Shape {
    void draw();
}

class Circle implements Shape {
    @Override
    public void draw() {
        System.out.println("Drawing a Circle");
    }
}

class Rectangle implements Shape {
    @Override
    public void draw() {
        System.out.println("Drawing a Rectangle");
    }
}

class ShapeFactory {
    public Shape getShape(String shapeType) {
        if (shapeType == null) {
            return null;
        }
        if (shapeType.equalsIgnoreCase("CIRCLE")) {
            return new Circle();
        } else if (shapeType.equalsIgnoreCase("RECTANGLE")) {
            return new Rectangle();
        }
        return null;
    }
}

public class FactoryPatternExample {
    public static void main(String[] args) {
        ShapeFactory shapeFactory = new ShapeFactory();

        Shape shape1 = shapeFactory.getShape("CIRCLE");
        shape1.draw(); // Output: Drawing a Circle

        Shape shape2 = shapeFactory.getShape("RECTANGLE");
        shape2.draw(); // Output: Drawing a Rectangle
    }
}
`;

    const observerPatternCode = `
import java.util.ArrayList;
import java.util.List;

interface Observer {
    void update(String message);
}

class ConcreteObserver implements Observer {
    private String name;

    public ConcreteObserver(String name) {
        this.name = name;
    }

    @Override
    public void update(String message) {
        System.out.println(name + " received message: " + message);
    }
}

interface Subject {
    void attach(Observer observer);
    void detach(Observer observer);
    void notifyObservers();
}

class ConcreteSubject implements Subject {
    private List<Observer> observers = new ArrayList<>();
    private String message;

    @Override
    public void attach(Observer observer) {
        observers.add(observer);
    }

    @Override
    public void detach(Observer observer) {
        observers.remove(observer);
    }

    @Override
    public void notifyObservers() {
        for (Observer observer : observers) {
            observer.update(message);
        }
    }

    public void setMessage(String message) {
        this.message = message;
        notifyObservers();
    }
}

public class ObserverPatternExample {
    public static void main(String[] args) {
        ConcreteSubject subject = new ConcreteSubject();

        Observer observer1 = new ConcreteObserver("Observer 1");
        Observer observer2 = new ConcreteObserver("Observer 2");

        subject.attach(observer1);
        subject.attach(observer2);

        subject.setMessage("Hello, Observers!"); // Output: Observer 1 received message: Hello, Observers!
                                                //         Observer 2 received message: Hello, Observers!
    }
}
`;

    const strategyPatternCode = `
interface PaymentStrategy {
    void pay(int amount);
}

class CreditCardPayment implements PaymentStrategy {
    @Override
    public void pay(int amount) {
        System.out.println("Paid " + amount + " using Credit Card");
    }
}

class PayPalPayment implements PaymentStrategy {
    @Override
    public void pay(int amount) {
        System.out.println("Paid " + amount + " using PayPal");
    }
}

class ShoppingCart {
    private PaymentStrategy paymentStrategy;

    public void setPaymentStrategy(PaymentStrategy paymentStrategy) {
        this.paymentStrategy = paymentStrategy;
    }

    public void checkout(int amount) {
        paymentStrategy.pay(amount);
    }
}

public class StrategyPatternExample {
    public static void main(String[] args) {
        ShoppingCart cart = new ShoppingCart();

        cart.setPaymentStrategy(new CreditCardPayment());
        cart.checkout(100); // Output: Paid 100 using Credit Card

        cart.setPaymentStrategy(new PayPalPayment());
        cart.checkout(200); // Output: Paid 200 using PayPal
    }
}
`;

    const loggerSingletonCode = `
public class Logger {
    private static Logger instance;

    private Logger() {
        // Private constructor
    }

    public static synchronized Logger getInstance() {
        if (instance == null) {
            instance = new Logger();
        }
        return instance;
    }

    public void log(String message) {
        System.out.println("Log: " + message);
    }
}

public class LoggerTest {
    public static void main(String[] args) {
        Logger logger = Logger.getInstance();
        logger.log("This is a log message.");
    }
}
`;

    const guiFactoryPatternCode = `
interface Button {
    void render();
}

class WindowsButton implements Button {
    @Override
    public void render() {
        System.out.println("Rendering a Windows button");
    }
}

class MacOSButton implements Button {
    @Override
    public void render() {
        System.out.println("Rendering a MacOS button");
    }
}

class ButtonFactory {
    public Button createButton(String osType) {
        if (osType.equals("Windows")) {
            return new WindowsButton();
        } else if (osType.equals("MacOS")) {
            return new MacOSButton();
        }
        return null;
    }
}

public class FactoryTest {
    public static void main(String[] args) {
        ButtonFactory factory = new ButtonFactory();

        Button button = factory.createButton("Windows");
        button.render(); // Output: Rendering a Windows button
    }
}
`;

    const notificationObserverPatternCode = `
import java.util.ArrayList;
import java.util.List;

interface NotificationObserver {
    void update(String notification);
}

class EmailNotifier implements NotificationObserver {
    @Override
    public void update(String notification) {
        System.out.println("Email Notification: " + notification);
    }
}

class SMSNotifier implements NotificationObserver {
    @Override
    public void update(String notification) {
        System.out.println("SMS Notification: " + notification);
    }
}

class NotificationService {
    private List<NotificationObserver> observers = new ArrayList<>();

    public void addObserver(NotificationObserver observer) {
        observers.add(observer);
    }

    public void notifyObservers(String notification) {
        for (NotificationObserver observer : observers) {
            observer.update(notification);
        }
    }
}

public class ObserverTest {
    public static void main(String[] args) {
        NotificationService service = new NotificationService();

        service.addObserver(new EmailNotifier());
        service.addObserver(new SMSNotifier());

        service.notifyObservers("System update available."); // Output: Email Notification: System update available.
                                                             //         SMS Notification: System update available.
    }
}
`;

    const paymentStrategyPatternCode = `
interface PaymentMethod {
    void pay(int amount);
}

class CreditCardPayment implements PaymentMethod {
    @Override
    public void pay(int amount) {
        System.out.println("Paid " + amount + " using Credit Card.");
    }
}

class PayPalPayment implements PaymentMethod {
    @Override
    public void pay(int amount) {
        System.out.println("Paid " + amount + " using PayPal.");
    }
}

class PaymentProcessor {
    private PaymentMethod paymentMethod;

    public void setPaymentMethod(PaymentMethod paymentMethod) {
        this.paymentMethod = paymentMethod;
    }

    public void processPayment(int amount) {
        paymentMethod.pay(amount);
    }
}

public class StrategyTest {
    public static void main(String[] args) {
        PaymentProcessor processor = new PaymentProcessor();

        processor.setPaymentMethod(new CreditCardPayment());
        processor.processPayment(150); // Output: Paid 150 using Credit Card.

        processor.setPaymentMethod(new PayPalPayment());
        processor.processPayment(200); // Output: Paid 200 using PayPal.
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Design Patterns in Java: A Comprehensive Guide</h1>
                <p>
                    Design patterns are tried and tested solutions to common software design problems. They represent best practices used by experienced developers to solve recurring problems in software design in a consistent and efficient manner. Understanding and implementing design patterns in Java can significantly enhance your software design skills, making your code more modular, maintainable, and scalable. This guide will cover four fundamental design patterns—Singleton, Factory, Observer, and Strategy—along with best practices and their application in real-world scenarios.
                </p>

                <hr className="section-divider" />

                <h2>1. Singleton Pattern</h2>

                <h3>1.1. Overview</h3>
                <p>
                    The Singleton pattern ensures that a class has only one instance and provides a global point of access to that instance. It is commonly used in situations where exactly one object is needed to coordinate actions across the system.
                </p>

                <h3>1.2. Implementation</h3>

                <h4>Eager Initialization:</h4>
                <CodeBlock code={singletonEagerCode} />

                <h4>Explanation:</h4>
                <p><strong>Eager Initialization:</strong> The instance is created at the time of class loading, which is useful when the Singleton instance is always required.</p>

                <h4>Lazy Initialization:</h4>
                <CodeBlock code={singletonLazyCode} />

                <h4>Explanation:</h4>
                <p><strong>Lazy Initialization:</strong> The instance is created only when it is needed. This approach is useful when the Singleton instance is resource-intensive and might not always be needed.</p>

                <h4>Thread-Safe Singleton:</h4>
                <CodeBlock code={singletonThreadSafeCode} />

                <h4>Explanation:</h4>
                <p><strong>Thread Safety:</strong> By using the synchronized keyword, this approach ensures that the Singleton instance is created only once, even in a multithreaded environment.</p>

                <h3>1.3. Real-World Application</h3>
                <p>The Singleton pattern is commonly used in logging, where a single logging instance is shared across an application. It’s also used in configuration management where a single configuration object needs to be shared across different parts of an application.</p>

                <hr className="section-divider" />

                <h2>2. Factory Pattern</h2>

                <h3>2.1. Overview</h3>
                <p>
                    The Factory pattern provides an interface for creating objects in a super class, but allows subclasses to alter the type of objects that will be created. This pattern is used when the exact type of the object cannot be determined until runtime.
                </p>

                <h3>2.2. Implementation</h3>
                <CodeBlock code={factoryPatternCode} />

                <h4>Explanation:</h4>
                <p><strong>Factory Method:</strong> The <code>ShapeFactory</code> class has a method <code>getShape()</code> that returns an object of the <code>Shape</code> type. This allows the creation of different Shape objects based on the input provided.</p>

                <h3>2.3. Real-World Application</h3>
                <p>The Factory pattern is widely used in frameworks like Spring for bean creation, in database management systems for creating database connections, and in GUI toolkits for creating different types of UI components like buttons, windows, and panels.</p>

                <hr className="section-divider" />

                <h2>3. Observer Pattern</h2>

                <h3>3.1. Overview</h3>
                <p>
                    The Observer pattern defines a one-to-many dependency between objects, so that when one object changes state, all its dependents are notified and updated automatically. This pattern is typically used in event-driven systems.
                </p>

                <h3>3.2. Implementation</h3>
                <CodeBlock code={observerPatternCode} />

                <h4>Explanation:</h4>
                <p><strong>Subject and Observer:</strong> The <code>ConcreteSubject</code> maintains a list of observers and notifies them when its state changes. The observers react by executing their <code>update()</code> methods.</p>

                <h3>3.3. Real-World Application</h3>
                <p>The Observer pattern is commonly used in GUI frameworks where UI elements need to react to changes in state, such as a button click or a text field update. It’s also used in event handling systems, such as in a pub-sub model where different components of an application need to respond to events.</p>

                <hr className="section-divider" />

                <h2>4. Strategy Pattern</h2>

                <h3>4.1. Overview</h3>
                <p>
                    The Strategy pattern defines a family of algorithms, encapsulates each one, and makes them interchangeable. This pattern lets the algorithm vary independently from the clients that use it.
                </p>

                <h3>4.2. Implementation</h3>
                <CodeBlock code={strategyPatternCode} />

                <h4>Explanation:</h4>
                <p><strong>Strategy Interface:</strong> The <code>PaymentStrategy</code> interface defines a method <code>pay()</code> that is implemented by different payment strategies. The <code>ShoppingCart</code> class uses a specific strategy based on what the client chooses.</p>

                <h3>4.3. Real-World Application</h3>
                <p>The Strategy pattern is useful in scenarios where an application needs to switch between different algorithms or behaviors at runtime, such as different sorting algorithms or different payment methods in an e-commerce application.</p>

                <hr className="section-divider" />

                <h2>5. Best Practices in Design Patterns</h2>

                <h3>5.1. Understand the Problem Domain</h3>
                <p>Before choosing a design pattern, it’s important to thoroughly understand the problem domain. Design patterns should be applied based on the specific needs and challenges of the project rather than simply because they are available.</p>

                <h3>5.2. Keep it Simple</h3>
                <p>Avoid overcomplicating the design by applying multiple patterns unnecessarily. The goal is to simplify the design and make it more maintainable, not more complex.</p>

                <h3>5.3. Favor Composition Over Inheritance</h3>
                <p>Whenever possible, favor composition over inheritance. This is a key principle in design patterns that promotes flexibility by allowing behavior to be changed or extended without modifying existing code.</p>

                <h3>5.4. Encapsulate Varying Code</h3>
                <p>Design patterns should be used to encapsulate code that is likely to change. By isolating variability, you can reduce the impact of changes on other parts of the application.</p>

                <h3>5.5. Apply Patterns Incrementally</h3>
                <p>It’s often better to apply design patterns incrementally as the need arises during development rather than trying to apply them all upfront. This ensures that the patterns are a natural fit for the problem rather than a forced solution.</p>

                <hr className="section-divider" />

                <h2>6. Using Design Patterns in Real-World Scenarios</h2>

                <h3>6.1. Scenario 1: A Logging Framework Using Singleton</h3>
                <CodeBlock code={loggerSingletonCode} />

                <h4>Explanation:</h4>
                <p><strong>Singleton Logger:</strong> Ensures that all log messages are managed by a single logger instance, preventing inconsistencies in log outputs.</p>

                <h3>6.2. Scenario 2: A GUI Toolkit Using Factory Pattern</h3>
                <CodeBlock code={guiFactoryPatternCode} />

                <h4>Explanation:</h4>
                <p><strong>Factory for Buttons:</strong> The <code>ButtonFactory</code> creates different button objects based on the operating system type. This allows the client code to request a button without worrying about the underlying implementation.</p>

                <h3>6.3. Scenario 3: A Notification System Using Observer Pattern</h3>
                <CodeBlock code={notificationObserverPatternCode} />

                <h4>Explanation:</h4>
                <p><strong>Observer for Notifications:</strong> Multiple observers (email and SMS notifiers) are notified when a new event (system update) occurs. This allows the system to easily expand by adding more observers without modifying the core notification logic.</p>

                <h3>6.4. Scenario 4: A Payment System Using Strategy Pattern</h3>
                <CodeBlock code={paymentStrategyPatternCode} />

                <h4>Explanation:</h4>
                <p><strong>Strategy for Payment Methods:</strong> The payment method can be easily switched at runtime, allowing the payment processor to handle different payment methods without changing its core logic.</p>

                <hr className="section-divider" />

                <h2>Conclusion</h2>
                <p>
                    Design patterns are powerful tools in a developer's toolkit, enabling the creation of flexible, scalable, and maintainable software. By mastering patterns like Singleton, Factory, Observer, and Strategy, you can address common design challenges more effectively and implement solutions that are both robust and reusable.
                </p>
                <p>
                    Best practices in applying design patterns include understanding the problem domain, keeping the design simple, favoring composition over inheritance, and encapsulating varying code. These principles help ensure that patterns are applied in a way that enhances the design rather than complicating it.
                </p>
                <p>
                    Real-world scenarios, such as logging frameworks, GUI toolkits, notification systems, and payment processing systems, demonstrate the practical application of these patterns, showing how they can solve specific design challenges in a variety of contexts.
                </p>
                <p>
                    By incorporating design patterns into your development process, you can create software that is easier to understand, extend, and maintain, ultimately leading to higher quality and more successful projects.
                </p>

                <NextButton nextPage="/java-networking" />
            </div>
        </div>
    );
};

export default DesignPatterns;
