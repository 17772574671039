import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { API_BASE_URL } from '../config';

const EditTask = ({ taskId, onClose, onTaskUpdated }) => {
  const [task, setTask] = useState(null);

  const fetchTask = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/tasks/${taskId}`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        }
      });
      const data = await response.json();
      setTask(data.task);
    } catch (error) {
      console.error('Error fetching task:', error);
    }
  }, [taskId]);

  useEffect(() => {
    fetchTask();
  }, [fetchTask]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTask({ ...task, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/edit-task/${taskId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        },
        body: JSON.stringify(task)
      });
      if (response.ok) {
        onTaskUpdated();
        onClose();
      }
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  if (!task) return <Typography>Loading...</Typography>;

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>Edit Task</Typography>
      <TextField
        fullWidth
        label="Title"
        name="title"
        value={task.title}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Description"
        name="description"
        value={task.description}
        onChange={handleInputChange}
        multiline
        rows={4}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Price"
        name="price"
        type="number"
        value={task.price}
        onChange={handleInputChange}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        label="Deadline"
        name="deadline"
        type="date"
        value={task.deadline}
        onChange={handleInputChange}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Category</InputLabel>
        <Select
          name="category"
          value={task.category}
          onChange={handleInputChange}
        >
          <MenuItem value="writing">Writing</MenuItem>
          <MenuItem value="editing">Editing</MenuItem>
          <MenuItem value="proofreading">Proofreading</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          Update Task
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default EditTask;