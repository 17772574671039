import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function TimeManagementProductivity() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/career-building');
    };

    return (
        <div className="time-management-intro">
            <header className="header">
                <h1>Time Management and Productivity</h1>
                <p className="subtitle">Mastering the Balance Between Efficiency and Well-Being</p>
            </header>

            <div className="content">
                <div className="section-block">
                    <h2>1. Setting Realistic Goals</h2>
                    <h3>Estimating Project Time</h3>
                    <p>Accurately estimating project time is crucial for setting realistic goals. Always factor in time for breaks, editing, and reviewing your work to avoid underestimating the task.</p>
                    <p><strong>Example:</strong> If you have a 60-minute podcast to transcribe, estimate around four hours for completion to allow for challenges and review.</p>

                    <h3>Breaking Large Projects into Manageable Tasks</h3>
                    <p>Break large tasks into smaller, bite-sized stages to make them more approachable. This helps avoid feeling overwhelmed and ensures consistent progress.</p>
                    <p><strong>Example:</strong> Split a long transcription project into 20-minute sections to transcribe and edit over a few days, maintaining quality and avoiding fatigue.</p>
                </div>

                <div className="section-block">
                    <h2>2. Productivity Techniques</h2>
                    <h3>Pomodoro Method</h3>
                    <p>The Pomodoro Method involves working for 25-minute intervals followed by 5-minute breaks. After completing four intervals, take a longer break to recharge.</p>
                    <p><strong>Example:</strong> Set a timer for 25 minutes, focus on your transcription, then take a 5-minute break. Repeat this cycle to maintain productivity without burning out.</p>

                    <h3>Time Blocking</h3>
                    <p>Time blocking involves dedicating specific blocks of time to each task. This method ensures each task receives your full attention during its designated time.</p>
                    <p><strong>Example:</strong> Reserve your morning from 9:00 AM to 12:00 PM for transcription work and the afternoon for emails and administrative tasks, ensuring balanced focus on each task.</p>
                </div>

                <div className="section-block">
                    <h2>3. Avoiding Burnout</h2>
                    <h3>Ergonomics and Health Considerations</h3>
                    <p>Setting up an ergonomic workspace helps prevent strain and injury, especially when working long hours at a desk. Proper ergonomics enhance comfort and productivity.</p>
                    <p><strong>Tips for Ergonomics:</strong> Adjust your chair for back support, keep your computer screen at eye level, and follow the 20-20-20 rule for eye health.</p>

                    <h3>Balancing Work and Rest</h3>
                    <p>To avoid burnout, balance your workload with ample rest. Prioritize sleep, take regular breaks, and schedule downtime to maintain mental and physical well-being.</p>
                    <p><strong>Example:</strong> Set a hard stop time at 6:00 PM to ensure you have time to relax and recharge, preventing overwork and maintaining long-term productivity.</p>
                </div>

                <div className="section-block">
                    <h2>Conclusion: Elevating Your Time Management and Productivity</h2>
                    <p>Effective time management and productivity techniques are essential for delivering high-quality work while maintaining balance. By setting realistic goals, adopting methods like the Pomodoro Method and Time Blocking, and focusing on ergonomics and self-care, you can excel in your transcription tasks while avoiding burnout.</p>
                    <p>Remember, productivity is about working smarter, not harder. Master these techniques to enhance your efficiency and enjoy a more balanced, fulfilling work life.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default TimeManagementProductivity;
