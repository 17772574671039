import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <>
            <div className={`sidebar-toggle-button ${isSidebarOpen ? 'open' : ''}`} onClick={toggleSidebar}>
                ☰
            </div>
            <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <div className="sidebar-header">
                    <img src="/fitfreelance-logo.jpeg" alt="Website Logo" className="sidebar-logo" />
                </div>
                <ul>
                    <li>
                        <Link to="/java-learning/introduction">Introduction to Programming Concepts</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/oop-basics">Object-Oriented Programming (OOP) Basics</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/arrays-collections">Arrays and Collections</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/exception-handling">Exception Handling</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/basic-io">Basic I/O Operations</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/java-apis">Introduction to Java APIs</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/advanced-oop">Advanced OOP Concepts</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/advanced-data-structures">Advanced Data Structures</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/multithreading">Multithreading</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/java-io-serialization">Java I/O and Serialization</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/exception-handling-advanced">Exception Handling (Advanced)</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/java-memory-management">Java Memory Management</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/design-patterns">Design Patterns</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/java-networking">Java Networking</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/advanced-multithreading">Advanced Multithreading and Concurrency</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/java-8-features">Java 8+ Features</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/unit-testing-tdd">Unit Testing and TDD</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/building-java-applications">Building Java Applications</Link>
                    </li>
                    <li>
                        <Link to="/java-learning/integration-deployment">Integration and Deployment</Link>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Sidebar;
