/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider, updateProfile, deleteUser, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { ClipLoader } from 'react-spinners';
import { API_BASE_URL } from '../config';
import { Badge } from 'lucide-react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const UserProfile = ({ userToken, learningStatus }) => {
  const [user, setUser] = useState(null);
  const [biddedTasks, setBiddedTasks] = useState(() => JSON.parse(sessionStorage.getItem('biddedTasks')) || { tasks: [], count: 0 });
  const [acceptedTasks, setAcceptedTasks] = useState(() => JSON.parse(sessionStorage.getItem('acceptedTasks')) || { tasks: [], count: 0 });
  const [completedTasks, setCompletedTasks] = useState(() => JSON.parse(sessionStorage.getItem('completedTasks')) || { tasks: [], count: 0 });
  const [revisionTasks, setRevisionTasks] = useState(() => JSON.parse(sessionStorage.getItem('revisionTasks')) || { tasks: [], count: 0 });
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');
  const [deleteError, setDeleteError] = useState('');
  const [totalAmount, setTotalAmount] = useState(() => JSON.parse(sessionStorage.getItem('totalAmount')) || 0);

  const fetchUserData = useCallback(async (firebaseUser) => {
    if (!userToken) {
      setMessage('No user token available.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/users/profile`, {
        headers: { 'Authorization': `Bearer ${userToken}` },
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const userData = await response.json();
      setUser({
        fullName: userData.fullName || 'N/A',
        email: userData.email,
        role: userData.role || 'User',
      });
      
      // Use Firebase user's photoURL for avatar
      setAvatarUrl(firebaseUser.photoURL || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);
    }
  }, [userToken]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        fetchUserData(firebaseUser);
      } else {
        setLoading(false);
        setMessage('No user is currently signed in.');
      }
    });

    return () => unsubscribe();
  }, [fetchUserData]);

  const fetchTasks = useCallback(async (url, setState, sessionStorageKey) => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken();
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const data = await response.json();
        setState({
          tasks: data,
          count: data.length
        });
        // Save tasks to sessionStorage
        sessionStorage.setItem(sessionStorageKey, JSON.stringify({ tasks: data, count: data.length }));
      }
    } catch (error) {
      console.error(`Error fetching tasks from ${url}:`, error);
    }
  }, []);

  const fetchTotalAmount = useCallback(async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const token = await user.getIdToken();
        const response = await fetch(`${API_BASE_URL}/api/users/transactions`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const data = await response.json();
        setTotalAmount(data.totalAmount || 0);
        // Save total amount to sessionStorage
        sessionStorage.setItem('totalAmount', JSON.stringify(data.totalAmount || 0));
      }
    } catch (error) {
      console.error('Error fetching total amount:', error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchTasks(`${API_BASE_URL}/api/users/bids`, setBiddedTasks, 'biddedTasks');
      await fetchTasks(`${API_BASE_URL}/api/users/accepted-tasks`, setAcceptedTasks, 'acceptedTasks');
      await fetchTasks(`${API_BASE_URL}/api/users/completed-tasks`, setCompletedTasks, 'completedTasks');
      await fetchTasks(`${API_BASE_URL}/api/users/revision-tasks`, setRevisionTasks, 'revisionTasks');
      await fetchTotalAmount(); 
    };
    fetchData();
  }, [fetchTasks, fetchTotalAmount]); 

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      setMessage('No user is currently signed in.');
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setMessage('Password updated successfully');
      setCurrentPassword('');
      setNewPassword('');
    } catch (error) {
      console.error('Error updating password:', error);
      if (error.code === 'auth/wrong-password') {
        setMessage('Current password is incorrect');
      } else {
        setMessage('Failed to update password: ' + error.message);
      }
    }
  };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    setDeleteError('');

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      setDeleteError('No user is currently signed in.');
      return;
    }

    try {
      // Re-authenticate user
      const credential = EmailAuthProvider.credential(user.email, deletePassword);
      await reauthenticateWithCredential(user, credential);

      // Delete user from Firebase
      await deleteUser(user);

      // Delete user from backend
      const response = await fetch(`${API_BASE_URL}/api/users/delete`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${userToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete user from the database');
      }

      // Redirect to home page or sign-in page after successful deletion
      sessionStorage.removeItem('userToken');
      window.location.href = '/';
    } catch (error) {
      console.error('Error deleting account:', error);
      setDeleteError(error.message);
    }
  };

  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const auth = getAuth();
    const storage = getStorage();
    const user = auth.currentUser;

    if (!user) {
      setMessage('No user is currently signed in.');
      return;
    }

    try {
      const storageRef = ref(storage, `avatars/${user.uid}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      await updateProfile(user, { photoURL: downloadURL });
      setAvatarUrl(downloadURL);
      setMessage('Avatar updated successfully');
    } catch (error) {
      console.error('Error uploading avatar:', error);
      setMessage('Failed to upload avatar: ' + error.message);
    }
  };

  const renderTaskCard = (title, { count }, linkPrefix) => (
    <Link to={`/${linkPrefix}`} className="block">
      <div className="bg-gray-800 p-4 rounded hover:bg-gray-700 transition-colors duration-300">
        <h4 className="text-xl font-semibold mb-2 text-teal-400">{title}</h4>
        <p className="text-3xl font-bold text-purple-300 mb-2">{count || 0}</p>
      </div>
    </Link>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-900">
        <ClipLoader color="#38bdf8" loading={loading} size={150} />
      </div>
    );
  }

  if (!user) {
    return <div className="text-gray-100">No user data available. Please try logging in again.</div>;
  }

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <div className="container mx-auto px-4 py-12">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Alert severity="info" className="mb-8">
            <AlertTitle>Email Notification</AlertTitle>
              Please check your email regularly for official communications from admin@fitfreelance.co.ke or support issues at support@fitfreelance.co.ke.
          </Alert>
        </motion.div>

        <motion.div 
          className="flex flex-col md:flex-row justify-between items-center mb-8"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2 className="text-3xl md:text-4xl font-bold text-purple-300 text-center md:text-left">{user.fullName}</h2>
        </motion.div>
        
        <div className="flex flex-col md:flex-row">
          <motion.div 
            className="w-full md:w-1/3 md:pr-8 mb-8 md:mb-0"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <div className="mb-8">
              <h3 className="text-2xl font-semibold mb-4 text-teal-400 text-center">Profile Overview</h3>
              <div className="flex flex-col items-center mb-4">
                <div className="w-32 h-32 rounded-full overflow-hidden mb-2">
                  <img 
                    src={avatarUrl || 'https://via.placeholder.com/150'} 
                    alt="Profile" 
                    className="w-full h-full object-cover"
                  />
                </div>
                <label className="cursor-pointer bg-teal-500 text-gray-900 font-bold py-2 px-4 rounded hover:bg-teal-400 transition duration-300">
                  Upload Avatar
                  <input 
                    type="file" 
                    className="hidden" 
                    onChange={handleAvatarUpload}
                    accept="image/*"
                  />
                </label>
              </div>
              <p className="text-gray-300 text-center"><strong>Local time:</strong> {new Date().toLocaleTimeString()}</p>
              <p className="text-gray-300 text-center"><strong>Role:</strong> {user.role}</p>
              <p className="text-gray-300 text-center">
                <strong>Verification Status: </strong>
                {learningStatus.completedLearning ? (
                  <span className="text-green-400 flex items-center justify-center">
                    Verified <Badge className="ml-1" size={16} />
                  </span>
                ) : (
                  <span className="text-red-400">Not Verified. Please complete the learning path to become verified.</span>
                )}
              </p>
              <div className="mt-4 text-center">
              <h4 className="text-xl font-semibold mb-2 text-teal-400">Total Earnings</h4>
              <p className="text-2xl font-bold text-purple-300">Ksh {totalAmount.toFixed(2)}</p>
            </div>
            </div>
          </motion.div>

          <motion.div 
            className="w-full md:w-2/3"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <div className="mb-8">
              <h3 className="text-2xl font-semibold mb-4 text-purple-300">Tasks Dashboard</h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {renderTaskCard("Bidded Tasks", biddedTasks, "bids")}
                {renderTaskCard("Accepted Tasks", acceptedTasks, "accepted-tasks")}
                {renderTaskCard("Completed Tasks", completedTasks, "completed-tasks")}
                {renderTaskCard("Tasks on Revision", revisionTasks, "revision-tasks")}
              </div>
            </div>

            <motion.div 
                className="mt-8"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                <h3 className="text-2xl font-semibold mb-4 text-purple-300">Manage Finances</h3>
                <Link to="/transaction-wallet" className="block bg-teal-500 text-gray-900 font-bold py-2 px-4 rounded hover:bg-teal-400 transition duration-300 text-center">
                  View Transaction Wallet
                </Link>
            </motion.div>

            <motion.div 
              className="mt-8"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <h3 className="text-2xl font-semibold mb-4 text-purple-300">Change Password</h3>
              <form onSubmit={handlePasswordChange} className="space-y-4">
                <input
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full p-2 border rounded bg-gray-800 text-gray-100 border-gray-600 focus:border-teal-500 focus:outline-none"
                />
                <input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-2 border rounded bg-gray-800 text-gray-100 border-gray-600 focus:border-teal-500 focus:outline-none"
                />
                <button type="submit" className="bg-teal-500 text-gray-900 font-bold py-2 px-4 rounded hover:bg-teal-400 transition duration-300">
                  Change Password
                </button>
              </form>
              {message && <p className="mt-4 text-center text-teal-400">{message}</p>}
            </motion.div>

            <motion.div 
          className="mt-8"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
            >
          <h3 className="text-2xl font-semibold mb-4 text-red-500">Delete Account</h3>
          <button 
            onClick={() => setShowDeleteConfirmation(true)}
            className="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-600 transition duration-300"
          >
            Delete My Account
          </button>
          
          {showDeleteConfirmation && (
            <div className="mt-4">
              <p className="text-red-400 mb-2">Are you sure you want to delete your account? This action cannot be undone.</p>
              <form onSubmit={handleDeleteAccount} className="space-y-4">
                <input
                  type="password"
                  placeholder="Enter your password to confirm"
                  value={deletePassword}
                  onChange={(e) => setDeletePassword(e.target.value)}
                  className="w-full p-2 border rounded bg-gray-800 text-gray-100 border-gray-600 focus:border-red-500 focus:outline-none"
                />
                <button type="submit" className="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-600 transition duration-300">
                  Confirm Delete
                </button>
                <button 
                  type="button" 
                  onClick={() => setShowDeleteConfirmation(false)}
                  className="ml-2 bg-gray-500 text-white font-bold py-2 px-4 rounded hover:bg-gray-600 transition duration-300"
                >
                  Cancel
                </button>
              </form>
              {deleteError && <p className="mt-2 text-red-400">{deleteError}</p>}
            </div>
          )}
          </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
