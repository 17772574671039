import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function QualityControlProofreading() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/time-management');
    };

    return (
        <div className="quality-control-intro">
            <header className="header">
                <h1>Quality Control and Proofreading</h1>
                <p className="subtitle">Perfecting Your Transcripts for Professional Results</p>
            </header>

            <div className="content">
                <div className="section-block">
                    <h2>Quality Control: The Backbone of Excellent Transcription</h2>
                    <p>Quality control is the backbone of excellent transcription work. Regardless of your speed or accuracy while transcribing, ensuring that the final product is error-free, polished, and consistent is essential. This phase transforms a good transcript into a professional one, and it’s the mark of a true expert in the field. Quality control involves self-editing techniques, peer reviews, and utilizing quality assurance tools. Each of these elements will help you refine your transcription and provide a flawless end product to your clients. Let’s dive deep into how you can make your proofreading process more efficient and reliable.</p>
                </div>

                <div className="section-block">
                    <h2>1. Self-Editing Techniques</h2>
                    <h3>Reading Aloud</h3>
                    <p>Reading your transcript aloud helps you catch awkward phrasing and errors that might go unnoticed while reading silently. This method ensures the transcript flows naturally and clearly for the reader.</p>
                    <p><strong>Example:</strong> You might catch an error like “The results was significant” and correct it to “The results were significant” when reading aloud.</p>

                    <h3>Taking Breaks Between Transcribing and Editing</h3>
                    <p>Taking breaks between transcribing and editing allows you to return to your work with fresh eyes, increasing your ability to spot errors and inconsistencies.</p>
                    <p><strong>Example:</strong> After a short break, you notice a redundant phrase in your transcription that you missed earlier.</p>
                </div>

                <div className="section-block">
                    <h2>2. Peer Review Process</h2>
                    <h3>Benefits of Having Others Review Your Work</h3>
                    <p>A peer review helps catch mistakes you may have overlooked. Fresh eyes can spot inconsistencies, grammatical errors, or awkward phrasing that can improve the transcript's overall quality.</p>
                    <p><strong>Example:</strong> A peer reviewer may notice incorrect use of “it’s” vs. “its,” allowing you to fix these errors before delivering the final transcript.</p>

                    <h3>How to Give and Receive Feedback</h3>
                    <p>When giving feedback, be constructive and specific, offering actionable suggestions. When receiving feedback, stay open-minded and use the input to improve your work.</p>
                    <p><strong>Example:</strong> Instead of saying “This section doesn’t make sense,” offer a suggestion: “Consider rephrasing this sentence for clarity...”</p>
                </div>

                <div className="section-block">
                    <h2>3. Using Quality Assurance Tools</h2>
                    <h3>Spell Checkers and Grammar Tools</h3>
                    <p>Tools like Grammarly or ProWritingAid can help you catch common spelling errors and grammatical issues. However, always follow up with a manual review to ensure context-specific accuracy.</p>
                    <p><strong>Example:</strong> A spell checker might flag “affect” when you meant “effect,” allowing for quick correction.</p>

                    <h3>Consistency Checkers</h3>
                    <p>Tools like PerfectIt ensure consistency in spelling, punctuation, and capitalization throughout your transcript, making it easier to maintain a professional and uniform style.</p>
                    <p><strong>Example:</strong> A consistency checker might flag both “email” and “e-mail” in your document, prompting you to choose one form for consistency.</p>
                </div>

                <div className="section-block">
                    <h2>Conclusion: Striving for Perfection with Quality Control and Proofreading</h2>
                    <p>By using self-editing techniques, embracing peer feedback, and utilizing quality assurance tools, you can transform your transcription work into professional, flawless documents. Attention to detail in quality control reflects your professionalism and dedication to delivering excellent results.</p>
                    <p>Remember, each step in this process—whether it’s taking a break before proofreading or ensuring consistency with quality tools—helps you build a reputation for quality and reliability.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default QualityControlProofreading;
