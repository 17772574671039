import React from 'react';
import { Typography, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const UserDetails = ({ user, onClose }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Button onClick={onClose} variant="contained" color="primary" sx={{ mb: 2 }}>
        Back to User List
      </Button>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>User Details</Typography>
        <Typography><strong>ID:</strong> {user.id}</Typography>
        <Typography><strong>Name:</strong> {user.fullName}</Typography>
        <Typography><strong>Email:</strong> {user.email}</Typography>
        <Typography><strong>Role:</strong> {user.role}</Typography>
        <Typography><strong>Status:</strong> {user.status}</Typography>
        <Typography><strong>Total Earnings:</strong> Ksh {user.totalEarnings.toFixed(2)}</Typography>
      </Paper>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>Bid Statistics</Typography>
        <Typography><strong>Total Bids:</strong> {user.bidStats.totalBids}</Typography>
        <Typography><strong>Won Bids:</strong> {user.bidStats.wonBids.count}</Typography>
        <Typography><strong>Lost Bids:</strong> {user.bidStats.lostBids.count}</Typography>
      </Paper>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>Won Bids</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Task ID</TableCell>
                <TableCell>Task Title</TableCell>
                <TableCell>Task Status</TableCell>
                <TableCell>Bid Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user.bidStats.wonBids.details.map((bid) => (
                <TableRow key={bid.taskId}>
                  <TableCell>{bid.taskId}</TableCell>
                  <TableCell>{bid.taskTitle}</TableCell>
                  <TableCell>{bid.taskStatus}</TableCell>
                  <TableCell>Ksh {bid.bidAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>Lost Bids</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Task ID</TableCell>
                <TableCell>Task Title</TableCell>
                <TableCell>Task Status</TableCell>
                <TableCell>Bid Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {user.bidStats.lostBids.details.map((bid) => (
                <TableRow key={bid.taskId}>
                  <TableCell>{bid.taskId}</TableCell>
                  <TableCell>{bid.taskTitle}</TableCell>
                  <TableCell>{bid.taskStatus}</TableCell>
                  <TableCell>Ksh {bid.bidAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default UserDetails;