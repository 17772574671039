import React, { useEffect } from 'react';
import './Tools.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function ToolsAndEquipment() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/process');
    };

    return (
        <div className="transcription-intro">
            <header className="header">
                <h1>Tools and Equipment for Transcriptionists</h1>
                <p className="subtitle">Equipping Yourself for Success</p>
            </header>

            <div className="content">
                <div className="section-block">
                    <h2>1. Hardware</h2>
                    <h3>Computers and Laptops</h3>
                    <p>Success as a transcriptionist isn’t just about having the skills—it’s about equipping yourself with the right tools that will enhance your workflow, speed up your productivity, and protect your health in the long run. Your computer is the backbone of your transcription work. A reliable, fast, and powerful machine will allow you to handle large audio files, run transcription software, and work on multiple tasks without crashing or lagging.</p>
                    <p><strong>Desktops vs. Laptops:</strong> Desktops offer more power and customizability. If you work from home and need a heavy-duty machine that can handle intensive tasks (like transcribing large, multi-hour files), a desktop with plenty of RAM and a fast processor will serve you well. Laptops offer portability, allowing you to work from anywhere. For freelance transcriptionists who are constantly on the go, a lightweight but powerful laptop is essential. Look for models with long battery life, high processing power, and at least 8GB of RAM to handle multitasking.</p>
                    <p><strong>Example:</strong> If you work in a professional transcription service handling medical or legal documents, a laptop like the MacBook Air or Dell XPS 13 can offer you the portability and speed you need. For home office work, a custom-built desktop with 16GB RAM and a fast SSD drive will ensure you can transcribe high-quality audio without performance issues.</p>

                    <h3>Headphones (Types and Features)</h3>
                    <p>High-quality headphones are absolutely critical for transcriptionists. Your job is to capture every word, nuance, and inflection of speech, and that requires clarity. The wrong pair of headphones can cause you to miss subtle sounds, especially in low-quality recordings.</p>
                    <p><strong>Over-Ear Headphones:</strong> These offer the best noise isolation and sound quality, making them perfect for long transcription sessions. They help block out external distractions, allowing you to focus entirely on the audio. Look for models with noise-cancellation technology, which can be especially helpful in noisy environments.</p>
                    <p><strong>Example:</strong> Sony WH-1000XM5 or Bose QuietComfort 35 II are excellent choices for their crystal-clear sound and noise-cancellation features.</p>
                    <p><strong>In-Ear Headphones:</strong> These are lightweight and portable, making them ideal for transcriptionists who are always on the move. They’re not as isolating as over-ear headphones but can still provide decent sound quality.</p>
                    <p><strong>Example:</strong> Shure SE215 are premium in-ear monitors used by professionals for their clear sound reproduction.</p>

                    <h3>Foot Pedals</h3>
                    <p>Foot pedals are a game-changer for transcriptionists. They allow you to control audio playback with your feet while keeping your hands free to type, significantly improving your typing speed and workflow. You can pause, rewind, and play audio files without lifting your fingers off the keyboard—saving precious time.</p>
                    <p><strong>Example:</strong> The Infinity USB Digital Foot Control is one of the most popular and reliable foot pedals, compatible with most transcription software. It allows easy control over play, pause, rewind, and fast forward functions.</p>
                    <p><strong>Why It's Useful:</strong> Instead of stopping to manually click “pause” or “rewind” on your computer, you can keep typing seamlessly while controlling the audio with your foot—dramatically increasing your productivity.</p>

                    <h3>Ergonomic Accessories (Chairs, Keyboards, etc.)</h3>
                    <p>Since transcription often requires long hours sitting at a desk, ergonomic accessories are a must to prevent strain and injury. Your health is as important as your speed and accuracy—so invest in tools that support your body’s natural posture and movement.</p>
                    <p><strong>Ergonomic Chairs:</strong> Proper lumbar support is essential for your back and posture. Look for chairs that are adjustable and provide good lower back support.</p>
                    <p><strong>Example:</strong> The Herman Miller Aeron chair is designed with ergonomics in mind, helping prevent back pain even during long typing sessions.</p>
                    <p><strong>Ergonomic Keyboards:</strong> Standard keyboards can lead to wrist and finger strain over time. Ergonomic keyboards are designed to reduce strain on your wrists by aligning your arms and hands in a more natural position.</p>
                    <p><strong>Example:</strong> The Kinesis Advantage2 keyboard is highly rated for its split, curved design that promotes natural hand positioning.</p>
                    <p><strong>Wrist Rests:</strong> A good wrist rest can help prevent carpal tunnel syndrome by reducing the pressure on your wrists while typing. Combine this with a well-designed ergonomic mouse to protect your hand and arm from strain.</p>
                </div>

                <div className="section-block">
                    <h2>2. Software</h2>
                    <h3>Word Processors</h3>
                    <p>The backbone of any transcriptionist’s workflow, word processors are used to type out and format your transcripts. The right software can streamline your editing and ensure that you deliver polished, professional results.</p>
                    <p><strong>Microsoft Word:</strong> This is the industry standard and offers a range of features like spelling and grammar checks, formatting options, and the ability to share files in various formats. It’s robust enough for all transcription work.</p>
                    <p><strong>Google Docs:</strong> Perfect for transcriptionists who collaborate with clients or teams. With real-time editing, you can share your document with others for feedback or proofreading.</p>
                    <p><strong>Example:</strong> Using Track Changes in Microsoft Word can help you and your clients keep track of edits made to the transcript.</p>

                    <h3>Specialized Transcription Software</h3>
                    <p>While general word processors are useful, specialized transcription software can make your life significantly easier by combining audio playback with transcription features.</p>
                    <p><strong>Express Scribe:</strong> One of the most popular transcription tools, it supports foot pedals, offers variable speed playback (slowing down audio without distorting the speaker’s voice), and integrates with a variety of audio formats.</p>
                    <p><strong>Example:</strong> You can control audio playback with a foot pedal and set automatic timestamps in the transcript using Express Scribe, saving time on manual work.</p>
                    <p><strong>Otter.ai:</strong> An AI-powered tool that provides automatic speech-to-text transcription. While it’s not perfect and may require heavy proofreading, it’s useful for generating first drafts of simple transcriptions.</p>
                    <p><strong>Example:</strong> For meetings or interviews, Otter.ai can produce a rough draft within minutes, which you can then clean up.</p>
                    <p><strong>oTranscribe:</strong> A free online tool that offers basic transcription features like foot pedal support and integrated audio controls.</p>

                    <h3>Audio Players with Transcription Features</h3>
                    <p>Not all audio players are built for transcription, but there are some that come with features to help you control playback speed, pause, rewind, and jump forward.</p>
                    <p><strong>VLC Media Player:</strong> While it’s not a dedicated transcription tool, VLC allows for slow playback, loop sections, and the adjustment of playback speed, making it useful for manual transcription.</p>
                    <p><strong>Example:</strong> You can slow down a fast speaker in VLC to half-speed without distorting the voice, making it easier to catch every word.</p>
                </div>

                <div className="section-block">
                    <h2>3. Online Resources</h2>
                    <h3>Style Guides</h3>
                    <p>Different industries require different transcription styles, and following the right guide can ensure that your transcripts meet professional standards.</p>
                    <p><strong>The Chicago Manual of Style:</strong> Widely used in publishing, this style guide can help with punctuation, citation, and formatting.</p>
                    <p><strong>The Associated Press (AP) Stylebook:</strong> Commonly used for media and journalism transcription.</p>
                    <p><strong>Example:</strong> A legal transcriptionist will follow a different style guide than someone transcribing a podcast. Familiarizing yourself with industry-specific style rules will help ensure your work meets client expectations.</p>

                    <h3>Dictionaries and Thesauri</h3>
                    <p>Every transcriptionist needs quick access to dictionaries and thesauri to ensure proper spelling, usage, and understanding of unfamiliar terms.</p>
                    <p><strong>Merriam-Webster Online:</strong> Provides definitions, pronunciation guides, and examples of word usage.</p>
                    <p><strong>Thesaurus.com:</strong> A quick way to find synonyms and ensure you’re using the right word in the right context.</p>
                    <p><strong>Example:</strong> When transcribing a business meeting, you may come across unfamiliar corporate jargon. Keeping an industry-specific dictionary handy ensures that your transcript is both accurate and professional.</p>

                    <h3>Industry-Specific Reference Materials</h3>
                    <p>Transcriptionists often work in niche fields (medical, legal, academic) that use specialized terminology. Having access to industry-specific resources is critical for delivering accurate work.</p>
                    <p><strong>For Medical Transcriptionists:</strong> Stedman’s Medical Dictionary is an essential tool for understanding complex medical terms.</p>
                    <p><strong>For Legal Transcriptionists:</strong> Black’s Law Dictionary is widely regarded as the gold standard for legal definitions.</p>
                    <p><strong>Example:</strong> If you are transcribing a legal deposition, you’ll need to know the meaning and proper usage of terms like “subpoena” or “interrogatories.”</p>
                </div>

                <div className="section-block">
                    <h2>Conclusion: Equipping Yourself for Success</h2>
                    <p>The tools and equipment you use will define your workflow as a transcriptionist. Investing in the right hardware and software, along with leveraging online resources, can transform your efficiency and the quality of your transcripts. Whether it’s ensuring you have high-quality headphones for clear audio or an ergonomic keyboard to prevent strain, every detail counts. Take pride in knowing that by choosing the right tools, you are positioning yourself to become a top-notch, reliable transcriptionist capable of producing professional-grade work every time.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default ToolsAndEquipment;
