import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Box } from '@mui/material';
import { API_BASE_URL } from '../config';

const BidsManagement = () => {
  const [bids, setBids] = useState([]);

  useEffect(() => {
    fetchBids();
  }, []);

  const fetchBids = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/bids`, {
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        }
      });
      const data = await response.json();
      setBids(data);
    } catch (error) {
      console.error('Error fetching bids:', error);
    }
  };

  const handleAcceptBid = async (bidId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/admin/bids/${bidId}/accept`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
        }
      });
      if (response.ok) {
        fetchBids(); // Refresh bids after accepting
      }
    } catch (error) {
      console.error('Error accepting bid:', error);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" sx={{ mb: 4 }}>Bids Management</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="bids table">
          <TableHead>
            <TableRow>
              <TableCell>Task</TableCell>
              <TableCell>Writer</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bids.map((bid) => (
              <TableRow key={bid.id}>
                <TableCell>{bid.taskTitle}</TableCell>
                <TableCell>{bid.userFullName}</TableCell>
                <TableCell>Ksh {bid.amount}</TableCell>
                <TableCell>{bid.status}</TableCell>
                <TableCell>{new Date(bid.createdAt).toLocaleString()}</TableCell>
                <TableCell>
                  {bid.status === 'pending' && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleAcceptBid(bid.id)}
                    >
                      Accept
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BidsManagement;