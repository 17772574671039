import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function SpecializedTranscriptionFields() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/software-technology');
    };

    return (
        <div className="transcription-intro">
            <header className="header">
                <h1>Specialized Transcription Fields</h1>
                <p className="subtitle">Mastering Specialized Forms of Transcription</p>
            </header>

            <div className="content">
                <div className="section-block">
                    <h2>1. Legal Transcription</h2>
                    <h3>Legal Terminology and Formatting</h3>
                    <p>Legal transcription involves converting audio recordings of court proceedings, depositions, interrogations, and other legal interactions into written documents. In this field, the accuracy of legal terms, phrasing, and context is paramount. Understanding terms like <i>plaintiff</i>, <i>defendant</i>, <i>amicus curiae</i>, or <i>ex parte</i> will ensure you deliver precise transcripts that can be used in courtrooms or legal reviews.</p>
                    <p><strong>Example:</strong> “The plaintiff seeks damages for the alleged breach of contract as outlined in Exhibit A.”</p>
                    <p>Legal transcription also demands strict adherence to formatting guidelines. For example, court transcripts must be formatted with proper speaker identification, timestamps, and consistent paragraph spacing.</p>
                    <p><strong>Formatting Example:</strong></p>
                    <p><strong>Judge:</strong> State your name for the record.<br />
                        <strong>Witness:</strong> John Doe.<br />
                        <strong>Judge:</strong> What is your relationship to the defendant?</p>
                </div>

                <div className="section-block">
                    <h3>Confidentiality and Security Concerns</h3>
                    <p>One of the most critical aspects of legal transcription is maintaining confidentiality. Sensitive legal matters, including personal client information, are often handled in these transcripts, making security paramount. You must be familiar with client confidentiality agreements and industry standards like encryption tools for file storage and transfer.</p>
                </div>

                <div className="section-block">
                    <h2>2. Medical Transcription</h2>
                    <h3>Medical Terminology and Abbreviations</h3>
                    <p>Medical transcription involves creating detailed records of doctor-patient interactions, medical reports, and diagnostic summaries. A strong understanding of medical terms and abbreviations is crucial. Terms like <i>hypertension</i> (high blood pressure), <i>tachycardia</i> (rapid heartbeat), and abbreviations like <i>BPM</i> (beats per minute), <i>q.d.</i> (once daily), or <i>NPO</i> (nothing by mouth) are just a few examples of language you must be familiar with.</p>
                    <p><strong>Example:</strong> “Patient presents with dyspnea and tachycardia. Recommend CBC and ECG for further investigation.”</p>
                    <p>Being precise is essential in medical transcription because even a slight misunderstanding or misspelling of a medical term can lead to misinterpretations in patient care.</p>
                </div>

                <div className="section-block">
                    <h3>HIPAA Compliance and Patient Privacy</h3>
                    <p>Medical transcriptionists must adhere to the Health Insurance Portability and Accountability Act (HIPAA) regulations to protect patient privacy. HIPAA dictates that patient information should remain confidential, and this applies to all transcripts involving patient records. You must ensure that all files are encrypted and stored securely and that any shared files are transmitted through HIPAA-compliant platforms.</p>
                    <p>For example, you should remove all personally identifiable information (PII) when handling de-identified medical transcripts for research purposes.</p>
                </div>

                <div className="section-block">
                    <h2>3. Academic Transcription</h2>
                    <h3>Handling Lectures and Interviews</h3>
                    <p>Academic transcription is commonly used for transcribing lectures, seminars, research interviews, and focus group discussions. Here, clarity, proper identification of speakers, and capturing academic jargon are critical. Whether transcribing for researchers or students, you should understand the importance of ensuring that all key points, terminologies, and speaker contributions are accurately captured.</p>
                    <p><strong>Example:</strong> “Professor: The principle of supply and demand is essential in economics.”<br />
                        <strong>Student A:</strong> Could you explain how that applies to modern digital marketplaces?</p>
                </div>

                <div className="section-block">
                    <h3>Citation Formats for Academic Transcripts</h3>
                    <p>In academic settings, proper citation is critical, especially when the transcripts are part of research documentation. It’s important to be familiar with citation formats like APA, MLA, or Chicago styles when referencing transcripts in academic publications.</p>
                    <p><strong>Example (APA Style):</strong> (Doe, J., personal communication, January 10, 2024)</p>
                </div>

                <div className="section-block">
                    <h2>4. Media and Entertainment Transcription</h2>
                    <h3>Transcribing for Closed Captions and Subtitles</h3>
                    <p>Media transcription is often used for creating closed captions or subtitles for films, TV shows, documentaries, and online videos. Accuracy, timing, and synchronization are critical. Captions need to reflect what is being said word-for-word, while subtitles may be condensed to fit reading speed.</p>
                    <p><strong>Example (for closed captions):</strong></p>
                    <p>[Door creaks] Person 1: “Did you hear that?”<br />
                        [Whispering] Person 2: “Yes, we should check it out.”</p>
                    <p>Timing is crucial when dealing with closed captions. Every sound, background noise, or effect must be included in the transcription.</p>
                </div>

                <div className="section-block">
                    <h3>Handling Music and Sound Effects</h3>
                    <p>In media transcription, it's not only about the dialogue; you need to describe the sounds, music, and background effects that are integral to the scene. A simple [music playing] or [wind blowing] may convey what’s happening, but for more intense scenes, being specific can enhance the user experience.</p>
                    <p><strong>Example (sound effects and music):</strong></p>
                    <p>[Upbeat jazz music starts]<br />
                        [Car screeches to a stop]<br />
                        Person 1: “We made it just in time!”</p>
                    <p>This is especially important for accessibility, ensuring that people who rely on captions understand the full context of the scene, including the non-verbal cues.</p>
                </div>

                <div className="section-block">
                    <h2>Conclusion: Elevating Your Transcription Skills in Specialized Fields</h2>
                    <p>Specialized transcription fields require precision, a deep understanding of industry-specific terms, and strict adherence to guidelines. Whether you're transcribing a legal deposition, summarizing a medical diagnosis, or captioning a blockbuster movie, your attention to detail directly affects the quality of the transcript.</p>
                    <p>Mastering these fields is not just about typing fast—it’s about ensuring every term, sound, and nuance is captured with clarity and professionalism. With specialized transcription skills, you'll open doors to high-demand opportunities and make yourself indispensable to clients who need transcripts that are more than just words on a page—they are records of important events, medical history, or academic research.</p>
                    <p>Make every transcript you produce a testimony to your professionalism and skill. Your expertise ensures that what’s said is documented accurately and meaningfully, empowering industries to function efficiently.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default SpecializedTranscriptionFields;
