import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function TranscriptionStyles() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/grammar-punctuation');
    };

    return (
        <div className="transcription-intro">
            <header className="header">
                <h1>Transcription Styles and Formatting</h1>
                <p className="subtitle">Mastering Formatting Techniques</p>
            </header>

            <div className="content">
                {/* Standard Transcription Formats Section */}
                <div className="section-block">
                    <h2>1. Standard Transcription Formats</h2>
                    <h3>Two-Column Format</h3>
                    <p>The two-column format is often used in legal, corporate, or interview transcriptions. It organizes the transcript into two distinct columns: one for the speaker and another for their dialogue. This format makes it easy to follow who is speaking at any given time.</p>
                    <p><strong>Example:</strong></p>
                    <p>Speaker&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dialogue</p>
                    <p>Interviewer&nbsp;&nbsp;&nbsp;&nbsp;Can you tell us about your experience with the project?</p>
                    <p>Interviewee&nbsp;&nbsp;&nbsp;&nbsp;Sure. I started working on the project about six months ago.</p>
                    <p><strong>When to Use It:</strong> Ideal for interviews, legal depositions, and group discussions.</p>

                    <h3>Play Script Format</h3>
                    <p>Inspired by theater scripts, the play script format is particularly useful in media transcription, court reporting, and performance transcriptions. Each speaker's name is followed by their dialogue on the same line, streamlining for multiple speakers.</p>
                    <p><strong>Example:</strong></p>
                    <p>John: I think we should move forward with the proposal.</p>
                    <p>Sarah: I agree, but we need more data before making a final decision.</p>
                    <p><strong>When to Use It:</strong> Ideal for podcasts, media transcriptions, or court reporting.</p>

                    <h3>Narrative Format</h3>
                    <p>In the narrative format, the transcript is written as a flowing document without formal breaks for each speaker. Instead, it uses embedded speaker labels, which can be more literary or report-like.</p>
                    <p><strong>Example:</strong></p>
                    <p>John said that he thinks they should move forward with the proposal. Sarah agreed but noted that more data was needed before making a final decision.</p>
                    <p><strong>When to Use It:</strong> Ideal for summaries or reports, where key points need to be captured in a natural, flowing narrative.</p>
                </div>

                {/* Formatting Elements Section */}
                <div className="section-block">
                    <h2>2. Formatting Elements</h2>
                    <h3>Speaker Identification</h3>
                    <p>Clearly identifying the speaker is critical in any transcription. Whether using names, titles, or generic labels like Speaker 1 or Speaker 2, consistency is key.</p>
                    <p><strong>Example:</strong></p>
                    <p>Speaker 1: What are your thoughts on the new policy?</p>
                    <p>Speaker 2: I believe it’s a step in the right direction.</p>
                    <p><strong>Pro Tip:</strong> Provide a speaker key at the top of your transcript for group discussions or legal transcriptions.</p>

                    <h3>Timestamps</h3>
                    <p>Timestamps are vital for projects where timing is important, such as legal transcriptions or video captions. Insert timestamps at regular intervals or speaker changes.</p>
                    <p><strong>Example:</strong></p>
                    <p>[00:02:35] Interviewer: Can you explain your approach to the project?</p>
                    <p>[00:02:40] Interviewee: Absolutely. We started by assessing the current market needs.</p>
                    <p><strong>Pro Tip:</strong> Use transcription software to automate timestamp insertion.</p>

                    <h3>Paragraph Breaks</h3>
                    <p>Paragraph breaks make transcripts more readable by separating blocks of dialogue or shifts in conversation.</p>
                    <p><strong>Example:</strong></p>
                    <p>John mentioned that the new data was critical to the project, adding that the team had been working on it for months.</p>
                    <p>Sarah agreed, noting that the data had already been implemented in several projects across departments.</p>
                    <p><strong>Pro Tip:</strong> Break paragraphs when there is a shift in topic or speaker.</p>

                    <h3>Handling Non-Verbal Sounds</h3>
                    <p>Non-verbal sounds like laughter, sighs, or applause should be noted in brackets to give the reader context.</p>
                    <p><strong>Example:</strong></p>
                    <p>Interviewer: Did you enjoy working on the project?</p>
                    <p>Interviewee: Yes, I did. [laughs]</p>
                    <p><strong>Pro Tip:</strong> Use consistent brackets for non-verbal sounds (e.g., [laughs], [applause]).</p>
                </div>

                {/* Style Guides Section */}
                <div className="section-block">
                    <h2>3. Style Guides</h2>
                    <h3>Creating and Following Style Guides</h3>
                    <p>A style guide ensures that your transcriptions are consistent in punctuation, grammar, speaker identification, and formatting elements.</p>
                    <p><strong>Key Elements:</strong></p>
                    <ul>
                        <li>Punctuation Rules: When to use commas, periods, and quotation marks.</li>
                        <li>Speaker Identification: Guidelines for labeling speakers.</li>
                        <li>Timestamp Placement: At regular intervals or only at key points.</li>
                        <li>Capitalization and Formatting: Rules for capitalizing proper nouns and formatting speaker labels.</li>
                    </ul>

                    <h3>Adapting to Client-Specific Requirements</h3>
                    <p>Different clients may have unique expectations. Adapting to these specific requirements shows professionalism and flexibility.</p>
                    <p><strong>Example:</strong></p>
                    <ul>
                        <li>Legal Transcription: Requires formal language and titles (e.g., "Attorney Smith").</li>
                        <li>Podcast Transcription: May use informal formatting and minimal speaker labels.</li>
                        <li>Academic Research: Often requires highly accurate verbatim transcriptions with detailed speaker labels.</li>
                    </ul>
                </div>

                {/* Conclusion Section */}
                <div className="section-block">
                    <h2>Conclusion: Mastering the Art of Transcription Formatting</h2>
                    <p>Transcription styles and formatting are where skill meets presentation. The way you format your transcripts can elevate their professionalism and usability. Mastering these techniques will allow you to adapt to any project, ensuring your transcripts are polished, clear, and meet client expectations.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default TranscriptionStyles;
