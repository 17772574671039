// src/pages/JavaNetworking.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import CodeBlock from '../components/CodeBlock';
import NextButton from '../components/NextButton';
import './JavaMemoryManagementP.css';

const JavaNetworking = () => {
    const socketClientCode = `
import java.io.IOException;
import java.io.OutputStream;
import java.io.PrintWriter;
import java.net.Socket;

public class SimpleClient {
    public static void main(String[] args) {
        String serverAddress = "localhost";
        int port = 12345;

        try (Socket socket = new Socket(serverAddress, port)) {
            OutputStream output = socket.getOutputStream();
            PrintWriter writer = new PrintWriter(output, true);

            writer.println("Hello, Server!");
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}`;

    const socketServerCode = `
import java.io.BufferedReader;
import java.io.IOException;
import java.io.InputStreamReader;
import java.net.ServerSocket;
import java.net.Socket;

public class SimpleServer {
    public static void main(String[] args) {
        int port = 12345;

        try (ServerSocket serverSocket = new ServerSocket(port)) {
            System.out.println("Server is listening on port " + port);

            Socket socket = serverSocket.accept();
            BufferedReader reader = new BufferedReader(new InputStreamReader(socket.getInputStream()));

            String message = reader.readLine();
            System.out.println("Received: " + message);

        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}`;

    const urlExampleCode = `
import java.net.URL;
import java.net.MalformedURLException;

public class URLExample {
    public static void main(String[] args) {
        try {
            URL url = new URL("https://www.example.com/index.html");
            System.out.println("Protocol: " + url.getProtocol());
            System.out.println("Host: " + url.getHost());
            System.out.println("File: " + url.getFile());
        } catch (MalformedURLException e) {
            e.printStackTrace();
        }
    }
}`;

    const httpURLConnectionCode = `
import java.io.BufferedReader;
import java.io.IOException;
import java.io.InputStreamReader;
import java.net.HttpURLConnection;
import java.net.URL;

public class HttpURLConnectionExample {
    public static void main(String[] args) {
        try {
            URL url = new URL("https://jsonplaceholder.typicode.com/posts/1");
            HttpURLConnection connection = (HttpURLConnection) url.openConnection();
            connection.setRequestMethod("GET");

            int responseCode = connection.getResponseCode();
            System.out.println("Response Code: " + responseCode);

            BufferedReader in = new BufferedReader(new InputStreamReader(connection.getInputStream()));
            String inputLine;
            StringBuilder content = new StringBuilder();

            while ((inputLine = in.readLine()) != null) {
                content.append(inputLine);
            }

            in.close();
            connection.disconnect();

            System.out.println("Response Content: " + content.toString());

        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}`;

    const echoServerCode = `
import java.io.BufferedReader;
import java.io.IOException;
import java.io.InputStreamReader;
import java.io.OutputStreamWriter;
import java.io.PrintWriter;
import java.net.ServerSocket;
import java.net.Socket;

public class EchoServer {
    public static void main(String[] args) {
        int port = 12345;

        try (ServerSocket serverSocket = new ServerSocket(port)) {
            System.out.println("Server is listening on port " + port);

            while (true) {
                Socket socket = serverSocket.accept();
                System.out.println("New client connected");

                new ClientHandler(socket).start();
            }
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}

class ClientHandler extends Thread {
    private Socket socket;

    public ClientHandler(Socket socket) {
        this.socket = socket;
    }

    @Override
    public void run() {
        try (BufferedReader reader = new BufferedReader(new InputStreamReader(socket.getInputStream()));
             PrintWriter writer = new PrintWriter(new OutputStreamWriter(socket.getOutputStream()), true)) {

            String message;
            while ((message = reader.readLine()) != null) {
                System.out.println("Received: " + message);
                writer.println("Echo: " + message);
            }
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}`;

    const echoClientCode = `
import java.io.BufferedReader;
import java.io.IOException;
import java.io.InputStreamReader;
import java.io.OutputStreamWriter;
import java.io.PrintWriter;
import java.net.Socket;

public class EchoClient {
    public static void main(String[] args) {
        String serverAddress = "localhost";
        int port = 12345;

        try (Socket socket = new Socket(serverAddress, port);
             BufferedReader reader = new BufferedReader(new InputStreamReader(socket.getInputStream()));
             PrintWriter writer = new PrintWriter(new OutputStreamWriter(socket.getOutputStream()), true);
             BufferedReader consoleReader = new BufferedReader(new InputStreamReader(System.in))) {

            System.out.println("Connected to server");

            String userInput;
            while ((userInput = consoleReader.readLine()) != null) {
                writer.println(userInput);
                String response = reader.readLine();
                System.out.println("Server response: " + response);
            }
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Java Networking: A Comprehensive Guide</h1>
                <p>
                    Networking is a crucial aspect of modern software development, enabling applications to communicate with each other across networks. Java provides a rich set of libraries and APIs to facilitate networking, allowing developers to build robust, scalable, and efficient networked applications. This guide will cover key networking concepts in Java, including sockets, ServerSocket, URL, HttpURLConnection, and how to build a simple client-server application. Each section includes detailed explanations, code examples, and real-world applications.
                </p>

                <hr className="section-divider" />

                <h2>1. Sockets and ServerSocket</h2>

                <h3>1.1. Overview</h3>
                <p>
                    Sockets are the endpoints for communication between two machines over a network. A socket is bound to a port number so that the Transmission Control Protocol (TCP) can identify the application that data is destined to. Java provides the Socket and ServerSocket classes to facilitate network communication.
                </p>
                <ul>
                    <li><strong>Socket:</strong> Represents the client-side endpoint for communication.</li>
                    <li><strong>ServerSocket:</strong> Represents the server-side endpoint that listens for incoming connections.</li>
                </ul>

                <h3>1.2. Creating a Socket (Client-Side)</h3>
                <CodeBlock code={socketClientCode} />

                <h4>Explanation:</h4>
                <p><strong>Socket Creation:</strong> The client creates a Socket connected to the server at localhost on port 12345.</p>
                <p><strong>Data Transmission:</strong> The client sends a message ("Hello, Server!") to the server using a PrintWriter wrapped around the socket’s output stream.</p>

                <h3>1.3. Creating a ServerSocket (Server-Side)</h3>
                <CodeBlock code={socketServerCode} />

                <h4>Explanation:</h4>
                <p><strong>ServerSocket Creation:</strong> The server creates a ServerSocket bound to port 12345.</p>
                <p><strong>Listening for Connections:</strong> The server calls <code>accept()</code> to block and wait for incoming connections. When a client connects, a <code>Socket</code> is returned, allowing the server to communicate with the client.</p>
                <p><strong>Data Reception:</strong> The server reads the message sent by the client using a <code>BufferedReader</code> wrapped around the socket’s input stream.</p>

                <h3>1.4. Real-World Application</h3>
                <p>Sockets and ServerSocket are fundamental to creating networked applications such as chat servers, file transfer utilities, and real-time multiplayer games. They enable low-level control over network communication, making them suitable for applications where performance and direct access to the network stack are critical.</p>

                <hr className="section-divider" />

                <h2>2. URL and HttpURLConnection</h2>

                <h3>2.1. Overview</h3>
                <p>
                    Java provides the URL and HttpURLConnection classes for higher-level network operations, such as communicating with web servers using HTTP/HTTPS. These classes abstract away the details of creating sockets and managing connections, allowing developers to easily interact with web resources.
                </p>

                <h3>2.2. Working with URL</h3>
                <CodeBlock code={urlExampleCode} />

                <h4>Explanation:</h4>
                <p><strong>URL Parsing:</strong> The URL object parses the provided string into its components (protocol, host, file path).</p>
                <p><strong>MalformedURLException:</strong> This exception is thrown if the provided string does not represent a valid URL.</p>

                <h3>2.3. Working with HttpURLConnection</h3>
                <CodeBlock code={httpURLConnectionCode} />

                <h4>Explanation:</h4>
                <p><strong>HttpURLConnection Setup:</strong> A <code>HttpURLConnection</code> object is created by calling <code>openConnection()</code> on a URL object.</p>
                <p><strong>Sending a GET Request:</strong> The <code>setRequestMethod("GET")</code> method specifies the HTTP request type.</p>
                <p><strong>Receiving a Response:</strong> The response is read using a <code>BufferedReader</code> wrapped around the connection’s input stream.</p>
                <p><strong>Connection Management:</strong> The connection is closed after the response is processed using <code>disconnect()</code>.</p>

                <h3>2.4. Real-World Application</h3>
                <p>URL and HttpURLConnection are used in applications that need to interact with web services, such as RESTful APIs, fetching web pages, or uploading files. They are foundational for building web crawlers, integrating with third-party services, or accessing online resources directly from Java applications.</p>

                <hr className="section-divider" />

                <h2>3. Building a Simple Client-Server Application</h2>

                <h3>3.1. Overview</h3>
                <p>
                    A client-server application involves a server that provides resources or services and one or more clients that request these resources or services. This section walks through building a simple client-server application using sockets.
                </p>

                <h3>3.2. Server-Side Implementation</h3>
                <CodeBlock code={echoServerCode} />

                <h4>Explanation:</h4>
                <p><strong>EchoServer:</strong> The server listens for connections on port 12345. When a client connects, a new <code>ClientHandler</code> thread is started to handle the client's requests.</p>
                <p><strong>ClientHandler:</strong> This class handles the communication with a single client, reading messages from the client and echoing them back.</p>

                <h3>3.3. Client-Side Implementation</h3>
                <CodeBlock code={echoClientCode} />

                <h4>Explanation:</h4>
                <p><strong>EchoClient:</strong> The client connects to the server on localhost at port 12345. It reads user input from the console, sends it to the server, and prints the server's response.</p>

                <h3>3.4. Running the Application</h3>
                <p>
                    To run this simple client-server application:
                    <ul>
                        <li>Start the EchoServer in one terminal.</li>
                        <li>Start the EchoClient in another terminal.</li>
                        <li>Type messages in the client terminal; the server will echo the messages back.</li>
                    </ul>
                </p>

                <h3>3.5. Real-World Application</h3>
                <p>This simple echo server demonstrates the basics of client-server communication. Such a pattern can be extended to build more complex applications like chat servers, multiplayer game servers, or command-line interfaces for remote server management.</p>

                <hr className="section-divider" />

                <h2>Conclusion</h2>
                <p>
                    Java provides a powerful set of tools and APIs for building networked applications. By mastering sockets and ServerSocket, you can create low-level, high-performance network applications. With URL and HttpURLConnection, you can easily interact with web services and perform HTTP operations. Building a simple client-server application helps in understanding the fundamental concepts of network communication, laying the foundation for more complex and scalable systems.
                </p>
                <p>
                    Whether you're developing an online multiplayer game, integrating with RESTful APIs, or building a distributed system, understanding Java networking is crucial. This guide has provided you with the foundational knowledge and practical examples needed to start building robust and efficient networked applications in Java.
                </p>

                <NextButton nextPage="/advanced-multithreading" />
            </div>
        </div>
    );
};

export default JavaNetworking;
