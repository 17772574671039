import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { API_BASE_URL } from '../config';

const DashboardOverview = () => {
  const [stats, setStats] = useState({
    taskStats: {
      incompleteOrders: 0,
      unpaidOrders: 0,
      ordersInRevision: 0,
      disputedOrders: 0
    },
    userStats: {
      totalWriters: 0,
      activeWriters: 0,
      suspendedWriters: 0
    }
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/admin/dashboard-stats`, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
          }
        });
        const data = await response.json();
        setStats(data);
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      }
    };

    fetchStats();
  }, []);

  const chartData = [
    { name: 'Incomplete', value: stats.taskStats.incompleteOrders },
    { name: 'Unpaid', value: stats.taskStats.unpaidOrders },
    { name: 'In Revision', value: stats.taskStats.ordersInRevision },
    { name: 'Disputed', value: stats.taskStats.disputedOrders },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h4" sx={{ mb: 4 }}>Dashboard Overview</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%', boxShadow: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Task Statistics</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: '100%', boxShadow: 3 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Writer Statistics</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="body1">
                Total Writers: <strong>{stats.userStats.totalWriters}</strong>
              </Typography>
              <Typography variant="body1">
                Active Writers: <strong>{stats.userStats.activeWriters}</strong>
              </Typography>
              <Typography variant="body1">
                Suspended Writers: <strong>{stats.userStats.suspendedWriters}</strong>
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardOverview;