import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function EthicsConfidentiality() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/continuing-education');
    };

    return (
        <div className="ethics-confidentiality-intro">
            <header className="header">
                <h1>Ethics and Confidentiality in Transcription</h1>
                <p className="subtitle">Safeguarding Data and Upholding Professional Integrity</p>
            </header>

            <div className="content">
                <div className="section-block">
                    <h2>Ethics and Confidentiality in Transcription</h2>
                    <p>In transcription, ethics and confidentiality are fundamental pillars that not only protect the integrity of your work but also build trust with clients and ensure legal compliance. As a professional transcriber, you will encounter sensitive information, and how you handle this data can make or break your career. Understanding the ethical guidelines and legal responsibilities surrounding confidentiality, accuracy, and intellectual property rights is essential to delivering high-quality transcription services while safeguarding your clients’ interests.</p>
                    <p>This section will dive into the key aspects of confidentiality, ethical behavior, and intellectual property, providing you with practical examples to implement in your transcription career.</p>
                </div>

                <div className="section-block">
                    <h2>1. Maintaining Confidentiality</h2>
                    <h3>Best Practices for Handling Sensitive Information</h3>
                    <p>Confidentiality is a critical part of transcription, especially when handling sensitive or private information. Whether you’re transcribing legal proceedings, medical records, or interviews, it’s essential to implement best practices to protect client data from unauthorized access or disclosure.</p>
                    <ul>
                        <li><strong>Data Encryption:</strong> When sending or receiving audio files or transcripts, use encryption tools or services to protect the data. Platforms like Google Drive or Dropbox offer encryption, but always ensure that you’re using the most secure options available.</li>
                        <li><strong>Secure Storage:</strong> Store files in secure, password-protected locations. Avoid keeping sensitive data on unprotected or shared devices, and ensure your devices are equipped with strong passwords or biometric locks.</li>
                        <li><strong>Limiting Access:</strong> Only authorized individuals should have access to the transcripts. If you work with a team, ensure each member is trained on confidentiality protocols and limit their access to only the parts of the project they need.</li>
                        <li><strong>Data Deletion:</strong> Once a project is completed and delivered, delete all client files from your devices unless otherwise instructed by the client. Keeping old, sensitive files increases the risk of data breaches.</li>
                    </ul>
                    <p><strong>Example:</strong> You’ve been hired to transcribe a legal deposition. After completing the transcription, instead of keeping a copy of the file on your computer “just in case,” you securely delete it to protect your client’s sensitive legal information.</p>

                    <h3>Legal Requirements (NDAs, HIPAA, etc.)</h3>
                    <p>In many industries, maintaining confidentiality goes beyond best practices—it’s a legal requirement. Non-Disclosure Agreements (NDAs) and laws like HIPAA (Health Insurance Portability and Accountability Act) set strict guidelines on how information should be handled, especially in medical or legal contexts.</p>
                    <ul>
                        <li><strong>NDAs:</strong> Clients may ask you to sign an NDA, which legally binds you to keep their information confidential. Breaching an NDA can result in legal action.</li>
                        <li><strong>HIPAA Compliance:</strong> If you’re working with medical transcriptions, you must adhere to HIPAA regulations, which protect patient privacy. This includes secure file storage, transmission, and ensuring that no unauthorized individuals access patient information.</li>
                    </ul>
                    <p><strong>Example:</strong> If you’re transcribing medical records for a healthcare provider, HIPAA laws require that you never disclose any patient information outside of the transcription task. You must also use secure channels to store and share these records.</p>
                </div>

                <div className="section-block">
                    <h2>2. Ethical Considerations</h2>
                    <h3>Accuracy and Integrity in Transcription</h3>
                    <p>Beyond confidentiality, ethical integrity in transcription encompasses the accuracy of your work and how you handle sensitive or potentially offensive content. As a transcriber, you have a responsibility to uphold professional standards regardless of the content you’re transcribing.</p>
                    <ul>
                        <li><strong>Verbatim Transcription:</strong> In some cases, such as legal or academic transcriptions, you may be required to transcribe verbatim, meaning every word, filler, or pause is included. Inaccuracies, even minor ones, can alter the meaning and have serious consequences.</li>
                        <li><strong>Non-Verbatim Transcription:</strong> In other cases, clients may prefer non-verbatim transcription where fillers (like “uh” or “um”) and redundancies are removed to create a more readable transcript. It’s still essential to maintain the integrity of the content without altering the meaning.</li>
                    </ul>
                    <p><strong>Example:</strong> If you’re transcribing a political speech and the speaker stumbles or corrects themselves, it’s important to capture that accurately. Changing their words could skew the intent of their message, which could have serious repercussions.</p>

                    <h3>Handling Controversial or Offensive Content</h3>
                    <p>You may encounter recordings that include controversial or offensive language, explicit content, or viewpoints that conflict with your personal beliefs. As a professional, it’s important to remain neutral and transcribe the content accurately, regardless of its nature. Your role is not to judge or censor the material but to ensure that it is faithfully represented.</p>
                    <ul>
                        <li>Maintain professional detachment. Focus on the task at hand without letting personal feelings interfere.</li>
                        <li>If a project’s content is too uncomfortable, communicate with the client. It’s okay to decline a project if it conflicts with your personal ethics.</li>
                    </ul>
                    <p><strong>Example:</strong> You’re hired to transcribe a documentary interview where the speaker uses offensive language. As part of your commitment to accuracy, you transcribe the content exactly as spoken, ensuring that the transcript reflects the speaker’s statements verbatim.</p>
                </div>

                <div className="section-block">
                    <h2>3. Copyright and Intellectual Property</h2>
                    <h3>Understanding Your Rights and Responsibilities</h3>
                    <p>Understanding your responsibilities regarding copyright and intellectual property rights is essential in transcription. Failing to attribute properly or violating intellectual property laws can lead to legal issues for both you and your clients. You must know when and how to attribute content and be aware of your own rights as a creator.</p>
                    <ul>
                        <li><strong>Client Responsibilities:</strong> In most cases, your client will provide the necessary permissions, but it’s always important to confirm that they have the right to use and distribute the material.</li>
                        <li><strong>Transcriber Rights:</strong> As a transcriber, you do not own the rights to the audio you transcribe unless specifically stated in your contract. The transcription itself typically becomes the intellectual property of the client. Be clear about your rights to use the transcription in your portfolio or for promotional purposes.</li>
                    </ul>
                    <p><strong>Example:</strong> You’re hired to transcribe a podcast episode. Before starting, you confirm with the client that they own the rights to the audio or have obtained permission from the original creators. This ensures that both you and your client are legally protected.</p>

                    <h3>Proper Attribution and Permissions</h3>
                    <p>In some cases, such as academic transcriptions or when quoting audio in written work, it’s important to properly attribute the source. If a client is publishing a transcription, they may need to attribute certain quotes or sections to their original creators.</p>
                    <ul>
                        <li><strong>Attribution Requirements:</strong> Ensure that the transcript clearly indicates who is speaking and, when necessary, includes citations for any quotes or external content used within the recording.</li>
                        <li><strong>Obtaining Permissions:</strong> If the transcript includes copyrighted material, such as lyrics or excerpts from other works, make sure the client has obtained the necessary permissions to use that content.</li>
                    </ul>
                    <p><strong>Example:</strong> You’re transcribing a university lecture that references a famous poem. The lecturer recites part of the poem, and you ensure that the transcript attributes it correctly, including any copyright information if the poem is not in the public domain.</p>
                </div>

                <div className="section-block">
                    <h2>Conclusion: Upholding Ethics and Confidentiality in Transcription</h2>
                    <p>Ethics and confidentiality aren’t just technicalities—they are core components of professional transcription work. By maintaining strict confidentiality protocols, ensuring accuracy and integrity in your work, and understanding copyright laws, you protect both your clients and your professional reputation.</p>
                    <p>As a transcriber, you hold a position of trust. Your clients rely on you to handle sensitive information with care and to produce transcripts that are honest, accurate, and legally compliant. When you commit to ethical practices, you not only enhance the quality of your work but also build lasting relationships with clients who know they can trust you with their most sensitive projects.</p>
                    <p>Ultimately, the value of a transcriptionist isn’t just in typing speed or accuracy—it’s in their integrity, professionalism, and dedication to maintaining ethical standards in every project. This is what sets you apart and ensures a long, successful career in transcription.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default EthicsConfidentiality;
