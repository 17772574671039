import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="sidebar-container">
            <button className="sidebar-toggle" onClick={toggleSidebar}>
                {isOpen ? 'Close Menu' : 'Open Menu'}
            </button>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-content">
                    <div className="sidebar-header">
                        <img src="/fitfreelance-logo.jpeg" alt="Website Logo" className="sidebar-logo" />
                    </div>
                    <ul>
                        <li><Link to="/transcription" onClick={toggleSidebar}>Introduction to Transcription</Link></li>
                        <li><Link to="/transcription/types" onClick={toggleSidebar}>Types of Transcription</Link></li>
                        <li><Link to="/transcription/skills" onClick={toggleSidebar}>Essential Skills for Transcriptionists</Link></li>
                        <li><Link to="/transcription/tools-equipment" onClick={toggleSidebar}>Tools and Equipment</Link></li>
                        <li><Link to="/transcription/process" onClick={toggleSidebar}>The Transcription Process</Link></li>
                        <li><Link to="/transcription/audio-quality" onClick={toggleSidebar}>Audio File Formats and Quality</Link></li>
                        <li><Link to="/transcription/styles-formatting" onClick={toggleSidebar}>Transcription Styles and Formatting</Link></li>
                        <li><Link to="/transcription/grammar-punctuation" onClick={toggleSidebar}>Grammar and Punctuation in Transcription</Link></li>
                        <li><Link to="/transcription/challenging-audio" onClick={toggleSidebar}>Handling Challenging Audio</Link></li>
                        <li><Link to="/transcription/specialized-fields" onClick={toggleSidebar}>Specialized Transcription Fields</Link></li>
                        <li><Link to="/transcription/software-technology" onClick={toggleSidebar}>Transcription Software and Technology</Link></li>
                        <li><Link to="/transcription/quality-control" onClick={toggleSidebar}>Quality Control and Proofreading</Link></li>
                        <li><Link to="/transcription/time-management" onClick={toggleSidebar}>Time Management and Productivity</Link></li>
                        <li><Link to="/transcription/career-building" onClick={toggleSidebar}>Building a Career in Transcription</Link></li>
                        <li><Link to="/transcription/ethics-confidentiality" onClick={toggleSidebar}>Ethics and Confidentiality in Transcription</Link></li>
                        <li><Link to="/transcription/continuing-education" onClick={toggleSidebar}>Continuing Education and Skill Development</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
