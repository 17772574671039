// src/pages/Multithreading.js
import React from 'react';
import Sidebar from '../components/Sidebar';
import NextButton from '../components/NextButton';
import CodeBlock from '../components/CodeBlock';
import './Multi.css';



const Multithreading = () => {
    const runnableExampleCode = `
class MyRunnable implements Runnable {
    @Override
    public void run() {
        for (int i = 0; i < 5; i++) {
            System.out.println(Thread.currentThread().getName() + " - Value: " + i);
            try {
                Thread.sleep(500); // Sleep for 500 milliseconds
            } catch (InterruptedException e) {
                e.printStackTrace();
            }
        }
    }
}

public class RunnableExample {
    public static void main(String[] args) {
        MyRunnable myRunnable = new MyRunnable();
        Thread thread1 = new Thread(myRunnable, "Thread-1");
        Thread thread2 = new Thread(myRunnable, "Thread-2");

        thread1.start();
        thread2.start();
    }
}
`;

    const threadExampleCode = `
class MyThread extends Thread {
    @Override
    public void run() {
        for (int i = 0; i < 5; i++) {
            System.out.println(getName() + " - Value: " + i);
            try {
                Thread.sleep(500); // Sleep for 500 milliseconds
            } catch (InterruptedException e) {
                e.printStackTrace();
            }
        }
    }
}

public class ThreadExample {
    public static void main(String[] args) {
        MyThread thread1 = new MyThread();
        MyThread thread2 = new MyThread();

        thread1.setName("Thread-1");
        thread2.setName("Thread-2");

        thread1.start();
        thread2.start();
    }
}
`;

    const synchronizedMethodCode = `
class Counter {
    private int count = 0;

    public synchronized void increment() {
        count++;
    }

    public int getCount() {
        return count;
    }
}

class CounterThread extends Thread {
    private Counter counter;

    public CounterThread(Counter counter) {
        this.counter = counter;
    }

    @Override
    public void run() {
        for (int i = 0; i < 1000; i++) {
            counter.increment();
        }
    }
}

public class SynchronizedExample {
    public static void main(String[] args) {
        Counter counter = new Counter();

        CounterThread thread1 = new CounterThread(counter);
        CounterThread thread2 = new CounterThread(counter);

        thread1.start();
        thread2.start();

        try {
            thread1.join();
            thread2.join();
        } catch (InterruptedException e) {
            e.printStackTrace();
        }

        System.out.println("Final count: " + counter.getCount()); // Expected: 2000
    }
}
`;

    const synchronizedBlockCode = `
class Counter {
    private int count = 0;
    private final Object lock = new Object();

    public void increment() {
        synchronized (lock) {
            count++;
        }
    }

    public int getCount() {
        return count;
    }
}
`;

    const producerConsumerCode = `
class SharedBuffer {
    private int data;
    private boolean hasData = false;

    public synchronized void produce(int value) {
        while (hasData) {
            try {
                wait(); // Wait until the data is consumed
            } catch (InterruptedException e) {
                e.printStackTrace();
            }
        }
        data = value;
        hasData = true;
        System.out.println("Produced: " + data);
        notify(); // Notify the consumer that data is available
    }

    public synchronized int consume() {
        while (!hasData) {
            try {
                wait(); // Wait until the data is produced
            } catch (InterruptedException e) {
                e.printStackTrace();
            }
        }
        hasData = false;
        System.out.println("Consumed: " + data);
        notify(); // Notify the producer that data has been consumed
        return data;
    }
}

class Producer extends Thread {
    private SharedBuffer buffer;

    public Producer(SharedBuffer buffer) {
        this.buffer = buffer;
    }

    @Override
    public void run() {
        for (int i = 1; i <= 5; i++) {
            buffer.produce(i);
            try {
                Thread.sleep(500);
            } catch (InterruptedException e) {
                e.printStackTrace();
            }
        }
    }
}

class Consumer extends Thread {
    private SharedBuffer buffer;

    public Consumer(SharedBuffer buffer) {
        this.buffer = buffer;
    }

    @Override
    public void run() {
        for (int i = 1; i <= 5; i++) {
            buffer.consume();
            try {
                Thread.sleep(500);
            } catch (InterruptedException e) {
                e.printStackTrace();
            }
        }
    }
}

public class InterThreadCommunicationExample {
    public static void main(String[] args) {
        SharedBuffer buffer = new SharedBuffer();
        Producer producer = new Producer(buffer);
        Consumer consumer = new Consumer(buffer);

        producer.start();
        consumer.start();
    }
}
`;

    return (
        <div className="content-container">
            <Sidebar />
            <div className="content">
                <h1>Mastering Multithreading in Java: A Comprehensive Guide</h1>
                <p>
                    Multithreading is a powerful feature in Java that allows concurrent execution of two or more parts of a program for maximum utilization of CPU. Each part of such a program is called a thread, and the process is known as multithreading. Understanding how to create and manage threads, synchronize them, and handle inter-thread communication is crucial for building efficient and responsive applications. This guide will delve into these aspects, providing detailed explanations, code examples, and real-world applications.
                </p>

                <hr className="section-divider" />

                <h2>1. Creating Threads (Runnable Interface, Thread Class)</h2>

                <h3>Overview</h3>
                <p>
                    In Java, there are two primary ways to create a thread:
                </p>
                <ul>
                    <li><strong>Implementing the Runnable Interface:</strong> The Runnable interface should be implemented by any class whose instances are intended to be executed by a thread.</li>
                    <li><strong>Extending the Thread Class:</strong> The Thread class itself implements Runnable, so a subclass of Thread can also be used to create a thread.</li>
                </ul>

                <h3>Using the Runnable Interface</h3>
                <h4>Steps:</h4>
                <ul>
                    <li>Implement the Runnable interface.</li>
                    <li>Override the <code>run()</code> method, which contains the code that constitutes the new thread.</li>
                    <li>Create a <code>Thread</code> object and pass the <code>Runnable</code> object to its constructor.</li>
                    <li>Start the thread using <code>start()</code>.</li>
                </ul>

                <h3>Example Code:</h3>
                <CodeBlock code={runnableExampleCode} />

                <h4>Output:</h4>
                <pre>
                    Thread-1 - Value: 0{'\n'}
                    Thread-2 - Value: 0{'\n'}
                    Thread-1 - Value: 1{'\n'}
                    Thread-2 - Value: 1{'\n'}
                    ...
                </pre>

                <p><strong>Explanation:</strong> The <code>MyRunnable</code> class implements <code>Runnable</code>, and the <code>run()</code> method contains the code that will be executed when the thread runs. Two threads (Thread-1 and Thread-2) are created and started, each executing the <code>run()</code> method concurrently.</p>

                <h3>Extending the Thread Class</h3>
                <h4>Steps:</h4>
                <ul>
                    <li>Extend the <code>Thread</code> class.</li>
                    <li>Override the <code>run()</code> method to define the code that the thread will execute.</li>
                    <li>Create an instance of the subclass and call <code>start()</code> to begin execution.</li>
                </ul>

                <h3>Example Code:</h3>
                <CodeBlock code={threadExampleCode} />

                <h4>Output:</h4>
                <pre>
                    Thread-1 - Value: 0{'\n'}
                    Thread-2 - Value: 0{'\n'}
                    Thread-1 - Value: 1{'\n'}
                    Thread-2 - Value: 1{'\n'}
                    ...
                </pre>

                <p><strong>Explanation:</strong> The <code>MyThread</code> class extends <code>Thread</code>, and the <code>run()</code> method is overridden to define the thread’s behavior. Two instances of <code>MyThread</code> are created and started, executing concurrently.</p>

                <h3>When to Use Which Approach</h3>
                <ul>
                    <li><strong>Runnable Interface:</strong> Preferred when you want to implement multithreading in a class that already extends another class (since Java does not support multiple inheritance).</li>
                    <li><strong>Thread Class:</strong> Convenient when you are directly dealing with threads without the need to extend other classes.</li>
                </ul>

                <p><strong>Real-World Application:</strong> Multithreading is essential in applications that perform time-consuming tasks, such as file processing, data computation, or network operations, without freezing the user interface. For example, in a web server, each client request might be handled by a separate thread, allowing the server to handle multiple requests concurrently.</p>

                <hr className="section-divider" />

                <h2>2. Synchronization</h2>

                <h3>Overview</h3>
                <p>
                    When multiple threads access shared resources (like objects or variables), there’s a risk of data inconsistency or corruption if the threads modify the resource simultaneously. Synchronization is the process of controlling the access of multiple threads to shared resources.
                </p>

                <h4>Key Concepts:</h4>
                <ul>
                    <li><strong>Critical Section:</strong> The part of the code that accesses shared resources should be synchronized to prevent race conditions.</li>
                </ul>

                <h3>Using <code>synchronized</code> Keyword</h3>
                <h4>Synchronized Method:</h4>
                <p>
                    If a method is declared with the <code>synchronized</code> keyword, the thread holds the monitor for that method’s object and prevents other threads from executing any synchronized methods on the same object.
                </p>

                <h3>Example Code (Synchronized Method):</h3>
                <CodeBlock code={synchronizedMethodCode} />

                <h4>Output:</h4>
                <pre>
                    Final count: 2000
                </pre>

                <p><strong>Explanation:</strong> The <code>increment()</code> method is synchronized to ensure that only one thread can modify the <code>count</code> variable at a time, preventing race conditions. The final count is consistent and as expected due to the use of synchronization.</p>

                <h4>Synchronized Block:</h4>
                <p>
                    Only the specified block of code is synchronized, allowing finer control over what is synchronized.
                </p>

                <h3>Example Code (Synchronized Block):</h3>
                <CodeBlock code={synchronizedBlockCode} />

                <p><strong>Explanation:</strong> The <code>increment()</code> method now only synchronizes the critical section, providing more control and potentially improving performance compared to synchronizing the entire method.</p>

                <p><strong>Real-World Application:</strong> Synchronization is critical in banking applications where multiple transactions might attempt to modify the same account balance simultaneously. Proper synchronization ensures that account balances are accurately updated, preventing errors like overdrafts or incorrect balances.</p>

                <hr className="section-divider" />

                <h2>3. Inter-Thread Communication</h2>

                <h3>Overview</h3>
                <p>
                    Inter-thread communication is crucial when threads need to cooperate. For example, one thread may need to wait for another thread to complete a task before proceeding. Java provides mechanisms like <code>wait()</code>, <code>notify()</code>, and <code>notifyAll()</code> to facilitate communication between threads.
                </p>

                <h4>Key Methods:</h4>
                <ul>
                    <li><code>wait():</code> Causes the current thread to wait until another thread invokes <code>notify()</code> or <code>notifyAll()</code> on the same object.</li>
                    <li><code>notify():</code> Wakes up a single thread that is waiting on the object's monitor.</li>
                    <li><code>notifyAll():</code> Wakes up all threads that are waiting on the object's monitor.</li>
                </ul>

                <h3>Producer-Consumer Problem</h3>
                <p>
                    The Producer-Consumer problem is a classic example of a multithreading scenario where one thread (the producer) creates data, and another thread (the consumer) processes it. Proper synchronization and communication between the threads are essential to ensure that the consumer waits until the producer has produced the data.
                </p>

                <h3>Example Code:</h3>
                <CodeBlock code={producerConsumerCode} />

                <h4>Output:</h4>
                <pre>
                    Produced: 1{'\n'}
                    Consumed: 1{'\n'}
                    Produced: 2{'\n'}
                    Consumed: 2{'\n'}
                    ...
                </pre>

                <p><strong>Explanation:</strong> The <code>SharedBuffer</code> class facilitates communication between the producer and consumer threads using <code>wait()</code> and <code>notify()</code>. The producer waits if the buffer is full, and the consumer waits if the buffer is empty, ensuring that data is produced and consumed in the correct order.</p>

                <p><strong>Real-World Application:</strong> Inter-thread communication is essential in applications like video streaming, where one thread might handle downloading data while another thread processes and displays it. Proper synchronization ensures smooth playback by coordinating the producer (downloading) and consumer (displaying) threads.</p>

                <hr className="section-divider" />

                <p><strong>Conclusion:</strong> Multithreading is a critical concept in Java that allows for concurrent execution of tasks, making applications more responsive and efficient. By understanding how to create and manage threads, synchronize access to shared resources, and enable inter-thread communication, you can build robust and scalable applications.</p>

                <p>Creating threads using the Runnable interface or extending the Thread class provides flexibility in how you implement multithreading. Synchronization ensures data consistency in multi-threaded environments, while inter-thread communication allows threads to work together effectively.</p>

                <p>These concepts are fundamental in developing complex applications, such as web servers, real-time systems, and large-scale data processing applications, where multiple tasks need to be performed simultaneously without compromising on performance or data integrity. By mastering these multithreading techniques, you'll be well-equipped to handle advanced programming challenges in Java.</p>

                <NextButton nextPage="/java-io-serialization" />
            </div>
        </div>
    );
};

export default Multithreading;
