import React, { useEffect } from 'react';
import './Transcription.css';
import { useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

function TranscriptionProcess() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleNext = () => {
        navigate('/transcription/audio-quality');
    };

    return (
        <div className="transcription-intro">
            <header className="header">
                <h1>The Transcription Process</h1>
                <p className="subtitle">Mastering Every Stage</p>
            </header>

            <div className="content">
                {/* Preparation Section */}
                <div className="section-block">
                    <h2>1. Preparation</h2>
                    <h3>Setting Up Your Workspace</h3>
                    <p>
                        Transcription is not just about typing words—it’s a meticulous process that requires preparation, focus, and attention to detail at every stage.
                        Mastering the transcription process will transform you into a professional who can produce clean, accurate transcripts efficiently.
                    </p>
                    <p>
                        Your workspace is your sanctuary—where focus and productivity flow. To transcribe efficiently, you need a setup that minimizes distractions and ensures you’re comfortable for long periods.
                    </p>
                    <p><strong>Ergonomics Matter:</strong> Invest in an ergonomic chair and keyboard to prevent strain. Place your screen at eye level and ensure your wrists are supported while typing. Your workspace should support long hours of work without causing discomfort.</p>
                    <p><strong>Minimize Distractions:</strong> Turn off unnecessary notifications, close unrelated tabs or apps, and ensure that your environment is quiet and free from distractions.</p>
                    <p><strong>Example:</strong> A clutter-free desk, good lighting, noise-canceling headphones, and a dedicated space free of distractions will enhance your focus and productivity.</p>

                    <h3>Organizing Files and Resources</h3>
                    <p>Being organized is half the battle in transcription. Set yourself up for success by having everything in place before you start.</p>
                    <p><strong>Create Folders:</strong> Organize your audio files, project briefs, and any related documents into dedicated folders for each client or project. This not only keeps you organized but also makes it easier to track and retrieve files later.</p>
                    <p><strong>Prepare Tools:</strong> Have your transcription software, foot pedal (if you use one), and text editor ready to go. Bookmark any online dictionaries or style guides you may need.</p>
                    <p><strong>Example:</strong> Before beginning transcription, create a folder named "Project_X" with subfolders for audio, draft transcripts, final versions, and reference materials. Having everything organized will make the workflow smoother and avoid scrambling for files during critical moments.</p>
                </div>

                {/* Initial Listening Section */}
                <div className="section-block">
                    <h2>2. Initial Listening</h2>
                    <h3>Assessing Audio Quality</h3>
                    <p>Before diving into transcription, always listen to the audio in its entirety, or at least a few minutes of it. This will give you a sense of clarity, background noise, volume levels, and speaker accents. You can then determine if the audio needs enhancement or if you need to adjust your transcription approach.</p>
                    <p><strong>Tools for Enhancing Audio:</strong> Use transcription software that allows you to adjust playback speed and audio levels. Some tools even let you apply noise reduction features to enhance clarity.</p>
                    <p><strong>Example:</strong> A podcast recording might have background music or audience laughter. Identifying these audio elements early allows you to prepare for when and how to include them in your transcript.</p>

                    <h3>Identifying Speakers and Audio Challenges</h3>
                    <p>Understanding who is speaking and identifying challenging audio sections early on will save you time later.</p>
                    <p><strong>Multiple Speakers:</strong> If the audio includes multiple speakers, make note of their voices and accents. This will help you later when labeling speakers in the transcript.</p>
                    <p><strong>Challenges to Note:</strong> If certain sections are hard to hear due to background noise, low volume, or speaker overlap, make mental notes or jot down timestamps where you’ll need to pay extra attention.</p>
                    <p><strong>Example:</strong> In a focus group discussion, listen for moments when multiple people may speak at once. Recognizing these moments in advance helps you prepare to either clarify or mark them appropriately in the transcript.</p>
                </div>

                {/* Transcribing Section */}
                <div className="section-block">
                    <h2>3. Transcribing</h2>
                    <h3>Techniques for Efficient Typing</h3>
                    <p>This is where the real work begins. Your goal during transcription is to balance speed and accuracy while maintaining efficiency. This step requires focus and technique.</p>
                    <p><strong>Use Shortcuts:</strong> Most transcription software comes with customizable keyboard shortcuts for pausing, rewinding, or slowing down audio. Master these shortcuts to avoid interrupting your typing flow.</p>
                    <p><strong>Foot Pedals:</strong> If you use a foot pedal, practice using it to control audio playback while you type. This allows you to maintain a smooth typing flow without using your hands for audio control.</p>
                    <p><strong>Example:</strong> In Express Scribe, you can assign the keys F4 to pause, F5 to rewind, and F6 to play the audio. Using these shortcuts allows you to transcribe without losing rhythm.</p>

                    <h3>Handling Timestamps and Speaker Labels</h3>
                    <p>When transcribing audio that requires timestamps or speaker labels, ensure that you follow the correct formatting as specified by the client or industry standards.</p>
                    <p><strong>Timestamps:</strong> Insert timestamps at regular intervals (e.g., every 5 minutes) or when there is a significant change in the conversation. Use your transcription software to automatically insert them if possible.</p>
                    <p><strong>Speaker Labels:</strong> For conversations with multiple speakers, label each speaker consistently (e.g., Speaker 1, Speaker 2, or by name if known). This helps maintain clarity in dialogues or interviews.</p>
                    <p><strong>Example:</strong> In a legal deposition, you might label speakers as "Attorney" and "Witness" with timestamps for each new segment of speech, ensuring the transcript is easy to follow.</p>
                </div>

                {/* Reviewing and Editing Section */}
                <div className="section-block">
                    <h2>4. Reviewing and Editing</h2>
                    <h3>Self-Editing Strategies</h3>
                    <p>Transcription doesn’t stop after the initial typing. Editing is where you refine and polish your work to ensure the transcript is error-free and professionally formatted.</p>
                    <p><strong>Read Aloud:</strong> Reading the transcript aloud helps you catch awkward phrasing, missing words, or confusing sections. This is particularly useful when dealing with complex sentences or technical language.</p>
                    <p><strong>Spot-Check the Audio:</strong> Re-listen to tricky sections of the audio to ensure you didn’t miss or misinterpret anything the first time. Check for consistency in speaker labels, punctuation, and timestamps.</p>
                    <p><strong>Example:</strong> In an academic interview, you might realize that a term like “neoliberalism” was misheard as “new liberalism.” Catching such errors during self-editing will ensure your transcript is accurate.</p>

                    <h3>Using Spellcheck and Grammar Tools</h3>
                    <p>Tools like spellcheck and grammar checkers (e.g., Grammarly or Microsoft Word’s built-in tools) are invaluable for catching minor errors. However, they shouldn’t be relied upon exclusively—always do a manual review for proper names, industry jargon, and context.</p>
                    <p><strong>Custom Dictionaries:</strong> In specialized fields like medical or legal transcription, use software that allows you to add industry-specific terms to the dictionary to avoid incorrect spellings or suggestions.</p>
                    <p><strong>Example:</strong> If you're working on medical transcription, you may need to manually review medical terminology like “tachycardia” or “bradyarrhythmia” that spellcheck might not recognize.</p>
                </div>

                {/* Finalizing Section */}
                <div className="section-block">
                    <h2>5. Finalizing</h2>
                    <h3>Formatting the Transcript</h3>
                    <p>Your client or industry may have specific guidelines regarding transcript formatting. Common formatting requirements include font style, speaker labeling, and spacing.</p>
                    <p><strong>Consistency is Key:</strong> Ensure that headings, speaker labels, and timestamps are consistently formatted. Double-check line spacing, margins, and font size to meet the specifications.</p>

                    <h3>Delivering the Finished Product</h3>
                    <p>Once you’ve reviewed and polished your transcript, ensure that it’s saved in the required file format. Clients may request transcripts in formats such as Word documents, PDFs, or plain text files.</p>
                    <p><strong>Back Up Your Work:</strong> Always keep a copy of the final transcript for yourself, as clients may request revisions or need the file again later.</p>
                    <p><strong>Final Checks:</strong> Before sending the file, do one final check of file names and formats to ensure everything is labeled correctly and that no crucial information was missed.</p>
                    <p><strong>Example:</strong> If your client requires the transcript in PDF format, use a word processor to export the document, and then do a final proofread to ensure the formatting was preserved during conversion.</p>
                </div>

                {/* Conclusion Section */}
                <div className="section-block">
                    <h2>Conclusion: The Heart of the Transcription Process</h2>
                    <p>The transcription process is a blend of technical skill, attention to detail, and careful editing. Every stage is an opportunity to perfect your craft—from preparation to the final delivery, your work has the potential to become a vital resource for your clients. Embrace the process—each project is a new challenge, a new opportunity to deliver something exceptional. With the right approach and passion, you’ll not only meet your clients' expectations but exceed them, creating transcripts that are both accurate and beautifully presented.</p>
                </div>
            </div>

            <div className="navigation-icons">
                <button className="nav-icon right" onClick={handleNext}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default TranscriptionProcess;
